import React from 'react'
import { Form, Table, Footer } from './Components'
import Title from '../../components/Title/index'
import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'


const Scheduling = () => {
  

  const navigate = useNavigate()

  const goHome = () => navigate('/') 

  
  return (
    <div className={styles.wrapper}>
      <Title title="Составление расписания"/> 
      <Form />
      <Table />
      <Footer goHome={goHome} />
    </div>
  )
}

export default Scheduling
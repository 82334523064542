import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'

const Select = ({
    title,
    options,
    withoutArrowMode,
    className,
    comment = '',
    borderRadius = '7px',
    width = 'fit-content',
    ...props
}) => {

    const classObject = props.ClassbookTable
        ? cn(styles.select, className, {
            [styles.selectGradeClassbook]: props.ClassbookTable
        })
        : cn(styles.select, className, {
            [styles.selectWithOutArrow]: withoutArrowMode,

            [styles.wrapperCell]: props.TableCell
        })

    return (

        <div
            className={props.TableCell || props.ClassbookTable
            ? styles.wrapperCell
            : styles.wrapper}
            style={{width: width,}}
            >
            {!props.TableCell
                ? <span className={styles.title}>{title}</span>
                : <></>}
            <select
                title={comment}
                className={classObject}
                style={{
                    borderRadius: borderRadius,
                    background: comment != ''
                    ? '#a6a6a6'
                    : ''
                }}
                {...props}>

                {options?.map(el => {
                    const name = el.name || el.title || el.value || el.year || el.work_status || el.access || el.sh_var_name || el.base_title || el.calc_fio
                    const value = el.xp_key || el.value || el.trmid || el.cid || el.gid || el.work_status || el.id || el.pmid || el.did || el.mid || el.sh_var_id || el.tfoid || el.tfotid
                    return <option value={value} className={styles.option}>{name}</option>
                })
}
            </select>
        </div>
    )
}

export default Select
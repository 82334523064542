import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { buildDefaultHeaders } from "./helper";
import { 
  SET_GROUPS_DATA, 
  SET_GROUPS_LOADING, 
  SET_GROUP_CURATORS, 
  SET_GROUP_HISTORY, 
  SET_GROUP_INFO, 
  SET_GROUP_TEAM, 
  SET_GROUPS_MODAL_LOADING,
  SET_CLASSING_PAGE,
  SET_CLASSING_COUNT,
 } from "./types";
import { checkAndUpdateToken } from "./auth";
import { createNotification } from "../components/Notifications/Notifications";


export const setPage = (pageNumber) => (
  {type: SET_CLASSING_PAGE, payload: {pageNumber}})

export const getGroups = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_LOADING, payload: true });
    const  {pageNumber}  = getState().groups
    const max_results = itemsOnPage
    const paginate_from = itemsOnPage * pageNumber
    const urlParams = new URLSearchParams({max_results, paginate_from}).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const {count, groups} = await response.json();//count общее кол-во эл
      const pageCount = Math.ceil(count / itemsOnPage)//pageCount число страниц
      dispatch({ type: SET_GROUPS_DATA, payload: groups });
      dispatch({ type: SET_CLASSING_COUNT, payload: {pageCount, count} });
    }
  }
  dispatch({ type: SET_GROUPS_LOADING, payload: false });
};

export const getGroupInfo = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_INFO, payload });
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const getGroupHistory = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/group_history/${groupId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_HISTORY, payload });
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const getGroupTeam = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupId}/listeners`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_TEAM, payload });
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const getGroupCurators = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupId}/tutors`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_CURATORS, payload });
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const updateGroups = (groupId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroups());
    }
  }
  dispatch({ type: SET_GROUPS_LOADING, payload: false });
};

export const createGroups = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroups());
    }
  }
  dispatch({ type: SET_GROUPS_LOADING, payload: false });
};

export const createHistoryGroups = (groupId,data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/group_history`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupHistory(groupId));
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const updateHistoryGroups = (groupId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/group_history/${groupId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupHistory(groupId));
    }
  }
  dispatch({ type: SET_GROUPS_MODAL_LOADING, payload: false });
};

export const deleteHistoryGroups = (confirmation) => async (dispatch, getState) => {
  const {xp_key, classId} = confirmation
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/group_history/${xp_key}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupHistory(classId));
      createNotification('success', 'Успешно удалено');
    }
  }
};

// export const createGroups = (data) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     dispatch({ type: SET_COURSES_LOADING, payload: true });
//     const response = await fetch(`${BACKEND_URL_PREFIX}/groups`, {
//       method: "POST",
//       body: JSON.stringify(data),
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       await dispatch(getCoursesList());
//     } else {
//       createNotification('error', 'Ошибка')
//     }
//   }
//   dispatch({ type: SET_COURSES_LOADING, payload: false });
// };
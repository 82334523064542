import React, { useState } from 'react'

import {Main} from './ModalTabs'

import Modal from '../../components/Modal'

export default function ModalComponent ({data, closeModal, yearId}) {
  
  const [mode, setMode] = useState('main')

  const renderTab = () => {
      switch (mode) {
          case 'main': return <Main yearId={yearId} closeModal={closeModal} data={data} addMode={!data?.sh_var_id}/>
          default: return
      }
  }
  
  return (
      <Modal
        open={!!data}
        menuItem={mode}
        setMenuItem={setMode}
      >
        {renderTab()}
      </Modal>
  )
}

import React, { useContext } from 'react'
import styles from './styles.module.css'
import { Input, Select, Textarea } from '../../../components/ModalInputs'
import { sex, statuses, docTypes } from '../../../assets/const'
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { StaffRosterContext } from '../../../utils/context';


const job = [
  { name: 'воспитатель' },
  { name: 'методист' }]
const job2 = [
  { name: 'преподаватель' },
  { name: 'старший вожатый' }]
const job3 = [
  { name: 'Начальник училища' },
  { name: 'Начальник НУ' }]


export default function Main() {

  const { allForms, addMode, } = useContext(StaffRosterContext)

  const { values, handleChange, handleBlur } = allForms

  const renderOptions = ({ name, value }) => <option value={value}>{name}</option>
  const generateOptions = (arr) => arr.map(renderOptions)

  return (

    <div className={styles.tabWrapper}>
      <div className={styles.inputsRow}>
        <Input label='Фамилия' name='lastname' value={values.lastname} onChange={handleChange} />
        <Input label='Имя' name='firstname' value={values.firstname} onChange={handleChange} />
        <Input label='Отчество' name='patronymic' value={values.patronymic} onChange={handleChange} />
        {/* <Input label='ФИО' name='fio' value={values && values.fio?.slice(0,120)} onChange={handleChange} style={{width:'318%'}}/> */}
      </div>
      <div className={styles.inputsRow}>
        <Input label='Табельный номер' name='perstype' value={values.perstype} onChange={handleChange} />
        <Input label='Литер' name='liter' value={values.liter} onChange={handleChange} />
        <Input label='Логин' name='login' value={values.login} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Select label='Пол'>{sex.map(renderOptions)}</Select>
        {/* <Input label='Пол' name='sex' value={values.sex} onChange={handleChange}/>
          <Select label='Должность пед. работника для аттестации (совместит.)'>{sex.map(renderOptions)}</Select> */}

        <Select label='Статус' name='birthday' value={values.birthday} onChange={handleChange}>{statuses.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Input label='Дата рождения' name='birthday' value={values.birthday} onChange={handleChange} />
        <Input label='Код ОКАТО места рождения' name='birthplace' value={values.birthplace} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        {/* <Select label='Состояние в браке' name='socialstatus' value={values.socialstatus} onChange={handleChange}>{sex.map(renderOptions)}</Select> // TODO */}
        <Input label='Состояние в браке' name='familystatus' value={values.familystatus} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        {/* <Select label='Гражданство по ОКИН' name='citizenokin' value={values.citizenokin} onChange={handleChange}>{sex.map(renderOptions)}</Select> */}
        <Input label='Гражданство по ОКИН' name='citizenokin' value={values.citizenokin} onChange={handleChange} />

      </div>
      <div className={styles.inputsRow}>
        {/* <Select label='Характер работы'>{sex.map(renderOptions)}</Select> */}
        <Input label='Характер работы' name='persstate' value={values.persstate} onChange={handleChange} />
        <Input label='Тип' name='perstype' value={values.perstype} onChange={handleChange} />
        {/* <Select label='Тип' name='birthday' value={values.birthday} onChange={handleChange}>{sex.map(renderOptions)}</Select> */}
        {/* <Select label='Вид работы'>{sex.map(renderOptions)}</Select> */}
        <Input label='Вид работы' name='kindwork' value={values.kindwork} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Select label='Должность пед. работника для аттестации (основная)'>{job.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Select label='Должность пед. работника для аттестации (совместит.)'>{job2.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Select label='Штатная должность для ГУКа'>{job3.map(renderOptions)}</Select>
      </div>

      <h3>Адрес места жительства</h3>
      <div className={styles.inputsRow}>
        <Input label='Индекс факт.' />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Место рождения' style={{ width: '450px' }} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Индекс пасп.' />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Адрес места жительства по паспорту' name='registration_address' value={values.registration_address} onChange={handleChange} style={{ width: '450px' }} />
      </div>
      <div className={styles.inputsRow}>
        <Input
          label='Дата регистрации'
          name='registrationdate'
          value={values.registrationdate}
          onChange={handleChange}
          type='date'
        />

      </div>
      <div className={styles.inputsRow}>
        <Select label='Тип документа' name='document_type' value={values.document_type} onChange={handleChange}>{docTypes.map(renderOptions)}</Select>
        <Input label='Серия' name='document_series' value={values.document_series} onChange={handleChange} />
        <Input label='Номер' name='document_number' value={values.document_number} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        {/* <Input label='Кем выдан' name='document_number' value={values.issued_by} onChange={handleChange} style={{width: '650px'}}/> */}
        <Textarea label='Кем выдан' name='issued_by' value={values.issued_by} onChange={handleChange} style={{ width: '650px' }} />
      </div>
      <div className={styles.inputsRow}>
        {/* <Select label='Когда выдан' name='issue_date' value={values.issue_date} onChange={handleChange}>{sex.map(renderOptions)}</Select> */}
        <Input label='Когда выдан' name='issue_date' value={values.issue_date} onChange={handleChange} />
        <Input label='Код подразделения' name='issuer_code' value={values.issuer_code} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Пенсионное страховое свидетельство(СНИЛС)' name='snils' value={values.snils} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='ИНН' name='inn' value={values.inn} onChange={handleChange} />
        <Input
          label='Дата составления Т-2'
          name='registrationdate'
          value={values.registrationdate}  //TODO
          onChange={handleChange}
          type='date'
        />
      </div>

      <h3>Трудовой договор</h3>
      <div className={styles.inputsRow}>
        <Input label='Номер' name='contractnumber' value={values.contractnumber} onChange={handleChange} />
        <Input label='Доп. сведения' name='contracttext' value={values.contracttext} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Дата приема' name='employment_date' value={values.employment_date} onChange={handleChange} />
        <Input label='Основание прекращения' name='contractendosnovanie' value={values.contractendosnovanie} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input
          label='Дата увольнения'
          name='registrationdate'
          value={values.registrationdate}  //TODO
          onChange={handleChange}
          type='date'
        />
        <Input label='Приказ (расп.) №' name='contractendprikaz' value={values.contractendprikaz} onChange={handleChange} />
        <Input
          label='от'
          name='registrationdate'
          value={values.registrationdate}  //TODO
          onChange={handleChange}
          type='date'
        />
      </div>

      <h3>Загран паспорт</h3>
      <div className={styles.inputsRow}>
        <Input
          label='Выдан'
          name='registrationdate'
          value={values.registrationdate}  //TODO
          onChange={handleChange}
          type='date'
        />
        <Input
          label='Действует до'
          name='registrationdate'
          value={values.registrationdate}  //TODO
          onChange={handleChange}
          type='date'
        />
        <Input label='Серия' name='contractenddate' value={values.contractenddate} onChange={handleChange} />
        <Input label='Номер' name='contractenddate' value={values.contractenddate} onChange={handleChange} />
      </div>

      <h3>Страховой полис</h3>
      <div className={styles.inputsRow}>
        <Input
          label='Действует до'
          name='registrationdate'
          value={values.registrationdate}  //TODO
          onChange={handleChange}
          type='date'
        />
        <Input label='Серия' name='insuranceseria' value={values.insuranceseria} onChange={handleChange} />
        <Input label='Номер' name='insurancenumber' value={values.insurancenumber} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Страховая компания' name='insurancecompany' value={values.insurancecompany} onChange={handleChange} />
      </div>

    </div>
  )
}

import React from 'react'
import { Input } from '../../../components/ModalInputs'



export default function IntNumber({data, formik}) {
  return (
    <Input
      type='number'
      label={data.description}
      onChange={formik.handleChange}
      name={data.name}
    />
  )
}

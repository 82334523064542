import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { Checkbox, Input, Select, Textarea } from '../../components/ModalInputs'
import Button from '../../components/Button'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { sendNotice } from '../../actions/candidates'
import { api } from '../../App'
import moment from 'moment'

const SEND_MODES = [
  {name: 'e-mail', value: 1},
  {name: 'печать', value: 2},
  {name: 'телеграмма', value: 3}
]

const NOTICE_TYPES = [
  {name: 'о допуске к участию во вступительных испытаниях', value: 1},
  {name: 'о допуске к онлайн собеседованию', value: 2},
  {name: 'об отказе в допуске к вступительным испытаниям', value: 3},
  {name: 'о зачислении', value: 4},
  {name: 'о зачислении (2023)', value: 5}
]

const MESSAGE_PLUG = {
  type: 0, 
  header: "Извещение", 
  sent: null,
  params: null, 
  subtype: 0,
  attach: null
}

const INIIT_VALUES = {
  body: '',
  mail_date: moment().format('YYYY-MM-DD'),
  test_mail: false,
  test_mail_text: '',
  send_mode: 1
}

const NoticeModal = ({ open, closeModal, selectedEmails }) => {
  
  const dispatch = useDispatch()

  const [initOptions, setInitOptions] = useState([])

  useEffect(() => {
    if (!open) return;
    getOptions()
    setValues({})
  }, [open])
  
  const getOptions = async () => {
    const { data } = await api.get('/options/applicant_letters')
    const initParams = data.options.reduce((acc, param) => ({...acc, [param.name]: param.value}), {})
    setInitOptions(data.options)
    setValues({...initParams, ...INIIT_VALUES})
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: {},
    onSubmit: console.log
  })
  

  const renderOptions = ({name, value}) => <option value={value} key={name}>{name}</option>
  
  const sendNoticeHandler = () => {
    const { body, mail_date, test_mail, test_mail_text } = values
    const message = {...MESSAGE_PLUG, body, mail_date }
    const options = initOptions.map(option => ({...option, value: values[option.name]}))
    const calc_data = test_mail ? [{email: test_mail_text, id: -1}] : selectedEmails 

    const payload = { calc_data, message, options }
  
    dispatch(sendNotice(payload))
    closeModal()
  }

  const sendModeHandler = (e) => {
    handleChange(e)
    setFieldValue('test_mail', false)
    setFieldValue('test_mail_text', '')
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      showCloseIcon={false}
      classNames={{modal: styles.noticeModal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      animationDuration={1}
    >
      <div>
        <h2>Рассылка извещений</h2>
      </div>
      <div>
        <div className={styles.noticeFirstRow}>
          <div>
            <Select
              label='Режим отправки'
              name='send_mode'
              value={values.send_mode}
              onChange={sendModeHandler}
            >
              {SEND_MODES.map(renderOptions)}
            </Select>
            <Checkbox label='Не отправлять повторно'/>
          </div>
          <div>
            <Checkbox label='Отложенная отправка'/>
            <Input
              type='date'
              label='Дата извещения'
              name='mail_date'
              value={values.mail_date}
              onChange={handleChange}
            />
          </div>
        </div>
        <Select label='Извещение' style={{width: '100%'}}>
          {NOTICE_TYPES.map(renderOptions)}
        </Select>
        <div className={styles.noticeSecondRow}>
          <Input
            style={{width: '376px'}}
            label='Краткое название (далее по тексту)'
            name='SchoolReference'
            value={values.SchoolReference}
            onChange={handleChange}
          />
          <Input
            style={{width: '376px'}}
            label='(прибыть в)'
            name='ExamAddress2'
            value={values.ExamAddress2}
            onChange={handleChange}
          />
        </div>
        <Textarea
          className={styles.noticeTextarea}
          name='body'
          value={values.body}
          onChange={handleChange}
        />
        <Textarea
          className={styles.noticeTextarea}
          style={{height: '5rem'}}
          label='Подпись (только в тексте эл. письма)'
          name='MailSignature'
          value={values.MailSignature}
          onChange={handleChange}
        />
        {values.send_mode == 1 &&
          <div className={styles.testNotice}>
          <Checkbox
            label='Тестовая отправка'
            name='test_mail'
            checked={values.test_mail}
            onChange={() => setFieldValue('test_mail', !values.test_mail)}
          />
          <Input
            name='test_mail_text'
            value={values.test_mail_text}
            onChange={handleChange}
            disabled={!values.test_mail}
          />
        </div>}
      </div>
      <div className={styles.buttonsBlock}>
        <Button onClick={sendNoticeHandler}>Отправить</Button>
        <Button onClick={closeModal}>Отмена</Button>
      </div>
    </Modal>
  )
}

export default NoticeModal
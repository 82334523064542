import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateDocuments } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import Table from '../../../components/Table'


export default function Documents () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { documents } = useSelector(state => state.candidates.data)

  useEffect(() => {
    dispatch(getCandidateDocuments(candidateId))
  }, [])
  
  
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <h5>Требуемые документы</h5>
          <Table>
            <thead>
              <tr>
                <th>Название документа (файл)</th>
                <th>Примечание/Причина</th>
                <th>Статус</th>
                <th>Обновлен</th>
              </tr>
            </thead>
          </Table>
        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={closeModal}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}

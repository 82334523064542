import * as types from "../../actions/types";



const initialState = null


export default function main (state = initialState, action) {
  switch (action.type) {
      
    case types.SET_SETTINGS_MAIN_DATA:
      return action.payload

    default:
      return state;
  }
}
import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  GET_SCHEDULE_SETTINGS_TEACHERS_SUBJECTS,
  GET_SCHEDULE_SETTINGS_CYCLE,
  GET_SCHEDULE_SETTINGS_LESSONS,
  GET_SCHEDULE_SETTINGS_ROOMS_SUBJECTS,
  GET_SCHEDULE_SETTINGS_ROOMS,
  GET_SCHEDULE_SETTINGS_ROOMS_TEACHERS,
  GET_SCHEDULE_SETTINGS_ROLE,
  GET_SCHEDULE_SETTINGS_COMPLEXITY_GROUPS_SUBJECTS,
  GET_SCHEDULE_SETTINGS_TEACHERS_LESSON,
  GET_SCHEDULE_SETTINGS_GROUPS,
  GET_SCHEDULE_SETTINGS_DAY_OF_WEEK,
  SET_SCHEDULE_SETTINGS_LOADING,
  GET_SCHEDULE_SETTINGS_WORKLOAD_TEACHERS,
  GET_SCHEDULE_SETTINGS_STUDY_PERIOD,
} from "./types";
import { createNotification } from "../components/Notifications/Notifications";

////////////// HEADERs table  ///////////////////
// ПРЕДМЕТЫ
export const getScheduleSettingsLessons = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/courses`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_LESSONS, payload: info });
    }
  }
};
// КАБИНЕТЫ
export const getScheduleSettingsRooms = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/rooms`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_ROOMS, payload: info });
    }
  }
};
// ЦИКЛЫ
export const getScheduleSettingsCycle = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/departments_courses`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_CYCLE, payload: info });
    }
  }
};
//РОЛИ
export const getScheduleSettingsRole = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/role`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_ROLE, payload: info });
    }
  }
};
//КЛАССЫ
export const getScheduleSettingsGroups = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/groups`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_GROUPS, payload: info });
    }
  }
};
//ДНИ НЕДЕЛИ
export const getScheduleSettingsDayOfWeek = (year) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/lessons/${year}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_DAY_OF_WEEK, payload: info });
    }
  }
};

//ПЕРИОДЫ
export const getScheduleSettingsStudyPeriod = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/study_period`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_STUDY_PERIOD, payload: info });
    }
  }
};
////////////// HEADERs table  ///////////////////

export const getScheduleSettingsTeachersSubjects = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: true });
    const urlParams = new URLSearchParams(data).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/teachers_subjects?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_TEACHERS_SUBJECTS, payload: info });
    }
  }
  dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: false });
};


export const updateScheduleSettingsTeachersSubjects = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/teachers_subjects`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data)

    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      dispatch(getScheduleSettingsTeachersSubjects())
    }
  }

};

export const getScheduleSettingsRoomsSubjects = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/rooms_subjects`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_ROOMS_SUBJECTS, payload: info });
    }
  }
  dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: false });
};


export const updateScheduleSettingsRoomsSubjects = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/rooms_subjects`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data)

    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      dispatch(getScheduleSettingsRoomsSubjects())
    }
  }
};

export const getScheduleSettingsRoomsTeachers = (role) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: true });
    const urlParams = new URLSearchParams({role}).toString();
    const response = await fetch(role ?
      `${BACKEND_URL_PREFIX}/schedule_settings/rooms_teachers?${urlParams}` :
      `${BACKEND_URL_PREFIX}/schedule_settings/rooms_teachers`,
       {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_ROOMS_TEACHERS, payload: info });
    }
  }
  dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: false });
};


export const updateScheduleSettingsRoomsTeachers = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/rooms_teachers`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data)

    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      dispatch(getScheduleSettingsRoomsTeachers())
    }
  }
};

export const getComplexityClassesSubjects = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: true });

    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/complexity_groups_subjects`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_COMPLEXITY_GROUPS_SUBJECTS, payload: info });
    }
  }
  dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: false });

};


export const updateComplexityClassesSubjects = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/complexity_groups_subjects`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data)

    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      dispatch(getComplexityClassesSubjects())
    }
  }
};

/// Преподватели-Уроки

export const getTeachersLesson = (year) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: true });

    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/teachers_lesson/${year}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_TEACHERS_LESSON, payload: info });
    }
  }
  dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: false });

};

export const updateTeachersLesson = (data, year) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/teachers_lesson`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data)
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      dispatch(getTeachersLesson(year))
    }
  }
};

///
export const getWorkloadTeachersClass = ({currPeriod, currTeacher}) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    // const {year, trmid, mid} =data
    dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/workload_teachers_class/${currPeriod.year}/${currPeriod.trmid}/${currTeacher}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info = await response.json();
      dispatch({ type: GET_SCHEDULE_SETTINGS_WORKLOAD_TEACHERS, payload: info });
    }
  }
  dispatch({ type: SET_SCHEDULE_SETTINGS_LOADING, payload: false });

};


export const updateWorkloadTeachersClass = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_settings/workload_teachers_class`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data)

    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      // dispatch(getWorkloadTeachersClass(data))
    }
  }
};

export const usersWord = (value) => {
    value = Math.abs(value) % 100; 
	var num = value % 10;
	if(value > 10 && value < 20) return 'пользователей'; 
	if(num > 1 && num < 5) return 'пользователя';
	if(num == 1) return 'пользователь'; 
	return 'пользователей';
}

export const objFilter = (obj) => {
	if (!obj) return {};
	return Object.keys(obj)
	.filter( key => obj[key] || obj[key] === 0 || obj[key] === false )
	.reduce( (res, key) => (res[key] = obj[key], res), {} );
}

export const getObjectFromArray = (arr) => arr?.reduce((res, item) => ({...res, [item.name]: item.value}), {})

export const getTreeSelectData = (list) => {
	return list?.map(({calc_type_id, calc_type_name, calc_values, calc_value_id, calc_value_name}) => ({
		label: calc_type_name || calc_value_name,
		value: calc_type_id || calc_value_id,
		children: getTreeSelectData(calc_values)
	})) || []
}
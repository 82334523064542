import React, {useState} from 'react'
import styles from './index.module.css'
import Table from '../../../../components/Table'
import {BiTrash} from 'react-icons/bi'
import {useDispatch} from 'react-redux'
import {createStudyPlan, createStudyPlanContent, deleteStudyPlansContent, editStudyPlan, editStudyPlanContent} from '../../../../actions/studyPlans'
import Confirmation from '../../../../components/Confirmation/Confirmation'
import Button from '../../../../components/Button'
import Modal from './Modal'
import {createNotification} from '../../../../components/Notifications/Notifications'
import moment from 'moment'

const Index = ({data, values, setValues}) => {

    const dispatch = useDispatch();

    const [confirmation,
        setConfirmation] = useState(false);
    const [modalOpen,
        setModalOpen] = useState(null);

    const acceptConfirmation = () => { // Подтвердить удаление
        dispatch(deleteStudyPlansContent(confirmation));
        closeModal();
        setConfirmation(false);
    };
    const rejectConfirmation = () => { // Отменить подтверждение
        setConfirmation(false);
    };

    const openModal = (item) => { // Открыть модалку
        if (item
            ?.xp_key) {
            setModalOpen(item)
        } else {
            setModalOpen({
                trid: values
                    ?.trid,
                xp_key: 'new',
                cid: '',
                prev_cid: '',
                xp_hours: 0,
                xp_hours_period: 0,
                xp_hours_year: 0,
                level: 0,
                profile_id: '',
                profile: '',
            })
        }
    }

    const closeModal = () => { // Закрыть модалку
        // setClassId(null)
        setModalOpen(null)
    }

    const handleConfirmation = (e, data) => {
        e.stopPropagation() // Отмена действия по умолчанию (открытие модалки)
        setConfirmation(data)
    }

    const handleSubmit = () => {
        if (modalOpen.trid && modalOpen.cid && modalOpen.prev_cid && modalOpen.xp_hours && modalOpen.xp_hours_period && modalOpen.xp_hours_year && modalOpen.level && modalOpen.profile_id) {
            if (modalOpen.xp_key && modalOpen.xp_key !== 'new') {
                dispatch(editStudyPlanContent({
                    ...modalOpen,
                    periodfrom: moment(modalOpen.periodfrom).format('YYYY-MM-DD'),
                    periodtill: moment(modalOpen.periodtill).format('YYYY-MM-DD'),
                    profile: Number(modalOpen.profile_id)
                }))
                closeModal()
            } else {
                const data = modalOpen
                delete data.xp_key
                data.profile = Number(data.profile_id)
                dispatch(createStudyPlanContent(modalOpen))
                closeModal()
            }
        } else {
            createNotification('error', 'Заполните все обязательные поля')
        }
    }

    return (
        <div className={styles.tabWrapper}>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>№ п/п</th>
                        <th>Предмет</th>
                        <th>Пред шедствующий предмет</th>
                        <th>Часы в неделю</th>
                        <th>Часы в уч. период</th>
                        <th>Часы в уч. год</th>
                        <th>Параллель</th>
                        <th>Профиль</th>
                        <th>Удалить</th>
                    </tr>
                </thead>
                <tbody>

                    {data.map((data, index) => (
                        <tr key={index} onClick={() => openModal(data)}>
                            <td>{index + 1}</td>
                            <td>{data.course_name}</td>
                            <td>{data.prev_name}</td>
                            <td>{data.xp_hours}</td>
                            <td>{data.xp_hours_period}</td>
                            <td>{data.xp_hours_year}</td>
                            <td>{data.level}</td>
                            <td>{data.profile}</td>

                            <td onClick={(e) => handleConfirmation(e, data)}>
                                <i className={styles.trash}><BiTrash/></i>
                            </td>
                        </tr>
                    ))
}

                </tbody>
            </Table>

            {modalOpen
                ?.xp_key && (
                    <div className={styles.modal}><Modal
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        closeModal={closeModal}
                        handleSubmit={() => handleSubmit()}/></div>
                )}

            <div className={styles.buttons}>
                <Button
                    style={{
                    backgroundColor: "#3689FF",
                    fontSize: "16px"
                }}
                    onClick={() => openModal(data)}>Добавить</Button>
            </div>

            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                title="Удалить данную запись?"
                confirmation={confirmation
                ?.name}
                acceptConfirmation={() => acceptConfirmation(confirmation
                ?.name)}
                rejectConfirmation={rejectConfirmation}/>)}
        </div>
    )
}

export default Index
import React from 'react'
import styles from './styles.module.css'
import {Input, Select, Checkbox, Textarea} from '../../../components/ModalInputs'
import { admissionYears, cities, countries, genders, sex, sportGrade, streams, documenttype, docTypes, armyDistricts } from '../../../assets/const'
import { useContext } from 'react'
import {  StudentsContext, VariableStuffContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'



export default function Main ({ active }) {

  const {mainForm, familyForm} = useContext(StudentsContext)
  const { schoolYears, countries, subjects, schools, familyStatuses, familyCompos } = useSelector(state => state.common)


  const renderOptions = ({name, value, xp_key, subject, idschool, schoolname}) => (
    <option value={value || xp_key || idschool}>
      {name || subject || schoolname}
    </option> 
  )
    
  return (
    <div className={classNames(styles.tabWrapper, {[styles.dislplayNone]: !active})}>
        <div className={styles.inputsRow}>
        <Input 
          label='Фамилия'  
          name='lastname'  
          onChange={mainForm.handleChange}   
          value={mainForm.values.lastname}
        />
        <Input  
          label='Имя'
          name='firstname'
          value={mainForm.values.firstname}
          onChange={mainForm.handleChange}
        />
       <Input
          label='Отчество'
          name='patronymic'
          value={mainForm.values.patronymic}
          onChange={mainForm.handleChange}
        />
        </div>
        <div className={styles.inputsRow}>
          <Input label='Номер'/>
          <Input
            label='Логин'
            name='login'
            value={mainForm.values.login}
            onChange={mainForm.handleChange}
          />
        </div>

        <div className={styles.inputsRow}>
          <Input label='Литер'/>
          <Input
            label='Логин родителей'
            name='login_parent'
            value={mainForm.values.login_parent}
            onChange={mainForm.handleChange}
          />
        </div>

        <div className={styles.inputsRow}>
          <Select
            label='Пол'
            value={mainForm.values.sex}
            name='sex'
            onChange={mainForm.handleChange}
          >   
           {sex.map(renderOptions)}
          </Select>
          <Input
            label='Дата рождения'
            type='date'
            value={mainForm.values.birthdate}
            name='birthdate'
            onChange={mainForm.handleChange}
          />
          <Checkbox label='Годен к строевой службе' />

        </div>
        <div className={styles.inputsRow}>
          <Input style={{width: '700px'}} label='Место рождения'/>
        </div>
        <div className={styles.inputsRow}>
          <Select
            style={{width: '380px'}}
            value={mainForm.values.idcountry}
            label='Страна'
            name='idcountry'
            onChange={mainForm.handleChange}
           >
            {countries?.map(renderOptions)}
          </Select>
          <Select
            style={{width: '380px'}}
            value={mainForm.values.citizen}
            label='Гражданство'
            name='citizen'
            onChange={mainForm.handleChange}
            // {countries?.map(renderOptions)}
          >
          </Select>
        </div>
        <div className={styles.inputsRow}>
          <Select
            label='Субъект РФ'
            value={mainForm.values.subject}
            name='subject'
            onChange={mainForm.handleChange}
          >
            {subjects?.map(renderOptions)}
          </Select>

        </div>
        <div className={styles.inputsRow}>
          <Input label='Индекс'/>
          <Input style={{width: '400px'}} label='Нас. пункт'/>

        </div>
        <div className={styles.inputsRow}>
          <Input style={{width: '700px'}} label='Адрес проживания'/>
        </div>
        <div className={styles.inputsRow}>
          <Input label='Дом. телефон'/>
          <Input label='Моб. телефон'/>
          <Input label='IMEI'/>
        </div>
        <div className={styles.inputsRow}>
         <Input label='Оператор сотовой связи '/>
         <Input label='Модель телефона'/>
         <Input label='E-mail'/>
         </div>

        <div className={styles.inputsRow}>
          <Input label='E-mail родителей'/>
          <Input label='Skype'/>
        </div>
        <h3>Регистрационные данные</h3>
        <div className={styles.inputsRow}>
          <Input label='Адрес регистрации'/>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Тип документа'>{docTypes.map(renderOptions)}</Select>
          <Input label='Серия'/>
          <Input label='Номер'/>
        </div>
        <div className={styles.inputsRow}>
          <Input label='Кем выдан'/>
          <Input
          label='Когда выдан'
          type='date'
          value={mainForm.values.date_for_passport}
          name='date_for_passport'
          onChange={mainForm.handleChange}
      />
        </div>
        <h3>Прочее</h3>
        <div className={styles.inputsRow}>
          <Select label='Тип восприятия информации '>{sex.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Input label='Примечание'/>
          <Input label='S/N ноутбука'/>
          <Input label='Инв № ноутбука'/>
        </div>
        <div className={styles.inputsRow}>
          <Input label='№ удостоверения учащегося'/>
          <Input label='RFID метка'/>
          <Input label=''/>
        </div>
     
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../../components/ModalFooter/ModalFooter'
import ModalLocal from './Modal'
import { BiTrash } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { deleteEventType, getEventTypes } from '../../../../actions/ads'
import Confirmation from '../../../../components/Confirmation/Confirmation'
import Button from '../../../../components/Button'

const Index = ({ isEventType, setIsEventType }) => {
  
    const dispatch = useDispatch()

    const [list, setList] = useState([])
    const [modal, setModal] = useState(null)

    const [confirmation, setConfirmation] = useState(null)

    const {list: eventTypeList, loading, eventTypeById} = useSelector(state => state.event_type)

    const handleModal = (el) => {
        setModal(el)
    }

    useEffect(() => {
        dispatch(getEventTypes())
    }, [])

    useEffect(() => {
        if (!loading) {
            setList(eventTypeList)
        }
    }, [loading, eventTypeList])

    const closeModal = () => {
        setIsEventType(null)
    }

    const acceptConfirmation = () => {
        dispatch(deleteEventType(confirmation.ev_type_id))
        setConfirmation(null)
    }

    const rejectConfirmation = () => {
        setConfirmation(null)
    }

    const handleSubmit = () => {
        // dispatch({type: 'ADD_EVENT_TYPE', payload: modal})
        closeModal()
    }

    return (
    <Modal
      open={true}
      onClose={() => false}
      showCloseIcon={false}
      center
      classNames={{
        overlay: styles.overlay,
        modal: styles.eventTypesModal
      }}
    >
        {modal && <ModalLocal id={modal?.ev_type_id} closeModal={setModal} handleSubmit={handleSubmit}/>}
        {confirmation && <Confirmation
            top="30px"
            left="40px"
            title="Удалить тип события?"
            accept={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
            acceptConfirmation={() => acceptConfirmation(confirmation?.ev_type)}
        />}
        <div className={styles.wrapper}>
        <h1>Типы событий</h1>

        <div className={styles.modalBody}>
            <div className={styles.list}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Тип</th>
                            <th>Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list
                            ?.map(el => <tr key={el.ev_type_id}>
                                
                                <td onClick={() => handleModal(el)}>{el?.ev_type}</td>
                                <td onClick={() => setConfirmation(el)} style={{width: '30px'}}>
                                    <i className={styles.trash}><BiTrash/></i>
                                </td>
                            </tr>)}
                        <th colSpan={2}>Всего: {list
                                ?.length}</th>
                    </tbody>
                </table>

                <Button
                    className={styles.button}
                    onClick={() => setModal({
                        ev_type: '',})}
                >Добавить</Button>

                <div className={styles.modalFooter}>
                <ModalFooter
                    isShowInfo={false}
                    handleSubmit={handleSubmit}
                    closeModal={closeModal}/>
                </div>
                
            </div>
            </div>
        </div>
    </Modal>
  )
}

export default Index
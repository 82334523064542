import React, { useEffect, useState } from 'react'
import { Range } from 'react-range';
import styles from './styles.module.scss'



export default function RangeComponent ({
  minValue = 0,
  maxValue = 0,
  width,
  ...props
}) {
  
  const [values, setValues] = useState([props.min, props.max]);
  
  useEffect(() => {
    const newMinValue = parseFloat(String(minValue).replace(',', '.')) || props.min
    const newMaxValue = parseFloat(String(maxValue).replace(',', '.')) || props.max
    setValues([newMinValue, newMaxValue])
  }, [minValue, maxValue])
  

  const renderTrack = ({ props, children }) => (
    <div
      {...props}
      className={styles.track}
      style={{width: width}}
    >
      {children}
    </div>
  )

  const renderThumb = ({ props }) => (
    <div
      {...props}
      className={styles.thumb}
    >
      <span>{props['aria-valuenow']}</span>
    </div>
  )

  return (
    <Range
      values={values}
      renderTrack={renderTrack}
      renderThumb={renderThumb}
      {...props}
    />
  )
}
import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getRoomsList, getTFOTypeList, getTFOList } from '../../actions/rooms';
import { BiTrash } from 'react-icons/bi';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Confirmation from '../../components/Confirmation/Confirmation';
import ModalComponent from './Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Select from '../../components/Select'
import { addObjectUMB, deleteObjectUMB, editObjectUMB, getDirectoryForModalList, getDirectoryObjectsUMBList } from '../../actions/directoryObjectsUMB';
import { getRoomTypesList } from '../../actions/roomTypes';
import { getTeachersList } from '../../actions/common';



const validationSchema = Yup.object().shape({
    short_title: Yup.string().required('обязательное поле').max(15, 'Должно быть не более 15 символов'),
    full_title: Yup.string().required('обязательное поле').max(60, 'Должно быть не более 60 символов'),
    // floor: Yup.string().required('обязательное поле').max(9, 'Должно быть не более 9 символов').matches(/^[0-9\x2D\x2C]+$/, "Пожалуйста, введите число"),
    // volume: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(999999, 'Должно быть не более 6 символов'),// TODO регулярка не работает + СЕРВЕР берет только цифры
    // floor: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(999999, 'Должно быть не более 6 символов'),// TODO регулярка не работает + СЕРВЕР берет только цифры
    // volume_groups: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(999999, 'Должно быть не более 6 символов'),
    // area: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(99999, 'Должно быть не более 5 символов'),
    area_total: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999, 'Должно быть не более 5 символов'),
    area_science: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999, 'Должно быть не более 5 символов'),
    area_learning: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999, 'Должно быть не более 5 символов'),
    area_working: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999, 'Должно быть не более 5 символов'),
    capacity_groups: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999, 'Должно быть не более 5 символов'),
    capacity_students: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999, 'Должно быть не более 5 символов'),
    rooms_learning: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999, 'Должно быть не более 5 символов'),
    rooms_total: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999, 'Должно быть не более 5 символов'),
    address: Yup.string().nullable().max(100, 'Должно быть не более 100 символов'),
    measurements: Yup.string().nullable().max(100, 'Должно быть не более 100 символов'),
    // section: Yup.string().required('обязательное поле').max(15, 'Должно быть не более 15 символов'),
    // extra_info: Yup.string().required('обязательное поле').max(255, 'Должно быть не более 255 символов'),
})

const DirectoryObjectsUMB = () => {

    const dispatch = useDispatch();
    const { tfo_list, loading, tfo_types } = useSelector(state => state.rooms)
    const { list } = useSelector(state => state.roomTypes);
    const { teachers } = useSelector(state => state.common);
    const { listUMB, listModal } = useSelector(state => state.directoryObjectsUMB)

    const [modalOpen, setModalOpen] = useState(null);

    const [confirmation, setConfirmation] = useState(false)
    const [formData, setFormData] = useState([])
 
    const acceptConfirmation = () => {
        dispatch(deleteObjectUMB(confirmation.tfoid));
        setConfirmation(false);
    };
    const rejectConfirmation = () => {
        setConfirmation(false);
    };

    useEffect(() => {
        dispatch(getRoomsList());
        dispatch(getDirectoryObjectsUMBList());
       
        dispatch(getTFOTypeList());
        dispatch(getTFOList());
        dispatch(getTeachersList());
        dispatch(getRoomTypesList());
    }, []);

    useEffect(() => {
        dispatch(getDirectoryObjectsUMBList(formData));
    }, [formData]);
    
    useEffect(() => {
         dispatch(getDirectoryForModalList());
    }, [listUMB]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleCreate = (values) => {
            dispatch(addObjectUMB({
                ...values, 
                baseid: Number(values?.tfoid),
                typeid: Number(values?.typeid),
                // baseid: Number(values?.baseid),
                teacher_mid: Number(values?.teacher_mid),
                isactive: Number(values?.isactive),
                isppe: Number(values?.tfoid),

            }))
            closeModal()
        }

    const handleUpdate = () => {
        dispatch(editObjectUMB({
            ...values, 
            baseid: Number(values?.tfoid),
            typeid: Number(values?.typeid),
            // baseid: Number(values?.baseid),
            teacher_mid: Number(values?.teacher_mid),
            isactive: Number(values?.isactive),
            isppe: Number(values?.tfoid),
            tfoid: Number(values?.tfoid),
        }))
        closeModal()
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        resetForm,
    } = useFormik({
        initialValues: modalOpen ? modalOpen : {},
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit:  modalOpen?.tfoid ? handleUpdate :  handleCreate 
    })
 
    const error = (fieldName) => errors[fieldName]

    const handleModal = (data) => {
        setModalOpen(listModal?.find(el => el.tfoid === data.tfoid))
    }

    const openModal = () => setModalOpen({});
    const closeModal = () => {
        resetForm({})
        setModalOpen(false);
    }

    return (
        <div className={styles.wrapper}>
            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                title="Удалить объект?"
                confirmation={'"' + confirmation?.short_title + '"'}
                acceptConfirmation={() => acceptConfirmation(confirmation)}
                rejectConfirmation={rejectConfirmation} />)}
            {modalOpen != null
                ? <ModalComponent
                    modalOpen={modalOpen}
                    closeModal={closeModal}
                    setModalOpen={setModalOpen}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    values={values}
                    error={error}
                />
                : <></>}

            {loading && <div className={styles.loader}><Loader /></div>}
            <h1>Справочник объектов УМБ</h1>
            <div className={styles.wrapperPanel}>
                <div className={styles.wrapperSelect}>
                    <Select
                        title="База объекта"
                        name={"tfobid"}
                        options={tfo_types && [
                            {
                                name: "Все",
                                value: '0'
                            },
                            ...tfo_types
                        ]}
                        value={formData
                            ?.tfo}
                        withoutArrowMode
                        onChange={handleInputChange}
                        style={{ minWidth: '500px' }}
                    />
                    <Select
                        title="Тип объекта"
                        name={"tfotid"}
                        options={tfo_types && [
                            {
                                name: "Все",
                                value: '0'
                            },
                            ...tfo_types
                        ]}
                        value={formData
                            ?.tfo_type}
                        withoutArrowMode
                        onChange={handleInputChange}
                        style={{ minWidth: '500px' }}
                    />
                    <Select
                        title="Ответственный"
                        name={"mid"}
                        options={teachers && [
                            {
                                name: "Все",
                                value: '0'
                            },
                            ...teachers?.teachers
                        ]}
                        value={formData
                            ?.teachers}
                        withoutArrowMode
                        onChange={handleInputChange}
                        style={{ minWidth: '500px' }}
                    />
                </div>
            </div>
            {!loading
                ? <> <div className={styles.tableWrapper}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Название</th>
                                <th>База объекта</th>
                                <th>Тип объекта</th>
                                <th>Ответственный</th>
                                <th>Площадь объекта, м2</th>
                                <th>Вместимость, чел</th>
                                <th>Удалить</th>
                            </tr>
                        </thead>
                   
                        <tbody>
                            {listUMB
                                ?.map(el => <tr key={el.tfoid}>

                                    <td onClick={() => handleModal(el)}>{el.short_title}</td>
                                    <td onClick={() => handleModal(el)}>{el.base_title}</td>
                                    <td onClick={() => handleModal(el)}>{el.type_title}</td>
                                    <td onClick={() => handleModal(el)}>{el.fio}</td>
                                    <td onClick={() => handleModal(el)}>{el.area_total}</td>
                                    <td onClick={() => handleModal(el)}>{el.capacity_students}</td>
                                    <td onClick={() => setConfirmation(el)}>
                                        <i className={styles.trash}><BiTrash/></i>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>

                    <div className={styles.buttons}>
                        <Button onClick={openModal}>Добавить</Button>
                    </div>
                </>
                : <></>}
        </div>
    )
}

export default DirectoryObjectsUMB
import * as types from "../actions/types";


const initialState = {
  info: [],
 
};


export default function info_main (state = initialState, action) {
  switch (action.type) {
 
    case types.GET_INFO_MAIN:
      return {
        ...state,
        info: action.payload
      };
  
   
    default:
      return state;
  }
}
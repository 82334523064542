import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles.module.scss'
import Table from '../../../components/Table'
import { getScheduleSettingsLessons, getScheduleSettingsTeachersSubjects, updateScheduleSettingsTeachersSubjects, getScheduleSettingsCycle } from '../../../actions/scheduleSettings'
import Loader from '../../../components/Loader'
import { Checkbox, Select } from '../../../components/ModalInputs'


export default function TeachersSubjects() {

  const dispatch = useDispatch()
  const { scheduleSettingsLessons, scheduleSettingsTeachersSubList, loading, cycle } = useSelector(state => state.schedule_settings)
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(getScheduleSettingsLessons())
    dispatch(getScheduleSettingsTeachersSubjects())
    dispatch(getScheduleSettingsCycle())
  }, [])

  useEffect(() => {
    dispatch(getScheduleSettingsTeachersSubjects())
  }, [])

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target
    setSelected({
      ...selected,
      [name]: value === 'on' ? checked : value
    })
  }
  // const handleFilterChange = (e) => {
  //   const { name, value, } = e.target
  //   setCurrFilters({
  //     ...currFilters,
  //     [name]:  value === 'on' ? checked : value
  //   })
  // }

  const selectedCell = (data) => () => {
    const res = { "mid": data[0], "cid": data[1] }
    dispatch(updateScheduleSettingsTeachersSubjects(res))
  }

  const renderLi = (data) => {
    if (data) return (
      <tr key={data[0]}>
        <td className={styles.fio}>{data[1]}</td>
        {data[2].map((el, i) =>
          <td
            key={el[0]}
            onClick={selectedCell([data[0], scheduleSettingsLessons?.[i].cid])}
            style={{ backgroundColor: el[1] === 1 && '#3689FF' }}

          ></td>)}
      </tr >
    )
  }

  return (
    loading ? <Loader /> :
      <div className={styles.inputsWrapper}>
        <div className={styles.selectWrapper}>
          <Select
            label='Цикл'
            // value={values?.cid}
            onChange={(e) => handleInputChange(e)}
            name='did'
            style={{ width: '500px' }}
          >
            {cycle && [{ name: 'Выберите цикл', did: null }, ...cycle]?.map(({ name, did }) => <option key={did} value={did}>{name}</option>)}
          </Select>

        </div>

        <Checkbox label='Только преподаватели цикла' onChange={(e) => handleInputChange(e)} name='scheduling' />

        <Table style={{ marginTop: '2rem', backgroundColor: 'white', position: 'relative', height: '70vh' }}>
          <thead className={styles.headSchSet}>
            <tr>
              <th className={styles.fio2}></th>
              {scheduleSettingsLessons?.map(el => <th>{el.title}</th>)}
            </tr>
          </thead>
          <tbody>

            {scheduleSettingsTeachersSubList?.map(renderLi)}

          </tbody>
        </Table>

      </div>
  )
}

import { useState } from 'react'
import {Admission, Adresses, CandidateInfo, Documents, Education, Main, Medicine, Parents, Priveleges} from './ModalTabs'
import Modal from 'react-responsive-modal'
import { candidatesModalModes } from '../../assets/const'
import styles from './styles.module.scss'
import userPhoto from '../../assets/images/user_photo.png'
import cn from 'classnames'
import { CandidatesContext } from '../../utils/context'

export default function ModalComponent ({candidateId, isOpen, closeModal}) {
    
    const [mode, setMode] = useState('main')
    
    const renderLi = ({name, value, icon}) => {
        const selected = value === mode
        return (
            <li
                onClick={() => setMode(value)}
                className={cn(styles.modalMenuItem, {
                    [styles.selectedMenuItem]: selected
                })}
            >
                {icon()}
                {name}
            </li>
        )
    }

    const renderTab = () => {
        switch (mode) {
            case 'main': return <Main/>
            case 'adresses': return <Adresses/>
            case 'priveleges': return <Priveleges/>
            case 'parents': return <Parents/>
            case 'admission': return <Admission/>
            case 'medicine': return <Medicine/>
            case 'education': return <Education/>
            case 'documents': return <Documents/>
            case 'candidateInfo': return <CandidateInfo/>
            default: return
        }
    }

    return (
        <CandidatesContext.Provider value={{candidateId, closeModal}}>
            <Modal
                open={isOpen}
                classNames={{ modal: styles.modalMain }}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                showCloseIcon={false}  
                animationDuration={1}
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalMenu}>
                        <img src={userPhoto} alt='o_O'/>
                        <ul>{candidatesModalModes.map(renderLi)}</ul>
                    </div>
                    {renderTab()}
                </div>
            </Modal>
        </CandidatesContext.Provider>
    )
}
import { api } from "../../App";
import { getObjectFromArray } from "../../utils/smallHelpers";
import { SET_SETTINGS_LOADING, SET_SETTINGS_MAIN_DATA } from "../types";



export const updateSettingsData = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const response = await api.put('/options', data)
      // dispatch({ type: SET_SETTINGS_MAIN_DATA, payload })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};
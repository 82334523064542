import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createGradeWeight, updateGradeWeight } from '../../../actions/directory'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { SketchPicker } from 'react-color'



export default function Main ({closeModal, data, addMode, selectedItem, }) {

  const getColor = (newcolor) => {  // ВЫНИМАЮ ЦВЕТ ИЗ ПАЛИТРЫ
   setFieldValue('color', newcolor.hex.slice(1))
  }

  const dispatch = useDispatch()

  const handleCreateWeight =  (values) => {
    const forRequest =
      {
        weight: +values.weight,
        enabled: values.enabled ? 0 : -1,
        color: values.color,
      }

      dispatch(createGradeWeight(forRequest))  
      closeModal()
  }

  const handleCheck =  (values) => {
    const forRequest =
      {
        weight: +values.weight,
        enabled: values.enabled ? -1 : 0,
        color: values.color,
      }

      dispatch(updateGradeWeight(forRequest, values.weight))  
      closeModal()
  }

  const {
    values, 
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: !addMode ? {...selectedItem, enabled: selectedItem.enabled !== 0 ? true : false } : 
    {
      weight: 0,
      enabled: false,
      color:0
    },

    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: !addMode ? handleCheck : handleCreateWeight 
  })

  function validateGrade(str) {
    const pattern = /^([2345])$/;
    return pattern.test(str) ;
}
return (
!!values && 
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
          <div> 
              <Input 
                label='Вес'
                name='weight'
                value={values.weight}
                error={addMode ? values?.weight ? 
                          !validateGrade(values?.weight) ?   'Не более 1 символа и допустимые веса 2,3,4,5'  : ''
                          : 'Обязательное поле'
                        : ''}
                onChange={handleChange}
                style={!addMode ? { opacity: '0.5',
                  cursor: 'not-allowed',
                  backgroundColor: '#f0f0f0'} : {margin: '5px 30px 20px 0px', height: '45px'}}
                disabled = {!addMode}
              />

              {/* <Select label='Цвет' style={{width: '200px' , padding: '12px'}} value={values.color} onChange={handleChange} name='color'>
              {colors.map(({name}) => <option value={name} style={{backgroundColor: `#${name}`}}><div style={{width:'40px', height:'20px',backgroundColor: `${name}`}}>{name}</div></option> )}  
              </Select> */}
              <Checkbox  label='Доступен' isChecked={values.enabled}  value={values.enabled} onChange={handleChange} name='enabled'  style={{margin: '15px'}} />
            </div> 
            <div> 
              <p style={{color: '#778AAF',fontSize: '13px'}}>Цвет</p>
              <br />
              <SketchPicker name='color' color={values.color} onChange={getColor} />
            </div>

        </div>
        </div>
      </div>
      {/* <Button mode='white' onClick={()=>handleCreateYear(values)}>Сохранить</Button> */}
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )

}

import ReactPaginate from "react-paginate"
import styles from "./styles.module.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Puff } from "react-loader-spinner";



export const Pagination = ({
    pageCount,
    onPageChange = () => {},
    pageNumber = 0,
    children,
    loading   
}) => {

    const Paginate = () => (
        <div className={styles.paginateWrapper}>
                {pageCount > 0 &&
                <ReactPaginate
                    previousLabel={pageNumber > 0 && <IoIosArrowBack size={22}/>}
                    nextLabel={pageCount-pageNumber > 1 && <IoIosArrowForward size={22}/>}
                    breakLabel={"..."}
                    marginPagesDisplayed={2}
                    pageCount={pageCount || 1}
                    pageRangeDisplayed={5}
                    forcePage={pageNumber}
                    onPageChange={onPageChange}
                    containerClassName={styles.container}
                    conta
                    activeClassName={styles.active}
                    breakClassName={styles.break}
                />
                }
                {loading &&
                    <Puff
                        height="20"
                        width="20"
                        radius={1}
                        color='#376fb9'
                        ariaLabel="puff-loading"
                        wrapperClass={styles.loader}
                    />
                }
            </div>
    )


    return (
        <>
            <Paginate/>
                {children}
            <Paginate/>
        </>
    )
}
import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Main, About, Candidates, Results, Administration, Export, Kiosk, Strength, Control } from './Tabs'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsContext } from '../../utils/context';
import { updateSettingsData } from '../../actions/settings';
import moment from 'moment';
import { getSchoolYears } from '../../actions/common';

export default function Settings () {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const goHome = () => navigate('/')

  const {
    main,
    about,
    candidates,
    results,
    administration,
    export: exportData,
    kiosk,
    strength,
    control,
  } = useSelector(state => state.settings)


  const saveChanges = values => {
    const payload = {...values, ...noticeSettingsForm.values}
    dispatch(updateSettingsData(payload))
  }

  const form = useFormik({
    initialValues: {},
    onSubmit: saveChanges
  })

  useEffect(() => { dispatch(getSchoolYears()) }, [])

  useEffect(() => { form.setValues({...form.values, ...main})}, [main])

  useEffect(() => {
    if (!about) return;
    const Foundation_date = moment(about.Foundation_date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    form.setValues({...form.values, ...about, Foundation_date})
  }, [about])

  useEffect(() => { form.setValues({...form.values, ...results})}, [results])

  useEffect(() => { form.setValues({...form.values, ...administration})}, [administration])

  useEffect(() => { form.setValues({...form.values, ...exportData})}, [exportData])

  useEffect(() => { form.setValues({...form.values, ...kiosk.data})}, [kiosk.data])

  useEffect(() => { form.setValues({...form.values, ...strength.data})}, [strength.data])

  useEffect(() => { form.setValues({...form.values, ...control})}, [control])

  const noticeSettingsForm = useFormik({
    initialValues: candidates.notices || {},
    enableReinitialize: true,
    onSubmit: console.log
  })

  const examTicketsForm = useFormik({
    initialValues: candidates.exam_tickets || {},
    enableReinitialize: true,
    onSubmit: console.log
  })


  return (
    <SettingsContext.Provider value={{form, noticeSettingsForm, examTicketsForm}}>
      <div className={styles.wrapper}>
          <h1>Настройки</h1>
          <Tabs>
              <TabList className={styles.tabsBlock}>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Главная</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Об образовательной организации</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Претенденты</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Итоги</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Администрация</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Экспорт в конс. базу</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Киоск</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Численность</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Администрирование</Tab>
              </TabList>
              
              <TabPanel>
                  <Main/>
              </TabPanel>

              <TabPanel>
                  <About/>
              </TabPanel>

              <TabPanel>
                  <Candidates/>
              </TabPanel>

              <TabPanel>
                  <Results/>
              </TabPanel>

              <TabPanel>
                  <Administration/>
              </TabPanel>

              <TabPanel>
                  <Export/>
              </TabPanel>

              <TabPanel>
                  <Kiosk/>
              </TabPanel>

              <TabPanel>
                  <Strength/>
              </TabPanel>

              <TabPanel>
                  <Control/>
              </TabPanel>

          </Tabs>
          <footer className={styles.footer}>
              <Button mode='white' onClick={form.handleSubmit}>Сохранить</Button>
              <Button mode='white' onClick={goHome}>Закрыть</Button>
          </footer>
      </div>
    </SettingsContext.Provider>
  )
}

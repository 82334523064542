import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import Li from '../../components/Li'
import { IoIosClose } from 'react-icons/io'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { createEventLevel, deleteEventLevel, getEventLevels, updateEventLevel } from '../../actions/event_levels'
import { BiTrash } from 'react-icons/bi'



const EventLevels = () => {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.event_levels)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    dispatch(getEventLevels())
  }, [])
  
  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => (
    <Li onClick={openModal(item)}>
      {item.news_level}
      <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.news_level_id)}/>
    </Li>
  )
  
  const acceptConfirmation = () => {
    dispatch(deleteEventLevel(confirmation))
    setConfirmation(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Уровни мероприятий</h1>
      <ul>{list?.map(renderLi)}</ul>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.news_level_id}
      />
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Уверены что хотите удалить запись?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({open, closeModal, data, addMode}) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? {} : data)
  }, [open])
  

  const createItem = async (values) => {
    const success = await dispatch(createEventLevel(values))
    success && closeModal()
  }
  
  const updateItem = async ({news_level_id, ...values}) => {
    const success = await dispatch(updateEventLevel(news_level_id, values))
    success && closeModal()
  }

  const {
    values,
    errors, 
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    onSubmit: addMode ? createItem : updateItem
  })
  
  console.log(values)

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='news_level'
          label='Название'
          value={values.news_level}
          onChange={handleChange}
        />
        <Checkbox
          label='Закрыт'
          checked={!!values.closed}
          onChange={() => setFieldValue('closed', Number(!values.closed))}
        />
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}


export default EventLevels
import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  SET_CURRENT_SCHEDULE_VARIANT,
  SET_SCHEDULE_VARIANTS_COUNT,
  SET_SCHEDULE_VARIANTS_LIST,
  SET_SCHEDULE_VARIANTS_LOADING,
  SET_SCHEDULE_VARIANTS_PAGE
} from "./types";


export const setPage = (pageNumber) => ({type: SET_SCHEDULE_VARIANTS_PAGE, payload: pageNumber })

export const getScheduleVariantsList = (id) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: true });
    const { pageNumber } = getState().schedule_variants
    const limit = itemsOnPage
    const offset = itemsOnPage * pageNumber
    const urlParams = new URLSearchParams({limit, offset}).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_variant${id ? `/year/${id}` : ''}`, {
    // ?${urlParams}
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {

      const {cnt: count, schedule_variants} = await response.json();
      const pageCount = Math.ceil(count / itemsOnPage)

      dispatch({ type: SET_SCHEDULE_VARIANTS_LIST, payload: schedule_variants });
      dispatch({ type: SET_SCHEDULE_VARIANTS_COUNT, payload: {pageCount, count} });

    }
  }
  dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: false });
};

export const getCurrentScheduleVariant = (scheId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_variant/${scheId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CURRENT_SCHEDULE_VARIANT, payload });
    }
  }
  dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: false });
};

export const deleteScheduleVariant = (scheId, yearId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_variant/${scheId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const {list, pageNumber} = getState().schedule_variants
      list.length < 2 && dispatch(setPage(pageNumber - 1)) 
      await dispatch(getScheduleVariantsList(yearId));
    }
  }
  dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: false });
};

export const updateScheduleVariant = (scheId, data, yearId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_variant/${scheId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getScheduleVariantsList(yearId));
    }
  }
  dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: false });
};

export const createScheduleVariant = (data, yearId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_variant`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getScheduleVariantsList(yearId));
    }
  }
  dispatch({ type: SET_SCHEDULE_VARIANTS_LOADING, payload: false });
};

import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'


export default function Input ({label, className, ...props}) {
  return (
    <label className={cn(styles.label, className)}>
        <p>{label}</p>
        <textarea className={styles.input} {...props}/>
        <p className={styles.errorText}>{props.error}</p>
    </label>
  )
}

import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { planningModalModes } from '../../assets/const'
import cn from 'classnames'
import {Planning, Homework, Files, About, Literature} from './ModalTabs'
import ModalFooter from '../../components/ModalFooter/ModalFooter'


export default function ModalComponent ({isOpen, closeModal, handleSubmit}) {
  
  const [mode, setMode] = useState('planning')
  
  const renderLi = ({name, value, icon}) => {
      const selected = value === mode
      return (
          <li
              onClick={() => setMode(value)}
              className={cn(styles.modalMenuItem, {
                  [styles.selectedMenuItem]: selected
              })}
          >
              {icon()}
              {name}
          </li>
      )
  }

  const renderTab = () => {
      switch (mode) {
          case 'planning': return <Planning/>
          case 'homework': return <Homework/>
          case 'files': return <Files/>
          case 'about': return <About/>
          case 'literature': return <Literature/>
          default: return
      }
  }
  
  return (
    <Modal
        open={isOpen}
        classNames={{ modal: styles.modalMain }}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}  
        animationDuration={1}
    >
        <div className={styles.modalContent}>
            <div className={styles.modalMenu}>
                <ul>{planningModalModes.map(renderLi)}</ul>
            </div>
            <div className={styles.modalMain}>{renderTab()}</div>
            <div className={styles.modalFooter}>
                <div></div>
                <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal}/>
            </div>
        </div>
    </Modal>
  )
}

import React from 'react'
import styles from './styles.module.css'
import { Textarea } from '../../../components/ModalInputs'

const Literature = () => {
  return (
    <div className={styles.literatureWrapper}>
      <Textarea label='Список литратуры' className={styles.textarea} />
    </div>
  )
}

export default Literature
import { api } from "../../App";
import { SET_STUDENTS_DATA_MAIN, SET_STUDENTS_LOADING } from "../types";



export const getStudentMain = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_STUDENTS_LOADING, payload: true });
      const { data } = await api.get(`/student/${id}`)
      dispatch({ type: SET_STUDENTS_DATA_MAIN, payload: data })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_STUDENTS_LOADING, payload: false });
    }
};

export const updateStudentMain = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.put(`/student/${id}`, data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const createStudentMain = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
  await api.post('/students', data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const deleteStudentMain = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.delete(`/student/${id}`)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};
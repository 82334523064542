import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCandidatesNotices, getCandidatesNoticesStat } from '../../../../actions/settings/candidates'
import styles from './styles.module.scss'
import { Checkbox, Input, Textarea } from '../../../../components/ModalInputs'
import { SettingsContext } from '../../../../utils/context'


const Notices = () => {
    
  const dispatch = useDispatch()
  const { noticeSettingsForm } = useContext(SettingsContext)

  useEffect(() => {
    dispatch(getCandidatesNotices())
    dispatch(getCandidatesNoticesStat())
  }, [])

  
  const { values, handleChange, setFieldValue } = noticeSettingsForm
  
  const setCheckboxValue = () => {
    const newValue = values.ApplicantSMTPUseTLS === '1' ? '0' : '1'
    setFieldValue('ApplicantSMTPUseTLS', newValue)
  }

  return (
    <div>
      <div className={styles.paramsChildBLock}>
        
        <p>Электронная рассылка</p>

        <Input
          label='Почтовый адрес'
          name='ApplicantMail'
          style={{width: '100%'}}
          value={values.ApplicantMail}
          onChange={handleChange}
          />

        <div className={styles.inputsRow}>
          <Input
            label='Сервер'
            name='ApplicantSMTPHost'
            value={values.ApplicantSMTPHost}
            onChange={handleChange}
          />

          <Input
            label='Порт'
            name='ApplicantSMTPPort'
            value={values.ApplicantSMTPPort}
            onChange={handleChange}
          />

          <Checkbox
            label='Использовать TLS'
            checked={parseInt(values.ApplicantSMTPUseTLS)}
            onChange={setCheckboxValue}
          />
        </div>

        <div className={styles.inputsRow}>
          <Input
            label='Имя пользователя'
            name='ApplicantSMTPUser'
            value={values.ApplicantSMTPUser}
            onChange={handleChange}
          />

          <Input
            label='Пароль'
            name='ApplicantSMTPPassword'
            type='password'
            value={values.ApplicantSMTPPassword}
            onChange={handleChange}
          />
        </div>

        <Textarea
          label='Текст уведомления о приеме заявки'
          name='ApplicantConfirmText'
          className={styles.noticeTextarea}
          value={values.ApplicantConfirmText}
          onChange={handleChange}
        />

      </div>
    </div>
  )
}

export default Notices
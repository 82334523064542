import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import {cardItems, menuItems, version} from '../../assets/const'
import {BsFillLockFill} from "react-icons/bs";
import Button from '../../components/Button'
import Modal from 'react-responsive-modal';
import {useDispatch, useSelector} from 'react-redux';
import {getInfoMain} from '../../actions/infoMain';
import logo from '../../assets/images/logo.png'

export default function Main() {

    const [modalData, setModalData] = useState(null)
    const dispatch = useDispatch()
    const {info} = useSelector(state => state.info_main)

    const openModal = (data) => () => setModalData(data)
    const closeModal = () => setModalData(null)

    useEffect(() => {
        dispatch(getInfoMain())
    }, [])

    const renderCard = ({color1, color2, title, icon, link}) => {

        const gradientStyle = {
            background: `linear-gradient(45deg, ${color1}, ${color2})`
        }

        return (
            <a className={styles.card} href={link}>
                <li style={gradientStyle}>
                    <span>{title}</span>
                    <div className={styles.after}>
                        <img src={icon} alt='o_O'/>
                    </div>
                </li>
            </a>
        )
    }

    const checkOverflow = (menu) => {
        if (menu.find(el => el.menu)) {
            return
        } else 
            return {maxHeight: `calc(100vh - 250px)`, overflowY: 'auto', overflowX: 'hidden'}
        }

    const renderLastLevelLi = ({link, label, type}) => {
        const disactive = link.length < 2
        return (
            <li>
                {type === "button"
                    ? <a href="#" onClick={openModal(true)} key={label}>
                            {label}
                        </a>
                    : <a
                        href={link}
                        style={disactive
                        ? {
                            color: 'grey',
                            pointerEvents: 'none'
                        }
                        : null}
                        key={label}>
                        {label}
                        <i>
                            {disactive && <BsFillLockFill color='grey'/>}
                        </i>
                    </a>
}
            </li>
        )
    }

    const renderMenuItem = (item) => {
        const {label, link, icon, menu} = item
        return (
            <li className={styles.menuItem}>
                {icon()}
                <div>{label}</div>
                {< ul className = {
                    styles.submenu
                }
                style = {
                    checkOverflow(menu)
                } > {
                    menu
                        ? menu.map(el => el.menu
                            ? <li className={styles.submenuEl}>
                                    <a href={el.link} onClick={e => el.menu && e.preventDefault()}>
                                        <span>{el.label}</span>
                                        <i className={styles.fa}>
                                            <svg
                                                width="6"
                                                height="11"
                                                viewBox="0 0 6 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 5.5L1 10" stroke="#314561" strokeLinecap="round"/></svg>
                                        </i>
                                    </a>

                                    <ul className={`${styles.submenu} ${styles.submenu2}`}>
                                        {el
                                            ?.menu
                                                ?.map(_el => <> {
                                                    _el.menu
                                                        ? <li key={_el.link}>
                                                                <a href={_el.link} onClick={e => _el.menu && e.preventDefault()}>
                                                                    <span>{_el.label}</span>
                                                                    <i className={styles.fa}>
                                                                        <svg
                                                                            width="6"
                                                                            height="11"
                                                                            viewBox="0 0 6 11"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 5.5L1 10" stroke="#314561" strokeLinecap="round"/></svg>
                                                                    </i>
                                                                </a>

                                                                <ul className={`${styles.submenu222}`}>
                                                                    {_el
                                                                        ?.menu
                                                                            ?.map(__el => <> {
                                                                                __el.menu
                                                                                    ? <li>
                                                                                            <a href={__el.link} onClick={e => __el.menu && e.preventDefault()}>
                                                                                                <span>{__el.label}</span>
                                                                                                <i className={`${styles.fa} ${styles.fa2}`}>
                                                                                                    <svg
                                                                                                        width="6"
                                                                                                        height="11"
                                                                                                        viewBox="0 0 6 11"
                                                                                                        fill="none"
                                                                                                        xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 5.5L1 10" stroke="#314561" strokeLinecap="round"/></svg>
                                                                                                </i>
                                                                                            </a>
                                                                                        </li>
                                                                                    : renderLastLevelLi(__el)
                                                                            } </>
                                    )}
                                                                </ul>
                                                            </li>
                                                        : renderLastLevelLi(_el)
                                                } </>
                        )}
                                    </ul>
                                </li>
                            : renderLastLevelLi(el))
                        : <li>
                                <a href={link} key={label}>{label}</a>
                            </li>
                } </ul>}
            </li>
        )
    }

    return ( <> <ul className={styles.menu}>
            {menuItems.map(renderMenuItem)}
        </ul>
        <ul className = {
        styles.wrapper
    } > {
        cardItems.map(renderCard)
    } </ul>
        <footer className={styles.footer}>
            <div onClick={openModal(true)}>{version}</div > <p>
        Федеральное государственное казенное общеобразовательное учреждение
        «Санкт-Петербургский кадетский корпус «Пансион воспитанниц Министерства обороны
        Российской Федерации»
    </p> </footer>

      <Modal
      open={!!modalData}
      onClose={closeModal}
      onEscKeyDown={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={1}
      classNames={{modal: styles.reportModal}}
      style={{padding: '0px',marginTop: '10%'}}
    >
    <div className={styles.modalMain}>
     
      <div  className={styles.paramsBlockClassbook}>

    

        <div className={styles.modalMainHigh} > 
        <div style={{display: "flex"}}>
        <img style={{width: '30%'}}src={'/images / LMSLogo.png '} alt=' o_O '/>
 < div style = {{display: "flex", flexDirection: "column", justifyContent:"flex-end", padding:'0 0 0 60px'}} > <p>Copyright &copy; ООО "Нинтегра".</p> < p > Все права защищены </p>
        </div > </div> < br /> <div
        style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginRight: '35%'
    }}>

        <div
            style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            padding: '0 0 0 30px',
            alignItems: "end"
        }}>
            <p>Версия программы:</p>
            <p>Версия API:</p>
            <p>версия Python:</p>
            <p>Host:</p>
        </div>

        <div
            style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            padding: '0 0 0 30px',
            alignItems: "start"
        }}>
            <p>{version}</p>
            <p>{info.APIversion}</p>
            <p>{info.python}</p>
            <p>{info.host}</p>
        </div>

    </div> < br /> <img src={logo} alt='o_0'/> < br /> <span>+7 495 780 76 71</span> < br /> <a href='https://www.nintegra.ru/' target='blank'>www.nintegra.ru</a> < br /> <Button
        style={{
        marginBottom: '20px',
        position: 'relative',
        left: '300px'
    }}onClick={closeModal}>Закрыть</Button> </div>

      </div > </div> </Modal>
    </>)
}

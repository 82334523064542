const parseNode = (node = {}) => {
  return (
      {
          ...node,
          cid: parseInt(node.cid),
          oid: parseInt(node.oid),
          prev_ref: parseInt(node.prev_ref),
          prev_refs: parseInt(node.prev_refs),
          level: parseInt(node.level),
          position: parseInt(node.position)
      }
  )
}

export function buildTree (list = []) {
  let map = {},
      prevNode,
      node,
      roots = [],
      i,
      newNode,
      cid = -1

  list.sort((a, b) => parseInt(a.prev_ref) - parseInt(b.prev_ref))

  for (i = 0; i < list.length; i += 1) {
      map[list[i].oid] = i
      list[i].children = []
  }

  for (i = 0; i < list.length; i += 1) {
      node = list[i]
      if (parseInt(node.cid) != cid) {
          newNode = {
              coursetitle: node.coursetitle,
              title: node.coursetitle,
              oid: i,
              cid: parseInt(node.cid),
              children: [],
          }

          newNode.children.push(parseNode(node))
          roots.push(newNode)
      } else {
          if (parseInt(node.prev_ref) !== -1) {
              if (parseInt(list[map[node.prev_ref]]?.level) < parseInt(node?.level)) {
                  list[map[node.prev_ref]].children.push(parseNode(node))
                  prevNode = list[map[node.prev_ref]]
              } else {
                  if (prevNode) {
                      prevNode.children.push(parseNode(node))
                  } else {
                      newNode.children.push(parseNode(node))
                      prevNode = node
                  }
              }
          } else {
              prevNode = node
              newNode.children.push(parseNode(node))
          }
      }
      cid = parseInt(node.cid)
  }
  return roots?.[0]?.children || []
}

export const getDataForUpdate = (list, parentId, levelNum) => list.reduce((acc, chapter, ind, array) => {
  const {children, oid, editing, show, prev_refs, name, module, code, ...restChapter} = chapter
  
  const level = levelNum || 0
  const prev_ref = ind ? array[ind-1].oid : (parentId || -1)
  const position = ind

  const newChapterData = ({...restChapter, oid, level, prev_ref, position})

  return [...acc, newChapterData, ...getDataForUpdate(children || [], oid, level + 1)]
}, [])
import React from 'react'
import styles from './styles.module.css'
import Select from 'react-select/creatable';


export default function CreatableSelect({label, ...props}) {
  return (
    <label className={styles.label}>
      <p>{label}</p>
      <Select
        className={styles.creatableSelect}
        noOptionsMessage={() => 'Нет опций'}
        placeholder="Новый элемент"
        formatCreateLabel={userInput => `Создать ${userInput}`}
        {...props}
      />
    </label>
  )
}

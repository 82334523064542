import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import Select from '../../../components/Select'
import Leaf from '../../../components/Leaf'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {getCLasses, getParallels, getScheduleVariants, getSchoolYears, getShifts, getStudyForms, getStudySteps} from '../../../actions/common'
import queryString from 'query-string';
import { useFormik } from 'formik'
import { getSchedule } from '../../../actions/scheduling'


export default function Form () {
  
  const dispatch = useDispatch()
  const {schoolYears, shifts, scheduleVariants, studyForms, studySteps, parallels, classes} = useSelector(state => state.common)
  const navigate = useNavigate()
  const location = useLocation()
  
  const paramsForm = queryString.parse(location.search)
  
  useEffect(() => {
    !schoolYears.length && dispatch(getSchoolYears())
    !shifts && dispatch(getShifts())
    !studyForms && dispatch(getStudyForms())
    !studySteps && dispatch(getStudySteps())
    !parallels && dispatch(getParallels())
    !classes && dispatch(getCLasses())
  }, [])
  
  const {values, handleChange} = useFormik({
    initialValues: paramsForm
  })

  useEffect(() => {
    const searchParams = queryString.stringify(values)
    navigate(`/scheduling?${searchParams}`)
    const {var_id, ...params} = values
    if (!var_id) return;
    dispatch(getSchedule(var_id, params))
  }, [values])

  useEffect(() => {
    if (!values.scYear) return;
    dispatch(getScheduleVariants(values.scYear))
  }, [values.scYear])


  return (
    <div className = {styles.wrapperPanel}> 
        
        <div className={styles.inputsRow}>
              <Select
                title="Учебный год"
                name={"scYear"}
                options={[{name: "Выбрать", value: '0'}, ...schoolYears || []]}
                value={values.scYear}
                onChange={handleChange}
                withoutArrowMode
              />

              <Select
                title="Вариант расписания"
                name={"var_id"}
                options={[{name: "Все", value: '0'}, ...scheduleVariants || []]}
                value={values.var_id}
                onChange={handleChange}
                className={styles.sheVarSelect}
                withoutArrowMode
              />
        </div>

        <div className={styles.inputsRow}>
            <Select
              title="Форма обучения"
              name={"f_studyform"}
              options={[{name: "Все", value: '0'}, ...studyForms || []]}
              value={values.f_studyform}
              onChange={handleChange}
              withoutArrowMode
            />

            <Select
              title="Ступень обучения"
              name={"f_studysteps"}
              options={[{name: "Выбрать", value: '0'}, ...studySteps || []]}
              value={values.f_studysteps}
              onChange={handleChange}
              withoutArrowMode
            />
            
            <Select
              title="Смена"
              name={"shift"}
              options={[{name: "Выбрать", value: '0'}, ...shifts || []]}
              value={values.shift}
              onChange={handleChange}
              withoutArrowMode
            />
        </div>

        <div className={styles.inputsRow}>
            <Select
              title="Параллель"
              name={"parallel"}
              options={[{name: "Выбрать", value: '0'}, ...parallels || []]}
              value={values.parallel}
              onChange={handleChange}
              withoutArrowMode
            />
            
            <Select
              title="Класс"
              name={"gid"}
              options={[{name: "Выбрать", value: '0'}, ...classes || []]}
              value={values.gid}
              onChange={handleChange}
              withoutArrowMode
            />
        </div>

    </div>
  )
}

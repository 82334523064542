import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import { Input, Select } from '../../../components/ModalInputs'
import { admissionYears } from '../../../assets/const'
import { useDispatch, useSelector } from 'react-redux'
import { ClassingContext } from '../../../utils/context'
import { getGroupInfo } from '../../../actions/groups'
import { useFormik } from 'formik'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'



export default function Main () {
  
  const dispatch = useDispatch()
  const { classId, closeModal } = useContext(ClassingContext)
  const { groupInfo, modalLoading: loading } = useSelector(state => state.groups)
  const {
    schoolYears,
    specializations,
    languages,
    studyProfiles,
    studyModes,
    studyForms,
    studySteps,
    shifts,
    teachers
  } = useSelector(state => state.common)

  useEffect(() => {
    dispatch(getGroupInfo(classId))
  }, [])
  
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: groupInfo || {},
    enableReinitialize: true,
    onSubmit: console.log
  })
  
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input label='Название' value={values.name} onChange={handleChange} name='name'/>
          </div>
         
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
    
  )
}

import * as types from "../actions/types";



const initialState = {
  pageNumber: 0,
  pageCount: 0,
  count: 0,
  list: [],
  currentYear: {},
  loading: false
};


export default function schoolYears (state = initialState, action) {
  switch (action.type) {
    case types.SET_SCHOOL_YEARS_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_SCHOOL_YEARS_PAGE:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case types.SET_SCHOOL_YEARS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case types.SET_CURRENT_SCHOOL_YEAR:
      return {
        ...state,
        currentYear: action.payload,
      };
    case types.SET_SCHOOL_YEARS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
import React from 'react'
import { Input } from '../../../components/ModalInputs'



export default function DateTime({data, formik}) {
  return (
    <Input
      label={data.description}
      onChange={formik.handleChange}
      name={data.name}
      type='datetime-local'
    />
  )
}

import React, { useState } from 'react'
import styles from './index.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Table from '../../../components/Table'
import Empty from '../../../components/Empty'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../../components/Confirmation/Confirmation'
import { deleteAds } from '../../../actions/ads'

const Index = ({ date, setType }) => {

    const dispatch = useDispatch();
    const [confirmation, setConfirmation] = useState(false);

    const {list, loading} = useSelector(state => state.ads) // список объявлений из store

    const acceptConfirmation = () => {
        dispatch(deleteAds(confirmation.postid, date.from, date.to));
        setConfirmation(false);
      };
    
      const rejectConfirmation = () => {
        setConfirmation(false);
      };

  return (
    <div className={styles.container}>
        {confirmation && <Confirmation 
                    top="30px"
                    left="40px"
                    title="Удалить объявление?"
                    confirmation={confirmation.name} 
                    acceptConfirmation={() => acceptConfirmation(confirmation.postid)}
                    rejectConfirmation={rejectConfirmation}/>}
        {loading
            ? <Loader/>
            : !!list?.length
                ?   <Table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Имя</th>
                                <th>Предмет</th>
                                {/* <th>Описание</th> */}
                                <th>Удалить</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map((item, index) => (
                                <tr key={item.postid}>
                                    <td onClick={() => setType(item.postid)}>{item.posted}</td>
                                    <td onClick={() => setType(item.postid)} style={{textAlign: 'left'}}>{item.name}</td>
                                    <td onClick={() => setType(item.postid)}>{item.course}</td>
                                    {/* <td onClick={() => setType(item.postid)}>{item.text}</td> */}
                                    <td onClick={() => setConfirmation(item)}><i className={styles.trash}><BiTrash/></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                : <Empty/>
        }
    </div>
  )
}

export default Index
import React from 'react'
import styles from './MarkPanel.module.css' 

const MarkPanel = ({ cellChanging, handleOpenComment, student, gradeValue, setSelected, selected, classbook }) => {

    const handleSetColumn = (lessonid) => {
        const arr = classbook.filter((el) => el.lessonid == lessonid).map((el) => ({mid: el.mid, lessonid: el.lessonid}));
        if ((arr.length === selected.length) && (arr[0].mid === selected[0].mid)) {
            setSelected([]);
        } else {
            setSelected(arr);
        }
    }

    return (
        <div className={styles.markPanel}>
            {[
                {
                    valid: 0,
                    value: "",
                    text: null
                },
                ...gradeValue
            ].map((el, i) => (
                <div
                    className={styles.markPanelItem}
                    key={i}
                    onClick={() => cellChanging('grade', el.value)}>{el.value}</div>
            ))}
            <div>
                <div
                    className={styles.markPanelItem}
                    onClick={() => handleOpenComment({mid: student.mid, lessonid: student.lessonid})}>Комментарий</div>
            </div>
            <div className={styles.markPanelItemBottom}>
                <div className={`${styles.markPanelItem} ${styles.markPanelItemSelect}`}>Вес оценки
                    <div className={styles.markPanelSelectWrapper}>
                        {[
                            1,
                            2,
                            3,
                            4,
                            5,
                            6
                        ].map(el => <div 
                                        key={el} 
                                        className={styles.markPanelSelectItem}
                                        onClick={() => cellChanging('gradeweight', el)}
                                        >{student.gradeweight == el ? '+' : ''}{el}</div>)}
                    </div>
                </div>
                <div className={styles.markPanelItem} onClick={(() => handleSetColumn(student.lessonid))}>Отметить колонку</div>
            </div>
        </div>
    )
}

export default MarkPanel
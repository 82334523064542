import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import { createPeriod, updatePeriod } from '../../../actions/periods'
import moment from 'moment'
import { getSchoolYears, getShifts } from '../../../actions/common'
import cn from 'classnames'
import Table from '../../../components/Table'
import { methodOfficeTableClean, getMethodOfficeAccess, getMethodOfficeCycle, getMethodOfficeRoles, getMethodOfficeAccessFilteredPersonnel, getMethodOfficeAccessPersonnel,  getMethodOfficeFiles, createFolder } from '../../../actions/methodOffice'
import { getGroups } from '../../../actions/groups'
import { MethodOfficeContext } from '../../../utils/context'


const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле')
})


export default function Main () {
  
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.terms)
  const { schoolYears, shifts } = useSelector(state => state.common)
  const { cycle, roles, access, access_personnel_table } = useSelector(state => state.method_office)
  const { groups} = useSelector(state => state.groups)

  const {formik, closeModal, data, addMode, file, onSubmit, createFolderModal} = useContext(MethodOfficeContext) 
  const {values, setFieldValue, handleChange, resetForm } = formik
  
  const [classQ, setClassQ] = useState([]);

  useEffect(() => {
    !schoolYears && dispatch(getSchoolYears())
    !shifts && dispatch(getShifts())
  }, [])

  useEffect(() => {
    dispatch(getGroups())
  }, [])

  const getCorrectPayload = (values) => {
    let {starttime, stoptime} = values
    starttime = moment.duration(starttime).asMinutes()
    stoptime = moment.duration(stoptime).asMinutes()
    return {...values, starttime, stoptime}
  }

  const handleCreatePeriod = async (values) => {
    const payload = getCorrectPayload(values)
    await dispatch(createPeriod(payload))  
    closeModal()
    dispatch(methodOfficeTableClean())
  }
  
  const handleUpdatePeriod = async (values) => {
    let {lid, ...data} = values
    const payload = getCorrectPayload(data)
    await dispatch(updatePeriod(lid, payload))
    closeModal()
    dispatch(methodOfficeTableClean())
  }
const close =() => {
  onSubmit()
  setFieldValue('statusMethodOfficeClasses', [...classQ])
  closeModal()
  resetForm()
  dispatch(methodOfficeTableClean())
}

  const renderLi = (data) => {
    const {name,gid } = data
    return (
      <div key={gid} style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Checkbox 
         name='statusMethodOfficeClasses'
         checked={values?.status}
         onClick={()=> setClassQ((prev)=> [...prev, gid])}
         withPadding
         />
        <div>{name}</div>
      
      </div>
    )
  }

  const getTable = values => {
    dispatch(getMethodOfficeAccessFilteredPersonnel(values))
  }

  const renderOption = (option, index) => {
    const {  xp_key,  value, trmid, cid, gid, work_status, id, pmid, did ,  name, title, year, access, } = option
    const optionValue =   xp_key || value ||trmid ||cid || gid || work_status || id || pmid || did
    const optionTitle =  name || title || value || year || work_status || access
    return <option key={`${optionValue}-${index}`} value={optionValue}>{optionTitle}</option>
  }
// console.log(values)
// console.log(file.mrid)
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
  
            <Input
            style={{
              width: '750px',    
              display: 'flex',
              alignContent: 'center',
              flexWrap: 'wrap',
              marginRight: '10px'
             }}
            label='Название'
            name='mr_name'
            value= {values?.mr_name || data?.mr_name}
            onChange={handleChange}

          />
          <div style={{display: "flex"}}>

              <Checkbox
              label='Доступно учащимся'
              checked={!!values.access_students}
              onChange={()=> setFieldValue('access_students', 1 )}

              />

              <Checkbox
              label='Доступно родителям'
              checked={!!values.access_parents}
              onChange={()=> setFieldValue('access_parents', 1 )}

              />

          </div>
         
          </div>
        </div>
 

      
 
          <div className={styles.wrapper}>
          
              <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
               <thead>
                <tr>

                </tr>
                </thead>
               <tbody style={{display: 'flex', flexDirection:'row', flexWrap: 'wrap'}}>
               {!!values.access_students && groups?.groups?.map(renderLi)
               }

      
                </tbody>
              </Table>
      
           
         
          </div>

      </div>

     

      <div className={styles.modalFooter}>
      
   
  
   
      <Button mode='white' onClick={() => createFolderModal()} style={{marginRight: '10px'}}>Сохранить</Button>
        <Button mode='white' onClick={close}>Закрыть</Button>
      </div>
    </>
  )
}

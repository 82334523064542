import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateBenefits } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import Table from '../../../components/Table'



export default function Priveleges () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { benefits } = useSelector(state => state.candidates.data)

  useEffect(() => {
    dispatch(getCandidateBenefits(candidateId))
  }, [])
  
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <Table>
            <thead>
              <tr>
                <th>Льгота</th>
                <th>Дополнительно</th>
                <th>На кого</th>
              </tr>
            </thead>
            <tbody>
              
            </tbody>
          </Table>
        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={closeModal}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}

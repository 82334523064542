import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminData } from '../../../actions/settings/administration'
import { getAdminisList } from '../../../actions/common'
import styles from '../styles.module.scss'
import {Input, Select} from '../../../components/ModalInputs'
import { SettingsContext } from '../../../utils/context'



export default function Administration () {
  
  const dispatch = useDispatch()
  const { administration } = useSelector(state => state.settings)
  const { admins: adminsList } = useSelector(state => state.common)
  const { form } = useContext(SettingsContext)

  const { values, handleChange, handleBlur, setFieldValue } = form


  useEffect(() => {
    !administration && dispatch(getAdminData())
    !adminsList && dispatch(getAdminisList())
  }, [])
  
  const renderOptions = ({mid, fio}) => <option value={mid} key={mid} >{fio}</option>

  return (
    <div className={styles.adminsWrapper}>
      <div>
        <Input
          label='Название должности'
          name='director_post_name'
          style={{width: '320px'}}
          value={values.director_post_name}
          onChange={handleChange}
        />
        <Select
          label='ФИО сотрудника'
          name='director_mid'
          value={values.director_mid}
          onChange={handleChange}
        >
          {adminsList?.map(renderOptions)}
        </Select>
      </div>

      <div>
        <Input
          label='Название должности'
          name='zam_uch_post_name'
          style={{width: '320px'}}
          value={values.zam_uch_post_name}
          onChange={handleChange}
        />
        <Select
          label='ФИО сотрудника'
          name='zam_uch_mid'
          value={values.zam_uch_mid}
          onChange={handleChange}
        >
          {adminsList?.map(renderOptions)}
        </Select>
      </div>

      <div>
        <Input
          label='Название должности'
          name='zam_vosp_post_name'
          style={{width: '320px'}}
          value={values.zam_vosp_post_name}
          onChange={handleChange}
        />
        <Select
          label='ФИО сотрудника'
          name='zam_vosp_mid'
          value={values.zam_vosp_mid}
          onChange={handleChange}
        >
          {adminsList?.map(renderOptions)}
        </Select>
      </div>
    </div>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'


function PrivateRoute() {
  
  const { token: isAuth } = useSelector(state => state.auth)
  
  
  return isAuth ? <Outlet/> : <Navigate to='/auth' replace />
}


export { PrivateRoute }
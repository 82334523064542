import React, { useState } from 'react'
import {Main} from './ModalTabs'

import Modal from '../../components/Modal'

export default function ModalComponent ({closeModal, handleSubmit, height, selectedItem, }) {

  const [mode, setMode] = useState('main')

  const renderTab = () => {
      switch (mode) {
          case 'main': return <Main 
            closeModal={closeModal} 
            addMode={!selectedItem?.id}    
            selectedItem={selectedItem}
            />
          default: return
      }
  }

  return (
      <Modal
        open={!!selectedItem}
        menuItem={mode}
        setMenuItem={setMode}
        height={height}
      >
        {renderTab()}
      </Modal>
  )
}

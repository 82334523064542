import React from 'react'
import styles from './styles.module.css'
import { Checkbox, Input, Select, Textarea } from '../../../../../components/ModalInputs';
import { degreeOfCapacity, statusAccess } from '../../../../../assets/const';




const Parameters = ({ modalOpen, setModalOpen, tfo_list, tfo_types, teachers, roomTypes, closeModal, handleSubmit1, values, handleChange, error }) => {


    //     const dispatch = useDispatch()

    //     const handleCreateRoom =  () => {
    //         dispatch(addRooms(modalOpen))  
    //         closeModal()
    //       }

    //       const handleUpdateRoom =  () => {
    //         dispatch(editRooms(modalOpen))
    //         closeModal()
    //       }
    //     const {
    //         values, 
    //         errors,
    //         touched,
    //         submitCount,
    //         handleBlur,
    //         handleChange,
    //         handleSubmit,
    //         setFieldValue
    //       } = useFormik({
    //         initialValues: modalOpen ? {} : modalOpen,
    //         enableReinitialize: true,
    //         validateOnChange: true,
    //         validationSchema: validationSchema,
    //         onSubmit: handleSubmit1
    //       })

    //   const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

    return (
        <div className={styles.parameters}>
            <div className={styles.inputsRow}>
                <Input
                    label='Адрес'
                    name='address' //TODO
                    value={values.address}
                    onChange={handleChange}
                    error={error('address')}
                />
            </div>
            <br />
            <span>Площади</span>
            <div style={{ backgroundColor: 'aliceblue', paddingBottom: '7px' }}>
                <div className={styles.inputsRow}>
                    <Input
                        label='Общая, м2'
                        name='area_total'  // TODO
                        value={values.area_total}
                        onChange={handleChange}
                        error={error('area_total')}
                    />
                    <Input
                        label='Рабочая, м2'
                        name='area_working'  // TODO
                        value={values.area_working}
                        onChange={handleChange}
                        error={error('area_working')}
                    />
                    <Input
                        label='Учебная, м2'
                        name='area_learning'  // TODO
                        value={values.area_learning}
                        onChange={handleChange}
                        error={error('area_learning')}
                    />
                    <Input
                        label='Научная, м2'
                        name='area_science'  // TODO
                        value={values.area_science}
                        onChange={handleChange}
                        error={error('area_science')}
                    />
                </div>
            </div>
            <br />

            <div className={styles.inputsRow}>
                <Input
                    label='Размеры, этажность'
                    name='measurements' //TODO
                    value={values.measurements}
                    onChange={handleChange}
                    error={error('measurements')}
                />
            </div>
            <br />
            <span>Вместимость</span>
            <div style={{ backgroundColor: 'aliceblue', paddingBottom: '7px' }}>
                <div className={styles.inputsRow}>
                    <Input
                        label={'Учебных коллективов'}
                        name='capacity_groups'  // TODO
                        value={values.capacity_groups}
                        onChange={handleChange}
                        error={error('capacity_groups')}
                    />
                    <Input
                        label={'Учен. мест, чел'}
                        name='capacity_students'  // TODO
                        value={values.capacity_students}
                        onChange={handleChange}
                        error={error('capacity_students')}
                    />
                </div>
            </div>

            <br />
            <span>Количество помещений</span>
            <div style={{ backgroundColor: 'aliceblue', paddingBottom: '7px' }}>
                <div className={styles.inputsRow}>
                    <Input
                        label={'Общее'}
                        name='rooms_total'  // TODO
                        value={values.rooms_total}
                        onChange={handleChange}
                        error={error('rooms_total')}
                    />

                    <Input
                        label={'Учебных'}
                        name='rooms_learning'  // TODO
                        value={values.rooms_learning}
                        onChange={handleChange}
                        error={error('rooms_learning')}
                    />
                </div>
            </div>


        </div>
    )
}

export default Parameters
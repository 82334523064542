import React from 'react'
import styles from './styles.module.css'
import Table from '../../../components/Table'
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
const Tables = ({modalOpen}) => {

    const { list = [], loading } = useSelector(state => state.planning);

    const handleOpenPopup = (item) => {
        modalOpen(item);
    }

  return (
    <div className={styles.wrapper}>
        {loading ? <Loader/> :
        <Table>
                {list?.map((item, index) => (
                    <tr key={index} className={styles.tableRow} onClick={() => handleOpenPopup(item)}>
                        <td>{item.name}</td>
                        <td>{item.room}</td>
                        <td>{item.period}</td>
                        <td>{item.groupname}</td>
                        <td>{item.day}</td>
                    </tr>
                ))}
        </Table>
        }
        {(list?.length === 0 && !loading) && <div className={styles.noData}>Нет данных</div>}
    </div>
  )
}

export default Tables
import React, { useEffect, useState } from 'react'
import styles from './Modal.module.css'
import Modal from 'react-responsive-modal'
import Button from '../../../../../../components/Button'
import { Checkbox } from '../../../../../../components/ModalInputs'
import { BiTrash } from 'react-icons/bi'
import {useDispatch, useSelector} from 'react-redux'
import { getEquipmentsList } from '../../../../../../actions/rooms'

const ModalValue = ({closeModal, localModalOpen, handleAddValue, handleSubmit, modalOpen}) => {

    const dispatch = useDispatch();
    const {equipmentsList} = useSelector(state => state.rooms);
    const [addList, setAddList] = useState([]);
    
    useEffect(() => {
      dispatch(getEquipmentsList(modalOpen?.rid));
    }, [])

    const handleModal = (type) => {
        if (type === 'save') {
            handleAddValue(addList);
        } else {
            closeModal();
        }

        setAddList([]);
    }

    const handleAdd = (el) => {
        if (addList.includes(el)) {
            setAddList(addList.filter(_el => _el != el));
        } else  setAddList([...addList, el]);    
        }

  return (
    <Modal
        close={closeModal}
        open={localModalOpen}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}
        animationDuration={1}
        classNames={{
        modal: styles.roomTypesModal
    }}
        style={{
        padding: '0',
        margin: '0'
    }}>
      
        <div className={styles.title}>Выбор значения</div>

        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Название</th>
                    <th>Инвентарный номер</th>
                    <th>Состояние</th>
                    <th>Выбрать</th>
                </tr>
            </thead>
            <tbody>
                {equipmentsList?.map(el => <tr key={el?.idequipment}>
                    <td>{el?.equipname}</td>
                    <td>{el?.inventnumber}</td>
                    <td>{el?.status}</td>
                    <td><Checkbox checked={addList?.includes(el.idequipment)} onClick={() => handleAdd(el.idequipment)}/></td>
                </tr>)}
            </tbody>
        </table>

        <div className={styles.count}>Всего строк: {equipmentsList?.length}</div>

        <div className={styles.buttons}>
            <Button onClick={() => handleModal('save')}>Сохранить</Button>
            <Button onClick={() => handleModal('cancel')}>Закрыть</Button>
        </div>
    </Modal>
  )
}

export default ModalValue
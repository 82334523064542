import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { thrash } from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { getGroupNutritionList, deleteFormOfEducation, getNutritionGroup, deleteGroupNutrition, getParallelsForGroupNutrition } from '../../actions/directoryCanteen'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'


export default function GroupNutrition() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { groupNutritionList, group } = useSelector(state => state.directoryCanteen)

  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getGroupNutritionList())
    dispatch(getParallelsForGroupNutrition())
  }, [])

  useEffect(() => {
    if (selectedItem && selectedItem !== 'add') { dispatch(getNutritionGroup(selectedItem?.xp_key)) }
    if (selectedItem && selectedItem === 'add') {
      setSelectedGroup({
        name: "",
        parallels: [],
        sort: '',
        xp_key: ''
      })
    }
  }, [selectedItem])

  useEffect(() => {
    group && setSelectedGroup(group)
  }, [group])

  const openModal = (data) => () => {
    setSelectedItem(data)
  }

  const closeModal = () => {

    setSelectedGroup(null)
    setSelectedItem(null)
  }

  const goHome = () => navigate('/')

  // const thrashClickHandler = (valid) => (e) => {
  //   e.stopPropagation()
  //   dispatch(deleteFormOfEducation(valid))
  // }

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteGroupNutrition(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { name, xp_key, sort } = data
    return (
      <tr >
        <td onClick={openModal(data)}>{name}</td>
        <td onClick={openModal(data)}>{sort}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: xp_key, name })}><BiTrash /></i></td>

      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          // confirmation={confirmation?.name} 
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <Modal
        closeModal={closeModal}
        data={selectedGroup}
        height={'430px'}
      />
      <h1>Группы питания</h1>

      <Table style={{ marginTop: '2rem', backgroundColor: 'white' }}>
        <thead>
          <tr>
            <th>Название</th>
            <th>Сортировка</th>
          </tr>
        </thead>
        <tbody>

          {groupNutritionList?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal('add')}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
      <footer className={styles.footer}>
        <Button mode='white' onClick={goHome}>Назад</Button>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
      </footer>
    </div>
  )
}

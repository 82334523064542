import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Title from '../../components/Title'
import Table from '../../components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { deleteStudyPlans, getStudyPlans } from '../../actions/studyPlans'
import { BiTrash } from 'react-icons/bi'
import moment from 'moment'
import Confirmation from '../../components/Confirmation/Confirmation'
import Modal from './Modal'
import Button from '../../components/Button'
const Index = () => {

    const dispatch = useDispatch()
    const {list} = useSelector(state => state.studyPlans)

    const [confirmation, setConfirmation] = useState(false);
    const [modalOpen, setModalOpen] = useState(null);

    const acceptConfirmation = () => { // Подтвердить удаление
        dispatch(deleteStudyPlans(confirmation?.trid));
        closeModal();
        setConfirmation(false);
      };
      const rejectConfirmation = () => { // Отменить подтверждение
        setConfirmation(false);
      };

    useEffect(() => {
      dispatch(getStudyPlans())
    }, [])

    const openModal = (data) => () => { // Открыть модалку
        setModalOpen(data)
    }

    const closeModal = () => { // Закрыть модалку
        setModalOpen(null)
      }

    const handleConfirmation = (e, data) => {
        e.stopPropagation() // Отмена действия по умолчанию (открытие модалки)
        setConfirmation(data)
    }

  return (
    <div className={styles.wrapper}>
        <Title title="Учебные планы"/>

        <div className={styles.buttons}>
          <Button 
            style={{backgroundColor: "#3689FF", fontSize : "16px"}} 
            onClick={() => setModalOpen({
              name: '',
              edupart: '',
              periodfrom: '',
              periodtill: '',
              periodkind: '',
              trid: 'new',
              isbasic: false,
              category: '',
              
          })}>Добавить</Button>
        </div>

        <Table className={styles.table}>
        <thead>
          <tr>
              <th>№ п/п</th>           
              <th>Название</th>           
              <th>Ступень</th>           
              <th>Период</th>           
              <th>С</th>           
              <th>По</th>           
              <th>Действует</th>           
              <th>Удалить</th>           
          </tr>
          </thead>
         <tbody>

        {
            list.map((data, index) => (
                <tr key={index} onClick={openModal(data)}>
                    <td>{index+1}</td>
                    <td>{data.name}</td>
                    <td>{data.edupart}</td>
                    <td>{data.periodkind}</td>
                    <td>{moment(data?.periodfrom).format('DD.MM.YYYY')}</td>
                    <td>{moment(data?.periodtill).format('DD.MM.YYYY')}</td>
                    <td>?</td>

                    <td onClick={(e) => handleConfirmation(e, data)}>
                        <i className={styles.trash}><BiTrash/></i>
                    </td>
                </tr>
            ))
        }

          </tbody>
        </Table>

        {modalOpen?.trid && (
            <div className={styles.modal}><Modal modalOpen={modalOpen} closeModal={closeModal}/></div>
        )}

        {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title="Удалить данную запись?"
          confirmation={confirmation?.name}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

    </div>
  )
}

export default Index
import * as types from "../actions/types";

const initialState = {
  staff: null,
};

export default function groups (state = initialState, action) {
  switch (action.type) {
    case types.GET_EDUCATIONAL_STRUCTURE:
      return {
        ...state,
        staff: action.payload,
      };
   
    case types.GET_GRADE_OPTIONS:
      return {
        ...state,
        gradesOptions: action.payload,
      };
    case types.GET_TYPES_STUDY_SESSIONS:
      return {
        ...state,
        typesStudySessions: action.payload,
      };
    case types.GET_EDUCATIONAL_STATUSES:
      return {
        ...state,
        typesStudySessions: action.payload,
      };


    case types.SET_GROUPS_MODAL_LOADING:
      return {
        ...state,
        modalLoading: action.payload,
      };
  
    default:
      return state;
  }
}
import { api } from "../App";
import { SET_EDU_DIRECTIONS_LIST, SET_EDU_DIRECTIONS_LOADING } from "./types";




export const getEduDirections = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDU_DIRECTIONS_LOADING, payload: true });
    const { data } = await api.get('/edu_direction');
    dispatch({ type: SET_EDU_DIRECTIONS_LIST, payload: data.edu_direction });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EDU_DIRECTIONS_LOADING, payload: false });
  }
}

export const createEduDirection = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EDU_DIRECTIONS_LOADING, payload: true });
    await api.post('/edu_direction', data);
    await dispatch(getEduDirections())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EDU_DIRECTIONS_LOADING, payload: false });
    return success
  }
}

export const updateEduDirection = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EDU_DIRECTIONS_LOADING, payload: true });
    await api.put(`/edu_direction/${id}`, data);
    await dispatch(getEduDirections())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EDU_DIRECTIONS_LOADING, payload: false });
    return success
  }
}

export const deleteEduDirection = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDU_DIRECTIONS_LOADING, payload: true });
    await api.delete(`/edu_direction/${id}`);
    await dispatch(getEduDirections())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EDU_DIRECTIONS_LOADING, payload: false });
  }
}
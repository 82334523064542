import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { SET_CANDIDATES_ADDITIONAL_EDUCATION, SET_CANDIDATES_ADDRESS, SET_CANDIDATES_BENEFITS, SET_CANDIDATES_DATA, SET_CANDIDATES_DOCUMENTS, SET_CANDIDATES_ENTRANCE, SET_CANDIDATES_EXAM_CITY, SET_CANDIDATES_FORM, SET_CANDIDATES_INFO, SET_CANDIDATES_LANGUAGES, SET_CANDIDATES_LIST, SET_CANDIDATES_LOADING, SET_CANDIDATES_MEDICINE_INFO, SET_CANDIDATES_PARENTS } from "./types";


export const setPage = (pageNumber) => ({type: SET_CANDIDATES_FORM, payload: {pageNumber}})

export const getCandidatesList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const { pageNumber } = getState().candidates.form
    const limit = itemsOnPage
    const offset = itemsOnPage * pageNumber
    const urlParams = new URLSearchParams({limit, offset}).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {

      const {calc_cnt: count, calc_data} = await response.json();
      const pageCount = Math.ceil(count / itemsOnPage)

      dispatch({ type: SET_CANDIDATES_LIST, payload: calc_data });
      dispatch({ type: SET_CANDIDATES_FORM, payload: {pageCount, count} });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateData = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/main`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_DATA, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateAddresses = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/address`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_ADDRESS, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateBenefits = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/benefits`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_BENEFITS, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateEntrance = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/entrance`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_ENTRANCE, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateMedicineInfo = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/medicine_info`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_MEDICINE_INFO, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateAdditionalEducation = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/additional_education`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_ADDITIONAL_EDUCATION, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateDocuments = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/documents`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_DOCUMENTS, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateInfo = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/info`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_INFO, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateParents = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANDIDATES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/${id}/parents`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_PARENTS, payload });
    }
  }
  dispatch({ type: SET_CANDIDATES_LOADING, payload: false });
};

export const getCandidateExamCity = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/main/exam_city`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CANDIDATES_EXAM_CITY, payload });
    }
  }
};

export const getCandidateLanguages = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant/main/second_languages`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.courses
      dispatch({ type: SET_CANDIDATES_LANGUAGES, payload });
    }
  }
};

export const sendNotice = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/applicant_letters`, {
      method: "POST",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data)
    });
    if (response.ok) {
    }
  }
}
import React from 'react'
import styles from './styles.module.css'
import Input from '../../../components/Input'
import Button from '../../../components/Button';
import { useDispatch } from 'react-redux';
import { getAds } from '../../../actions/ads';
import moment from 'moment';

const Index = ({ date, setDate, dateFormat, setType}) => {

    const dispatch = useDispatch();
    
    const handleGetData = () => { // Запрос на получение данных
        dispatch(getAds(date.from, date.to));
    }

    const dateChangeHandler = (name, value) => { // Обработчик изменения дат
        setDate({ ...date, [name]: moment(value).format(dateFormat)});
    }

    const handleAdd = () => {
        setType('ADD');
    }

  return (
    <div className={styles.container}>
        
        <Button onClick={() => handleAdd()}>Добавить</Button>
        <div className={styles.inputsRow}>
            <span className={styles.label}>Период:</span>
            <Input type='date' title='От' value={date.from} onChange={(e) => dateChangeHandler('from', e.target.value)}/>
            <Input type='date' title='До' value={date.to} onChange={(e) => dateChangeHandler('to', e.target.value)}/>
            <Button onClick={() => handleGetData()}>Обновить</Button>
        </div>


    </div>
  )
}

export default Index
import * as types from "../../actions/types";



const initialState = null


export default function results (state = initialState, action) {
  switch (action.type) {
      
    case types.SET_SETTINGS_RESULTS_DATA:
      return action.payload

    default:
      return state;
  }
}
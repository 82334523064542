import * as types from "../actions/types";


const initialState = {
  schedule: [],
  weeks: [],
  classSchedule: [],
  loading: false,
  scheduleNew: [],
  loadingNew: false,
};


export default function schedule (state = initialState, action) {
  switch (action.type) {

    case types.GET_SCHEDULE_CURRENT:
      return {
        ...state,
        schedule: action.payload
      };
    case types.GET_SCHEDULE_CURRENT_NEW:
      return {
        ...state,
        scheduleNew: action.payload
      };
    case types.SET_SCHEDULE_CURRENT_NEW_LOADING:
      return {
        ...state,
        loadingNew: action.payload
      };
 
    case types.GET_SCHEDULE_WEEKS_LIST:
      return {
        ...state,
        weeks: action.payload
      };
    case types.GET_SCHEDULE_CLASS_LIST:
      return {
        ...state,
        classSchedule: action.payload
      };

      case types.SET_SCHEDULE_CURRENT_LOADING:
        return {
          ...state,
          loading: action.payload
        };
    default:
      return state;
  }
}
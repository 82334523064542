import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCandidatesExamTickets } from '../../../../actions/settings/candidates'
import styles from './styles.module.scss'
import { Checkbox } from '../../../../components/ModalInputs'
import { SettingsContext } from '../../../../utils/context'

const INDENT_TYPES = [
  {name: 'Шапка', value: 'marginbottomhead'},
  {name: 'Номер билета', value: 'fontsizehead'},
  {name: 'Между номером билета и названием дисциплины', value: 'marginbottomticketnumber'},
  {name: 'Перед блоком с заданием', value: 'paddingtopqblock'},
  {name: 'После блока с заданием', value: 'paddingbottomqblock'}
]

const ExamTickets = () => {
      
  const dispatch = useDispatch()
  const { examTicketsForm } = useContext(SettingsContext)

  const { values, handleChange, setFieldValue } = examTicketsForm

  useEffect(() => {
    dispatch(getCandidatesExamTickets())
  }, [])
  

  const renderRow = ({name, value}) => (
    <tr>
      <td>{name}</td>
      <td>
        <input
          name={value}
          type='range'
          min={0}
          max={25}
          step={1}
          value={values[value]}
          onChange={handleChange}
        />
      </td>
    </tr>
  )

  const setCheckboxValue = (e) => {
    const { name } = e.target
    const newValue = values[name] ? 0 : 1
    setFieldValue(name, newValue)
  } 

  return (
    <div>
      <div className={styles.paramsChildBLock}>
        
        <p>Настройка внешнего вида формирования билета</p>
        <div className={styles.paramsChildBLock}>
          <p>Отступы</p>

          <table className={styles.indentTable}>
            {INDENT_TYPES.map(renderRow)}
          </table>

        </div>

        <div className={styles.checkboxes}>
          
          <Checkbox
            label='Не отображать название задания, если оно пустое'
            name='skipquesttitle'
            selected={!!values.skipquesttitle}
            onChange={setCheckboxValue}
          />
          
          <Checkbox
            label='Каждая дисциплина начинается с нового листа'
            name='dispagebreakafter'
            selected={!!values.dispagebreakafter}
            onChange={setCheckboxValue}
          />
          
        </div>

      </div>
    </div>
  )
}

export default ExamTickets
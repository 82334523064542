import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MultiSelect } from '../../../components/ModalInputs'
import { BACKEND_URL_PREFIX } from '../../../assets/const'
import { objFilter } from '../../../utils/smallHelpers'



export default function MultipleSelect({data, formik}) {
  
  const { token } = useSelector(state => state.auth)

  const [options, setOptions] = useState([])
  const [deps, setDeps] = useState(undefined)
  const [value, setValue] = useState([])

  useEffect(() => {
    if (!formik.values || !data.arguments) return;
      const newDeps = data.arguments.reduce((acc, dep) => ({...acc, [dep]: formik.values[dep]}), {})
      if (!deps) return setDeps(newDeps);
      const needRerender = data.arguments.some(dep => deps[dep] !== newDeps[dep])
      if (!needRerender) return;
      setDeps(newDeps)
  }, [formik.values])
  
  useEffect(() => {
    const {api_route} = data
    api_route && getOptions(api_route) 
  }, [deps])

  useEffect(() => {
    const newValue = value.map(({value}) => value).join(',') || null
    formik?.setFieldValue(data.name, newValue)
  }, [value])
  

  const getOptions = async (entity) => {
    const headers = { Authorization: `Basic ${btoa(`nnz:${token}`)}` }
    const filteredPArams = objFilter(deps)
    const urlParams = new URLSearchParams(filteredPArams).toString();
    const respose = await fetch(`${BACKEND_URL_PREFIX}/${entity}?${urlParams}`, { headers })
    const data = await respose.json()
    setOptions(data[entity])
  }
  
  const getMultiOptions = ({name, gid}) => ({label: name, value: gid})

  return (
    <MultiSelect
      label={data.description}
      name={data.name}
      value={value}
      options={options.map(getMultiOptions)}
      onChange={setValue}
    />
  )
}

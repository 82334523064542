import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getVaccinations, getTypesOfVaccinations, getVaccinationsCurr, deleteTypesOfVaccinations, deleteVaccinations } from '../../actions/directoryMedCenter'
import Loader from '../../components/Loader'


export default function TypesOfVaccinations() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { vaccinations, loading, vaccinationsCurr } = useSelector(state => state.directoryMedCenter)


  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedVaccine, setSelectedVaccine] = useState(vaccinationsCurr)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getVaccinations())
    dispatch(getTypesOfVaccinations())
  }, [])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
    // data && data.c_vac_type && dispatch(getVaccinationsCurr(data.c_vac_type))
  }

  const closeModal = () => {
    setSelectedItem(null)
    setSelectedVaccine([])
  }


  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteVaccinations(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { phase, vac_type_name, name, c_vaccine } = data
    return (
      <tr >
        <td onClick={openModal(data)}>{vac_type_name}</td>
        <td onClick={openModal(data)}>{name}</td>
        <td onClick={openModal(data)}>{phase}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: c_vaccine, name: vac_type_name })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      {loading ? <Loader /> :

        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'630px'}
          selectedVaccine={selectedVaccine} // для сохр-я ТИпа вакцины
        />}

      <h1>Виды прививок</h1>

      <Table style={{ marginTop: '2rem', backgroundColor: 'white' }}>
        <thead>
          <tr>
            <th>Тип вакцины</th>
            <th>Наименование</th>
            <th>Компонент</th>
          </tr>
        </thead>
        <tbody>

          {vaccinations?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
      <footer className={styles.footer}>
        <Button mode='white' onClick={goHome}>Назад</Button>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
      </footer>
    </div>
  )
}

import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {

  GET_GROUP_NUTRITION_PARALLELS,
  GET_NUTRITION_GROUP, 
  GET_PERIOD_NUTRITION_LIST,
  GET_NUTRITION_PERIOD,
  GET_NUTRITION_PERIOD_FILES,
  GET_NUTRITION_PERIOD_FILE,
  GET_TRACK_PROFILE_LIST,
  GET_TRACK_PROFILE_CURR,
  GET_STUDY_SHIFTS_LIST
} from "./types";





// <----  ПРОФИЛИ ОБУЧЕНИЯ ---->

export const getTrackProfileList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_TRACK_PROFILE_LIST, payload: result });
    }
  }
};

export const getTrackProfileCurr = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      
      dispatch({ type: GET_TRACK_PROFILE_CURR, payload: result });
    }
  }

};

export const updateTrackProfile = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile/${params.trp_id}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getTrackProfileList());
    }
  };
}

export const createTrackProfile = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getTrackProfileList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteTrackProfile = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getTrackProfileList());
    } else createNotification('error', result.error);
  }
};

// <----  УЧЕБНЫЕ СМЕНЫ ---->

export const getStudyShiftsList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_STUDY_SHIFTS_LIST, payload: result });
    }
  }

};


export const updateStudyShift = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts/${params.sid}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getStudyShiftsList());
    }
  };
}

export const createStudyShift = (data) => async (dispatch, getState) => {
  console.log('data::: ', data);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getStudyShiftsList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteStudyShift = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getStudyShiftsList());
    } else createNotification('error', result.error);
  }
};

import React, { useState } from 'react'
import {Main} from './ModalTabs'
import { ClassingContext } from '../../utils/context'

import Modal from '../../components/Modal'

export default function ModalComponent ({classId, closeModal}) {

  const [mode, setMode] = useState('main')

  const renderTab = () => {
      switch (mode) {
          case 'main': return <Main/>
        
          default: return
      }
  }
  

  return (
    <ClassingContext.Provider value={{classId, closeModal}}>
      <Modal
        open={!!classId}
        menuItem={mode}
        setMenuItem={setMode}
      >
        {renderTab()}
      </Modal>
    </ClassingContext.Provider>
  )
}

import * as types from "../actions/types";



const initialState = {
  pageNumber: 0,
  pageCount: 0,
  count: 0,
  list: null,
  currentCourse: {},
  loading: false,
  coursesAliasList: null,
  coursesAliasList_loading: false,
  eduDirList: [],
  eduDirList_loading: false,
  cultureListList: [],
  cultureListList_loading: false,
  competenceList: [],
  competenceList_loading: false
};


export default function courses (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_COURSES_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_COURSES_PAGE:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case types.SET_COURSES_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case types.SET_CURRENT_COURSE:
      return {
        ...state,
        currentCourse: action.payload,
      };
    case types.SET_COURSES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_COURSES_ALIAS_LIST:
      return {
        ...state,
        coursesAliasList: action.payload,
      };
    case types.SET_COURSES_ALIAS_LIST_LOADING:
      return {
        ...state,
        coursesAliasList_loading: action.payload,
      };
    case types.SET_EDU_DIR_LIST:
      return {
        ...state,
        eduDirList: action.payload,
      };
    case types.SET_EDU_DIR_LIST_LOADING:
      return {
        ...state,
        eduDirList_loading: action.payload,
      };
    case types.SET_CULTURE_LIST:
      return {
        ...state,
        cultureListList: action.payload,
      };
    case types.SET_CULTURE_LIST_LOADING:
      return {
        ...state,
        cultureListList_loading: action.payload,
      };
    case types.SET_COMPETENCE_LIST:
      return {
        ...state,
        competenceList: action.payload,
      };
    case types.SET_COMPETENCE_LIST_LOADING:
      return {
        ...state,
        competenceList_loading: action.payload,
      };
    default:
      return state;
  }
}
import * as types from "../actions/types";


const initialState = {
  data: null,
  loading: false
};


export default function scheduling (state = initialState, action) {
  switch (action.type) {

    case types.SET_SCHEDULING_DATA:
      return {
        ...state,
        data: action.payload
      };

    case types.SET_SCHEDULING_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
import React, { useContext } from 'react'
import styles from './styles.module.css'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import { sex, statuses } from '../../../assets/const'
import { StaffRosterContext } from '../../../utils/context'

const obr = [
  { name: 'высшее' },
  { name: 'высшее образование' }]
const vus = [
  { name: 'МГИМО' },
  { name: 'ИТМО' }]
const spec = [
  { name: 'Биология' },
  { name: 'Химия' }]
const edStep = [
  { name: 'Доктор наук' },
  { name: 'Кандидат наук' }]
const edZvan = [
  { name: 'Доцент' },
  { name: 'Профессор' }]

const raz = [
  { name: '10' },
  { name: '1' }]
const katos = [
  { name: 'высшая категория' },
  { name: 'первая категория' }]
const katsov = [
  { name: 'высшая категория' },
  { name: 'первая категория' }]
const pochet = [
  { name: 'Народный учитель' },
  { name: 'Заслуженный учитель' }]


export default function More() {

  const { allForms, addMode, } = useContext(StaffRosterContext)

  const { values, handleChange, } = allForms

  const renderOptions = ({ name, value }) => <option value={value}>{name}</option>

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.inputsRow}>
        {/* TODO */}
        {/* <Input label='Телефон мобильный' name='cellularnumber' value={111} onChange={{}}/>  */}
        <Input label='Телефон мобильный' name='mobile_phone' value={values.mobile_phone} onChange={handleChange} />
        <Input label='Skype' name='skype' value={values.skype} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Телефон рабочий' name='phone_work' value={values.phone_work} onChange={handleChange} />
        <Input label='' />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Телефон домашний' name='phone_home' value={values.phone_home} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='E-mail' name='email' value={values.email} onChange={handleChange} />
      </div>

      <h3>Образование</h3>
      <div className={styles.inputsRow}>
        <Select label='Образование' name='education' value={values.education} onChange={handleChange}>{obr.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Select label='ВУЗ' name='vuz' value={values.vuz} onChange={handleChange}>{vus.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Select label='Специальность' name='specialisation' value={values.specialisation} onChange={handleChange}>{spec.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Select label='Ученая степень' name='scn_degree' value={values.scn_degree} onChange={handleChange}>{edStep.map(renderOptions)}</Select>
        {/* TODO */}
        <Select label='Ученое звание' name='scnrank' value={values.scnrank} onChange={handleChange}>{edZvan.map(renderOptions)}</Select>
      </div>

      <h3>Профессия</h3>
      <div className={styles.inputsRow}>
        <Input label='Основная' name='mainprofession' value={values.mainprofession} onChange={handleChange} />
        <Input label='Код по ОКПДТР' name='mainprofcode' value={values.mainprofcode} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Другая' name='otherprofession' value={values.otherprofession} onChange={handleChange} />
        <Input label='Код по ОКПДТР' name='otherprofcode' value={values.otherprofcode

        } onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Общий стаж на момент поступления' name='all_exp' value={values.all_exp} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Педагогический стаж на момент поступления' name='ped_exp' value={values.ped_exp} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Select label='Разряд' name='razryad' value={values.razryad} onChange={handleChange}>{raz.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Select label='Категория (осн)' name='phone_work' value={values.phone_work} onChange={handleChange}>{katos.map(renderOptions)}</Select>
        <Select label='Почетное звание' name='phone_work' value={values.phone_work} onChange={handleChange}>{pochet.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Select label='Категория (сов)' name='phone_work' value={values.phone_work} onChange={handleChange}>{katsov.map(renderOptions)}</Select>
      </div>
      <div className={styles.inputsRow}>
        <Input label='Нагрузка' name='nagruzka' value={values.nagruzka} onChange={handleChange} />
      </div>
      <div className={styles.inputsRow}>
        <Input
          label='Дата решения аттестационной комиссии (осн)'
          name='registrationdate'
          value={values.registrationdate} // TODO
          onChange={handleChange}
          type='date'
        />
      </div>
      <div className={styles.inputsRow}>
        <Checkbox label='Соответствие занимаемой должности' />
        <Checkbox label='Не аттестовывается' />
      </div>
      <div className={styles.inputsRow}>
        <Input
          label='Дата решения аттестационной комиссии (совм)'
          name='registrationdate'
          value={values.registrationdate} // TODO
          onChange={handleChange}
          type='date'
        />
      </div>
      <div className={styles.inputsRow}>
        <Checkbox label='Соответствие занимаемой должности' />
        <Checkbox label='Не аттестовывается' />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Надбавки' />
      </div>

      <h3>Прочее</h3>
      <div className={styles.inputsRow}>
        <Input label='Надбавки' />
      </div>
      <div className={styles.inputsRow}>
        <Input label='S/N ноутбука' />
      </div>
      <div className={styles.inputsRow}>
        <Input label='Сведения о научно-методич. деятельность' />
      </div>
      <div className={styles.inputsRow}>
        <Input label='сведения о внеурочной деятельности' />
      </div>

    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { updateSubjectsExtraEdu } from '../../../actions/directoryExtraEducation'
import moment from 'moment'
import { createTypesOfVaccinations, getTypesOfVaccinations, updateTypesOfVaccinations } from '../../../actions/directoryMedCenter'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})
const statusList = [
  { status: "Идет" },
  { status: "Закрыт" }
]
const type = [
  { type: 'другое', typeId: 1 },
  { type: 'спортивный', typeId: 2 },
  { type: 'военный', typeId: 3 },
]

export default function ModalComponent({ data, closeModal, height, addMode }) {

  const dispatch = useDispatch()

  // const [reload, setReload] = useState(false)

  const handleCreate = (values) => {
    dispatch(createTypesOfVaccinations(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    console.log('handleUpdate::: ', values);
    dispatch(updateTypesOfVaccinations(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: addMode ? {
      name: '',
    } : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsColumn}>
              <Input
                label='Название'
                name='name'
                value={values?.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />
            </div>
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>

  )
}

import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import { setPage, getSchoolYearsList } from '../../actions/schoolYears'
import Table from '../../components/Table'
import { deleteGradeOption, getGradeWeight  } from '../../actions/directory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { deleteCompetence, getCompetence } from '../../actions/competence'


export default function Competence () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {competenceList} = useSelector(state => state.competence)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getCompetence())
  }, [])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => {
    setSelectedItem(null)
  }
  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteCompetence(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };
  const renderLi = (data) => {
    const {name, id} = data
    return (
      <tr  >
        <td name= 'name' onClick={openModal(data)}>{name}</td>
       
        <td><i className={styles.trash} onClick={() => setConfirmation(data)}><BiTrash/></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{width: '700px',  margin: '0 auto'}}>
      {confirmation && (
                <Confirmation
                    top="30px"
                    left="40px"
                    title={`Удалить компетентность ${confirmation.name}?`}
                    acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
                    rejectConfirmation={rejectConfirmation}
                />
      )}
        <Modal
          closeModal={closeModal}
          selectedItem={selectedItem}
          height={'500px'}
        />
        <h1>Компетентность</h1>
        <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
         <thead>
          <tr>
              <th>Название</th>           
          </tr>
          </thead>
         <tbody>

          {competenceList?.map(renderLi)}

          </tbody>
        </Table>

        <div style={{display:'flex', flexDirection: "row"}}>
          <Button
              style={{padding: '.5rem 1.5rem', margin: '12px'}}
              onClick={openModal()}
            >
              <IoIosAdd size={30}/>
              Добавить 
            </Button>
        
        </div>
        <footer className={styles.footer}>
            <Button mode='white' onClick={goHome}>Назад</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, CreatableSelect, Input, Select, Textarea } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createTypesOfVaccinations, createVaccine, getTypesOfVaccinations, updateTypesOfVaccinations, updateVaccine, } from '../../../actions/directoryMedCenter'
import ModalVacTop from '../TypeVaccine/ModalVacTop'

const validationSchema = Yup.object({
  title: Yup.string().required('обязательное поле').max(50, 'Должно быть не более 50 символов'),

})


const expire_unitList = [
  { expire_unit: 'месяц', typeId: 1 },
  { expire_unit: 'год', typeId: 2 },
  { expire_unit: 'тысячилетие', typeId: 3 },
]

export default function Main({ closeModal, data, addMode, selectedVaccine }) {


  const dispatch = useDispatch()
  const { vaccinations, typesVaccinations } = useSelector(state => state.directoryMedCenter)




  const [vac, setVac] = useState(false)
  let ref = useRef(false);
  const [mo, setMo] = useState(false)
  const [open, setOpen] = useState(false)


  const handleCreate = (values) => {
    dispatch(createVaccine(values))
    closeModal()
    setMo(false)
  }

  const handleUpdate = (values) => {
    dispatch(updateVaccine(values))
    closeModal()
    setMo(false)
  }

  const closeModalTop = () => {
    setMo(false)
    setOpen(null)
    // setSelectedVaccine([])
  }

  const openTop = () => {
    setMo(true)
    setOpen(true)
  }


  useEffect(() => {
    vac && setFieldValue('c_vac_type', vac.c_vac_type)
  }, [vac])

  // useEffect(() => {
  //    if (length !== open?.length)  
  //     {
  //       setMo(true)
  //       setOpen(typesVaccinations)
  //     }
  // }, [typesVaccinations])



  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {
      "c_vaccine": 1,
      "name": "",
      "used": null,
      "c_vac_type": 1,
      "phase": 1,
      "expired": 2,
      "pause": 180,
      "disabled": 1,
      "notes": "",
      "expire_unit": 1
    } : data,
    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })



  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  console.log('open::: ', open);
  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} >
          {mo && 
            <ModalVacTop
              closeModal={closeModalTop}
              data={open}
              // setLength={setLength}
              setVac={setVac}


            />
          }
          <div className={styles.inputsRow}>
            <Input
              label='Тип вакцины'
              name='vac_type_name'
              value={vac.name || values.vac_type_name}
              error={error('vac_type_name')}
              onChange={handleChange}
              onClick={openTop}
              style={{ width: '620px' }}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Наименование'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{ width: '620px' }}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              name='phase'
              type='number'
              label='Компонент'
              value={values.phase}
              onChange={handleChange}
            />
            <Input
              name='pause'
              type='number'
              label='Срок действия'
              value={values.pause}
              onChange={handleChange}
            />
             <Select
                label='исчисление'
                value={values.expire_unit}
                onChange={handleChange}
                name='expire_unit'
                error={error('expire_unit')}
              >
                {expire_unitList?.map(({ expire_unit, typeId }) => <option value={typeId}>{expire_unit}</option>)}
              </Select>
            <Input
              name='expired'
              type='number'
              label='Интервал'
              value={values.expired}
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Checkbox label='Обучающийся' сhecked={!!values.disabled} value={!!values.disabled} onChange={handleChange} name='disabled' />
            <Checkbox label='Сотрудник' сhecked={!!values.expire_unit} value={!!values.expire_unit} onChange={handleChange} name='expire_unit' />
          </div>
          <br />
          <Textarea
            label='Описание'
            className={styles.noticeTextarea}
            style={{ width: '400px', height: '200px' }}
            name='notes'
            value={values.notes}
            onChange={handleChange}
          />

        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}


import * as types from "../../actions/types";



const initialState = null


export default function list (state = initialState, action) {
  switch (action.type) {
      
    case types.SET_STUDENTS_LIST:
      return action.payload

    default:
      return state;
  }
}
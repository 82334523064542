import * as types from "../actions/types";



const initialState = {
  list: null,
  params: null,
  loading: false
};


export default function planning (state = initialState, action) {
  switch (action.type) {
    case types.GET_THEME_PLANNING:
      return {
        ...state,
        list: action.payload
      };
    // case types.SET_REPORTS_PARAMS:
    //   return {
    //     ...state,
    //     params: action.payload
    //   };
    case types.SET_THEME_PLANNING_LOAD:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
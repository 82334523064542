import React from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'


export default function Files ({closeModal, infoCreateChange}) {
  return (
    <>
      <div className={styles.modalMain}>
        Файлы
      </div>
      <ModalFooter closeModal={closeModal} handleSubmit={() => {}} infoCreateChange={infoCreateChange}/>
    </>
  )
}

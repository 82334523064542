import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles.module.scss'
import Table from '../../../components/Table'
import { getScheduleSettingsRooms, getComplexityClassesSubjects, getScheduleSettingsGroups } from '../../../actions/scheduleSettings'
import { TableCell } from './tableCell'
import Loader from '../../../components/Loader'


export default function ComplexityClassesSubjects() {

  const dispatch = useDispatch()
  const { scheduleSettingsRooms, scheduleSettingsComplexityClassesSubjectsList, groups,  loading } = useSelector(state => state.schedule_settings)
  const [selected, setSelected] = useState([]);
  const [panelOpen, setPanelOpen] = useState({ gid: null, cid: null });


  useEffect(() => {
    dispatch(getScheduleSettingsRooms())
    dispatch(getComplexityClassesSubjects())
    dispatch(getScheduleSettingsGroups())
  }, [])
  useEffect(() => {
  }, [])

  const selectedCell = (data) => () => {
    const res = { "rid": data[0], "mid": data[1] }
  }
  const renderLi = (data) => {
    if (data) return (
      <tr key={data[0]}>
        <td className={styles.fio}>{data[1]}</td>
        {data[2].map((el, i) =>
          <td
            key={el[0]}
            onClick={selectedCell([scheduleSettingsRooms?.[i].rid, data[0]])}
            // style={{ backgroundColor: el[1] === 1 && '#3689FF' }}

          >
            <TableCell
              data={{gid:el[0], cid:data[0], value: el[1]}}
              selected={selected}
              setSelected={setSelected}
              panelOpen ={panelOpen}
              setPanelOpen={setPanelOpen}
            />
          </td>)}
      </tr >
    )
  }
  
  return (
    loading ? <Loader /> :
    <div className={styles.inputsWrapper}>
   
      <Table style={{ marginTop: '2rem', backgroundColor: 'white', position: 'relative', height: '70vh' }}>

        <thead className={styles.headSchSet}>
          <tr>
            <th className={styles.fio2}></th>
            {groups?.map(el => <th style={{height: '110px'}}>{el.name}</th>)}
          </tr>
        </thead>
        <tbody>

          {scheduleSettingsComplexityClassesSubjectsList?.map(renderLi)}

        </tbody>
      </Table>

    </div>
    
  )
}

import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { updateSubjectsExtraEdu } from '../../../actions/directoryExtraEducation'
import moment from 'moment'
import { BiTrash } from 'react-icons/bi'
import Table from '../../../components/Table'
import ModalVacBot from '../TypeVaccine/ModalVacBot'
import { IoIosAdd } from 'react-icons/io'
import Button from '../../../components/Button'
import { deleteTypesOfVaccinations, getTypesOfVaccinations } from '../../../actions/directoryMedCenter'
import Confirmation from '../../../components/Confirmation/Confirmation'


const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})
const statusList = [
  { status: "Идет" },
  { status: "Закрыт" }
]
const type = [
  { type: 'другое', typeId: 1 },
  { type: 'спортивный', typeId: 2 },
  { type: 'военный', typeId: 3 },
]

export default function ModalComponent({ data, closeModal, height, addMode, setVac,  }) {

  const dispatch = useDispatch()

  const { vaccinations, typesVaccinations } = useSelector(state => state.directoryMedCenter)


  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);


  
  const [value, setValue] =  useState(typesVaccinations);
  const prevValue = useRef('')
  useEffect(() => {
    prevValue.current = value;
  }, [value]);
  console.log('value::: ', value);


  const openModalBot = (data) => () => {
    setSelectedItem(data || {})
  }
  const takeSelectedVac = (data) => () => {
    setVac(data)
  }

  const closeModalBot = () => {
    setSelectedItem(null)
    // setOpen(null)
  }

  const handleCreate = (values) => {

  }

  const handleUpdate = (values) => {

  }


  const acceptConfirmation = () => {
    dispatch(deleteTypesOfVaccinations(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { name, c_vac_type } = data
    return (
      <tr >
        <td
           onDoubleClick={openModalBot(data)}
           onClick={takeSelectedVac(data)} // для main запроса
        >{name}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: c_vac_type, name: name })}><BiTrash /></i></td>
      </tr>
    )
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: addMode ? {
      name: '',
    } : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })


  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        {confirmation && (
          <Confirmation
            top="30px"
            left="40px"
            title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
            acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
            rejectConfirmation={rejectConfirmation}
          />
        )}
       { selectedItem &&
        <ModalVacBot
          closeModal={closeModalBot}
          data={selectedItem}
          addMode={selectedItem === 'add'}
        />}
        <Table style={{ marginTop: '2rem', backgroundColor: 'white' }}>
          <thead>
            <tr>
              <th>Тип вакцины</th>
            </tr>
          </thead>
          <tbody>

            {value?.map(renderLi)}

          </tbody>
        </Table>
        <div style={{ display: 'flex', flexDirection: "row" }}>
          <Button
            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
            onClick={openModalBot('add')}
          >
            <IoIosAdd size={30} />
            Добавить
          </Button>
        </div>

        <ModalFooter handleSubmit={closeModal} closeModal={closeModal} />
      </>
    </Modal>

  )
}

import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsContext } from '../../../utils/context'
import { getStrengthData, getStrengthTableData } from '../../../actions/settings/strength'
import styles from '../styles.module.scss'
import { Input, Select } from '../../../components/ModalInputs'
import Table from '../../../components/Table'



export default function Strength() {
  
  const dispatch = useDispatch()
  const { data, tableData } = useSelector(state => state.settings.strength)
  const { schoolYears } = useSelector(state => state.common)
  const { form } = useContext(SettingsContext)

  const { values, handleChange, handleBlur, setFieldValue } = form
  
  const [year, setYear] = useState(schoolYears?.[0].xp_key || '')

  useEffect(() => {
    !data && dispatch(getStrengthData())
  }, [])

  useEffect(() => {
    year && dispatch(getStrengthTableData(year))
  }, [year])
  
  const renderRow = ({year, staff}) => (
    <tr>
      <td>{year}</td>
      <td>{staff}</td>
    </tr>
  )

  return (
    <div className={styles.resultsWrapper}>
      
      <div>
        <Input
          label='Штатная численность постоянного состава'
          name='statperson'
          value={values.statperson}
          onChange={handleChange}
        />
      </div>

      <div>
        <Input
          label='Штатная численность педагогических работников'
          name='pedperson'
          value={values.pedperson}
          onChange={handleChange}
        />
      </div>

      <div className={styles.strengthTableBlock}>

        <Select
          label='Год'
          value={year}
          onChange={e => setYear(e.target.value)}
        >
          {schoolYears.map(({name, xp_key}) => <option key={xp_key} value={xp_key}>{name}</option>)}
        </Select>

        {
          tableData &&
            <Table>
              <thead>
                <tr>
                  <td>Параллель</td>
                  <td>Штатная численность переменного состава</td>
                </tr>
              </thead>
              <tbody>
                {tableData.map(renderRow)}
              </tbody>
            </Table>
        }

      </div>

    </div>
  )
}

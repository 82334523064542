import React, { useEffect, useState } from 'react'
import { getReport, getReportParams, getReportsList } from '../../actions/reports'
import { useDispatch, useSelector } from 'react-redux'
import Li from '../../components/Li'
import styles from './styles.module.css'
import Loader from '../../components/Loader'
import Modal from 'react-responsive-modal'
import Button from '../../components/Button'
import { Input, Date, DateTime, IntNumber, FloatNumber, Select, MultiSelect, Checkbox } from './Components'
import { useFormik } from 'formik'

export default function Reports () {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.reports)

  const [modalData, setModalData] = useState(null)

  useEffect(() => { dispatch(getReportsList()) }, [])
  
  const openModal = (data) => () => setModalData(data)

  const closeModal = () => setModalData(null)

  const renderLi = (reportData) => <Li onClick={openModal(reportData)}>{reportData.name}</Li>
  
  return (
    <div className={styles.wrapper}>
      <h1>Отчеты</h1>
      {(loading && !modalData)
        ? <Loader/>
        : <ul>{list?.map(renderLi)}</ul>
      }
      <ReportModal
        open={!!modalData}
        closeModal={closeModal}
        data={modalData}
      />
    </div>
  )
}

const  ReportModal = ({open, closeModal, data}) => {
  
  const dispatch = useDispatch()
  const { params, loading } = useSelector(state => state.reports)

  useEffect(() => {
    data && dispatch(getReportParams(data.xp_rpt_id))
  }, [data])
  
  const loadReport = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.download = true
    link.click()
  }

  const onSubmit = async (values) => {
    const name = data.report_query_name
    const payload = {...values, name}
    const link = await dispatch(getReport(payload))
    link && loadReport(link)
    closeModal()
  }

  const formik = useFormik({
    initialValues: params?.reduce((acc, item) => ({...acc, [item.name]: null}), {}) || {},
    enableReinitialize: true,
    onSubmit: onSubmit
  })

  const renderParam = (param) => {
    let Component
    switch (param.type) {
      case 17: { Component = MultiSelect; break; }
      case 5: { Component = DateTime; break; }
      case 4: { Component = Date; break; }
      case 3: { Component = FloatNumber; break; }
      case 2: { Component = IntNumber; break; }
      case 6: { Component = Checkbox; break; }
      case 1: { Component = Select; break; }
      default: { Component = Input; break; }
    }
    return <Component data={param} key={param.id} formik={formik} />
  }
  
  return (
    <Modal
      open={open}
      onClose={closeModal}
      onEscKeyDown={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={1}
      classNames={{modal: styles.reportModal}}
    >
      <h1>{data?.name}</h1>
      <div className={styles.paramsBlock}>
        {loading ? <Loader/> : params?.map(renderParam)}
      </div>
      <div className={styles.buttonsBlock}>
        <Button onClick={formik.handleSubmit}>Печать отчета</Button>
        <Button onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}
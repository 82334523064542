import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateAddresses } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Input, Select } from '../../../components/ModalInputs'
import { useFormik } from 'formik'


export default function Adresses () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { address: data } = useSelector(state => state.candidates.data)

  useEffect(() => {
    dispatch(getCandidateAddresses(candidateId))
  }, [])
  
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data || {},
    enableReinitialize: true,
    onSubmit: console.log
  })

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          
          <>
            <h5>Адрес регистрации</h5>
            <div className={styles.inputsRow}>
              <Input
                label='Индекс'
                name='registrationindex'
                value={values.registrationindex}
                onChange={handleChange}
                />
              <Select
                style={{width: '350px'}}
                label='Субъект РФ'
                name='registrationsubject'
                value={values.registrationsubject}
                onChange={handleChange}
                >
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Input
                style={{width: '550px'}}
                label='Район'
                name='registrationdistrict'
                value={values.registrationdistrict}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                style={{width: '350px'}}
                label='Город / Нас. пункт'
                name='registrationcity'
                value={values.registrationcity}
                onChange={handleChange}
              />
              <Input
                style={{width: '350px'}}
                label='Улица'
                name='registrationstreet'
                value={values.registrationstreet}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Дом'
                name='registrationhome'
                value={values.registrationhome}
                onChange={handleChange}
              />
              <Input
                label='Корпус'
                name='registrationliter'
                value={values.registrationliter}
                onChange={handleChange}
              />
              <Input
                label='Квартира'
                name='registrationflat'
                value={values.registrationflat}
                onChange={handleChange}
              />
            </div>          
          </>
          
          <>
            <h5>Адрес фактического проживания</h5>
            <div className={styles.inputsRow}>
              <Input
                label='Индекс'
                name='registrationindex'
                value={values.registrationindex}
                onChange={handleChange}
                />
              <Select
                style={{width: '350px'}}
                label='Субъект РФ'
                name='registrationsubject'
                value={values.registrationsubject}
                onChange={handleChange}
                >
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Input
                style={{width: '550px'}}
                label='Район'
                name='registrationdistrict'
                value={values.registrationdistrict}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                style={{width: '350px'}}
                label='Город / Нас. пункт'
                name='registrationcity'
                value={values.registrationcity}
                onChange={handleChange}
              />
              <Input
                style={{width: '350px'}}
                label='Улица'
                name='registrationstreet'
                value={values.registrationstreet}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Дом'
                name='registrationhome'
                value={values.registrationhome}
                onChange={handleChange}
              />
              <Input
                label='Корпус'
                name='registrationliter'
                value={values.registrationliter}
                onChange={handleChange}
              />
              <Input
                label='Квартира'
                name='registrationflat'
                value={values.registrationflat}
                onChange={handleChange}
              />
            </div>          
          </>

          <>
            <h5>Адрес для переписки</h5>
            <div className={styles.inputsRow}>
              <Input
                label='Индекс'
                name='registrationindex'
                value={values.registrationindex}
                onChange={handleChange}
                />
              <Select
                style={{width: '350px'}}
                label='Субъект РФ'
                name='registrationsubject'
                value={values.registrationsubject}
                onChange={handleChange}
                >
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Input
                style={{width: '550px'}}
                label='Район'
                name='registrationdistrict'
                value={values.registrationdistrict}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                style={{width: '350px'}}
                label='Город / Нас. пункт'
                name='registrationcity'
                value={values.registrationcity}
                onChange={handleChange}
              />
              <Input
                style={{width: '350px'}}
                label='Улица'
                name='registrationstreet'
                value={values.registrationstreet}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Дом'
                name='registrationhome'
                value={values.registrationhome}
                onChange={handleChange}
              />
              <Input
                label='Корпус'
                name='registrationliter'
                value={values.registrationliter}
                onChange={handleChange}
              />
              <Input
                label='Квартира'
                name='registrationflat'
                value={values.registrationflat}
                onChange={handleChange}
              />
            </div>          
          </>

        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={closeModal}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}

import * as types from "../actions/types";



const initialState = {

};


export default function formsOfEducation (state = initialState, action) {
  switch (action.type) {
    case types.GET_FORMS_OF_EDUCATION:
      return {
        ...state,
        ...action.payload,
      };
  
    default:
      return state;
  }
}
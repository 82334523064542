import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { checked } from '../../assets/svg/other'
import {add, remove, change, print, copy} from '../../assets/svg/footers'
import Select from '../../components/Select'
import { useState } from 'react'
import Modal from './modal'
import { useNavigate, useParams } from 'react-router-dom'
import Table from '../../components/Table'
import { getPersonnelList } from '../../actions/personnel'
import { useDispatch, useSelector } from 'react-redux'
import { statuses } from '../../assets/const'
import { getCandidatesList } from '../../actions/candidates'
import { getStrengthTableData } from '../../actions/settings/strength'
import { getGroups } from '../../actions/groups'
import { getStudents } from '../../actions/students/list'


export default function Students () {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { list } = useSelector(state => state.students)
  const { tableData } = useSelector(state => state.settings.strength)
  const { groups} = useSelector(state => state.groups)
  const { studentId } = useParams()


  const [formData, setFormData] = useState({parallel: "", class: "", status: ''})

  const openModal = (id) => () => navigate(`/students/${id}`)
  const closeModal = () => navigate('/students')
  const goHome = () => navigate('/')

  useEffect(()=> {
    dispatch(getStudents())
    !tableData && dispatch(getStrengthTableData(0))
    dispatch(getGroups())
  }, [])

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
}
  const renderOption = (option, index) => {
    const {  xp_key,  value, trmid, cid, gid, work_status, id, pmid, did ,  name, title, year, access, } = option
    const optionValue =   xp_key || value ||trmid ||cid || gid || work_status || id || pmid || did || year
    const optionTitle =  name || title || value || year || work_status || access
    return <option key={`${optionValue}-${index}`} value={optionValue}>{optionTitle}</option>
  }

  const renderRow = (row) => {
    const {fio, login, sex, age, birthday, email, residential_address, date_of_birth, mid, calc_fio} = row
    
    return (
      <tr onClick={openModal(mid)}>
        <td>{fio || calc_fio}</td>
        <td>{checked()}</td>
        <td>{login}</td>
        <td></td>
        <td>{sex}</td>
        <td>{birthday}</td>
        <td>{age}</td>

        <td>{date_of_birth}</td>
        <td>{email}</td>
        <td>{residential_address}</td>
      </tr>
    )
  }
  
  return (
    <div className={styles.wrapper}>
        <Modal  
          studentId={studentId} 
          isOpen={!!studentId} 
          closeModal={closeModal}
          addMode={!parseInt(studentId)}
        />
        <h1>Личные дела учащихся</h1>
        <div className={styles.filter}>
        <div className={styles.filter1}>
            <Select 
            title='Статус'
            options={statuses} 

            >
              <option value=''>Выбрать</option>
              {statuses?.map(renderOption)}

            </Select>

            <Select 
            title='Параллель'
            options={tableData} 
            >
              <option value=''>Выбрать</option>
              {tableData?.map(renderOption)}
            </Select>
            <Select
              title="Класс"
              name={"class"}
              options={groups && [
              {name: "Выбрать"},
              ...groups?.groups
              ]}
              value={formData
              ?.class}
              onChange={handleInputChange}/>

              </div>
              <div className={styles.filter2}>
                <span className={styles.title}>Воспитатель: Аврамкова Екатерина Валерьевна</span>
              
            <Button>Обновить</Button>
            </div>
            </div>
        <Table style={{marginTop: '2rem'}}>
          <thead>
              <tr>
                  <th>Фамилия Имя Отчество</th>
                  <th>Фото</th>
                  <th>Логин</th>
                  <th>Заблокирован</th>
                  <th>Пол</th>
                  <th>Дата рождения</th>
                  <th>Возраст</th>
                  <th>ДР (Месяц/День)</th>
                  <th>Email</th>
                  <th>Адрес</th>
              </tr>
          </thead>
          <tbody>
              {list?.map(renderRow)}
          </tbody>
        </Table>
        <footer className={styles.footer}>
            <div>
                <Button className={styles.footerButton} onClick={openModal(0)} mode='bordered'>{add()}Добавить</Button>
                {/* <Button className={styles.footerButton} mode='bordered'>{remove()}Удалить</Button> */}
                {/* <Button className={styles.footerButton} onClick={openModal('add')} mode='bordered'>{change()}Изменить</Button> */}
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{print()}Печать экзаменационных билетов</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{print()}Печать Т-2</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{copy()}Пароли / Экспорт в AD</Button>
            </div>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}

import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { SET_ROOMS, SET_ROOMS_LOAD, SET_TFO_LIST, SET_TFO_TYPES_LIST, SET_EQUIPMENTS_LIST, SET_EQUIPMENTS_BY_ROOM_LIST } from "./types";
import { buildDefaultHeaders } from "./helper";
import { createNotification } from "../components/Notifications/Notifications";

export const getRoomsList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ROOMS_LOAD, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/rooms`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_ROOMS, payload: data});
    }
  }
  dispatch({ type: SET_ROOMS_LOAD, payload: false });
};

export const editRooms = (data) => async (dispatch, getState) => {
    try {
      await dispatch(checkAndUpdateToken());
      if (getState().auth.token) {
        dispatch({ type: SET_ROOMS_LOAD, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/rooms/${data.rid}`, {
          method: "PUT",
          ...buildDefaultHeaders(getState),
          body: JSON.stringify(data)
        });
        if (response.ok) {
          createNotification('success', 'Успешно');
          dispatch(getRoomsList())
        }
      }
    } catch (ERR) {
    } finally {
      dispatch({ type: SET_ROOMS_LOAD, payload: false });
    }
  };

  export const addRooms = (data) => async (dispatch, getState) => {
    try {
      await dispatch(checkAndUpdateToken());
      if (getState().auth.token) {
        dispatch({ type: SET_ROOMS_LOAD, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/rooms`, {
          method: "POST",
          ...buildDefaultHeaders(getState),
          body: JSON.stringify(data)
        });
        if (response.ok) {
          createNotification('success', 'Успешно');
          dispatch(getRoomsList());
        }
      }
    } catch (ERR) {
    } finally {
      dispatch({ type: SET_ROOMS_LOAD, payload: false });
    }
  };

  export const deleteRooms = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: SET_ROOMS_LOAD, payload: true });
      const response = await fetch(`${BACKEND_URL_PREFIX}/rooms/${id}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
        dispatch(getRoomsList());
      }
    }
    dispatch({ type: SET_ROOMS_LOAD, payload: false });
  };

  export const getTFOList = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/tfo/object_umb`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_TFO_LIST, payload: data});
      }
    }
  };
  
  export const getTFOTypeList = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/tfo_types`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_TFO_TYPES_LIST, payload: data});
      }
    }
  };

  export const getEquipmentsList = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/equipments/${id}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_EQUIPMENTS_LIST, payload: data});
      }
    }
  };

  export const getEquipmentsByRoom = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/equipments/room/${id}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_EQUIPMENTS_BY_ROOM_LIST, payload: data});
      }
    }
  };

  export const setEquipmentsForRoom = (data = [], id) => async (dispatch, getState) => {
    try {
      await dispatch(checkAndUpdateToken());
      if (getState().auth.token) {
        // dispatch({ type: SET_ROOMS_LOAD, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/rooms_equipments`, {
          method: "POST",
          ...buildDefaultHeaders(getState),
          body: JSON.stringify(data)
        });
        if (response.ok) {
          createNotification('success', 'Успешно');
          dispatch(getEquipmentsByRoom(id));
        } else {
          createNotification('error', 'Ошибка')
        }
      }
    } catch (ERR) {
    } finally {
      // dispatch({ type: SET_ROOMS_LOAD, payload: false });
    }
  };

  export const deleteEquipment = (id, idAquip) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/rooms_equipment/${id}/${idAquip}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
          dispatch(getEquipmentsByRoom(id));
          dispatch(getEquipmentsList(id));
      }
    }
  };
import * as types from "../actions/types";



const initialState = {
  list: null,
  loading: false
};


export default function achievements (state = initialState, action) {
  switch (action.type) {
    case types.SET_ACHIEVEMENTS_LIST:
      return {
        ...state,
        list: action.payload
      };
    case types.SET_ACHIEVEMENTS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { ClassingContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupTeam } from '../../../actions/groups'
import Loader from '../../../components/Loader'
import Table from '../../../components/Table'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'


export default function Team () {
  
  const dispatch = useDispatch()
  const { classId, closeModal, infoCreateChange } = useContext(ClassingContext)
  const { groupTeam, modalLoading: loading } = useSelector(state => state.groups)

  useEffect(() => {
    dispatch(getGroupTeam(classId)) 
  }, [])
  
  const renderRow = ({calc_fio, calc_status, calc_date}) => (
    <tr>
      <td>{calc_fio}</td>
      <td>{calc_status}</td>
      <td>{calc_date}</td>
    </tr>
  )

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          {loading
            ? <Loader/>
            : !!groupTeam?.length &&
                <Table maxHeight='50vh'>
                  <thead>
                    <tr>
                      <th>ФИО</th>
                      <th>Статус</th>
                      <th>Дата</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupTeam.map(renderRow)}
                  </tbody>
                </Table>
          }
        </div>
      </div>
      <ModalFooter closeModal={closeModal} handleSubmit={closeModal} infoCreateChange={infoCreateChange} />
    </>
  )
}

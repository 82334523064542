import { combineReducers } from 'redux'
import loading from './loading'
import data from './data'
import list from './list'


const students = combineReducers({
  list,
  data,
  loading
})

export default students
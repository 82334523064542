import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateEntrance } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import cn from 'classnames'
import Table from '../../../components/Table'


export default function Admission () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { entrance: data } = useSelector(state => state.candidates.data)

  useEffect(() => {
    dispatch(getCandidateEntrance(candidateId))
  }, [])  
  
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div style={{alignItems: 'end'}} className={styles.inputsRow}>
            <h3>ДОПУЩЕН К ВСТУПИТЕЛЬНЫМ ИСПЫТАНИЯМ</h3>
            <Select
              style={{width: '250px'}}
            />
            <Checkbox
              label='Не явился'
            />  
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Причина отказа'
            />
            <Input
              label='Нагрудный номер для ОФП'
            />
          </div>
          <div className={cn(styles.inputsRow, styles.resumeRow)}>
            <Input
              label='Коэффициент'
            />
            <Input
              label='Итоговое заключение'
            />
          </div>
          <h5>Превышающие показатели</h5>
          <div className={cn(styles.inputsRow, styles.flexBasisRow)}>
            <Input
              style={{width: '70px'}}
              label='Средний балл по табелю'
            />
            <Input
              style={{width: '70px'}}
              label='Участие в олимпиадах, конкурсах'
            />
            <Input
              style={{width: '70px'}}
              label='Балл за портфолио'
            />
            <Input
              style={{width: '70px'}}
              label='Техническая и специальная физическая подготовленность'
              />
          </div>
          <h5>Антропометрические данные</h5>
          <Table style={{width: '70%'}}>
            <thead>
              <tr>
                <th>Параметр</th>
                <th>Значение</th>
              </tr>
            </thead>
          </Table>
        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={closeModal}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}

import React, {useState} from 'react'
import styles from './index.module.css'
import {Link} from 'react-router-dom'
import {BsFillLockFill} from 'react-icons/bs'

const Index = ({data, setIsSearch}) => {
    const [state,
        setState] = useState('')

    const handleClick = () => {
        setIsSearch('')
        setState('')
    }

    const normalizeData = (data) => {
        let menu = []
        data
            ?.map(el => {
                if (!el.hasOwnProperty('menu')) {
                    menu.push(el)
                } else {
                    el
                        ?.menu
                            ?.map(_el => {
                                if (!_el.hasOwnProperty('menu')) {
                                    menu.push(_el)
                                } else {
                                    _el
                                        ?.menu
                                            ?.map(__el => {
                                                if (!__el.hasOwnProperty('menu')) {
                                                    menu.push(__el)
                                                }
                                            })
                                }
                            })
                }
                return menu
            })
        return menu
    }

    return (
        <div className={styles.wrapper}>
            <input
                type='text'
                onChange={(e) => setState(e.target.value)}
                value={state}
                className={styles.input}
                placeholder='Поиск'
                autoFocus/>

            <div className={styles.conteiner}>
                <div className={styles.menuList}>
                    {normalizeData(data)
                        ?.map((el, i) => {
                            if (el.label.toLowerCase().includes(state.toLowerCase()) && state.length > -1) {
                                if (el.link === '/') {
                                    return <span key={i} className={styles.link}>{el.label}
                                        <i className={styles.icon}><BsFillLockFill/></i>
                                    </span>
                                } else {
                                    return <Link key={i} to={el.link} onClick={handleClick} className={styles.link}>{el.label}
                                    </Link>
                                }
                            }
                        })}
                </div>
            </div>
        </div>
    )
}

export default Index
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsContext } from '../../../utils/context'
import { getKioskData, getKioskVideoData } from '../../../actions/settings/kiosk'
import { Input, Select } from '../../../components/ModalInputs'
import styles from '../styles.module.scss'
import Table from '../../../components/Table'


const SHEDULE_MODES = ['Классический', 'Вертикальный']

export default function Kiosk() {
  
  const dispatch = useDispatch()
  const { data, video } = useSelector(state => state.settings.kiosk)
  const { form } = useContext(SettingsContext)

  const { values, handleChange, handleBlur, setFieldValue } = form

  useEffect(() => {
    !data && dispatch(getKioskData())
    !video && dispatch(getKioskVideoData())
  }, [])
  
  
  return (
    <div className={styles.resultsWrapper}>

      <div>
        <Select
          label='Режим расписания ДО'
          name='DO_Shedule_Mode'
          value={values.DO_Shedule_Mode}
          onChange={handleChange}
          >
          {SHEDULE_MODES.map(mode => <option key={mode}>{mode}</option>)}
        </Select>
      </div>

      <div>
        <Input
          label='Интервал смены изображений блюд на экране столовой (сек.)'
          name='MenuDelay'
          value={values.MenuDelay}
          onChange={handleChange}
          type='number'
        />
      </div>

      {
        !!video?.length &&
          <div>
            <p>Список видеофайлов: video (avi, mp4, divx, mpg, mpeg, mpv)</p>
            <Table>
              <thead>
                <tr>
                  <th>№ п/п</th>
                  <th>Файл</th>
                  <th>Размер</th>
                </tr>
              </thead>
              <tbody>
                {video.map(video => <tr></tr>)}
              </tbody>
            </Table>
          </div>
      }

    </div>
  )
}

import React from 'react'
import styles from './TeacherInfo.module.css'

const TeacherInfo = ({class_teacher_fio, teacher_fio}) => {
  return (
    <div className={styles.teacherInfo}>
        <div>Классный руководитель: <span>{class_teacher_fio}</span></div>
        <div>Преподаватель: <span>{teacher_fio}</span></div>
  </div>
  )
}

export default TeacherInfo
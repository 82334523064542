import * as types from "../actions/types";



const initialState = {
  gradeWeight: null,
  gradesOptions: null,
  typesStudySessions: null,
  educationalStatus: null,
  countries: null,

};


export default function groups(state = initialState, action) {
  switch (action.type) {
    case types.GET_GRADE_WEIGHT:
      return {
        ...state,
        gradeWeight: action.payload,
      };
    case types.GET_GRADE_OPTIONS:
      return {
        ...state,
        gradesOptions: action.payload,
      };
    case types.GET_TYPES_STUDY_SESSIONS:
      return {
        ...state,
        typesStudySessions: action.payload,
      };
    case types.GET_EDUCATIONAL_STATUSES:
      return {
        ...state,
        educationalStatus: action.payload,
      };
    case types.GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
      case types.GET_FORMS_OF_EDUCATION:
        return {
          ...state,
          formOfEducation: action.payload,
        };

    case types.SET_GROUPS_MODAL_LOADING:
      return {
        ...state,
        modalLoading: action.payload,
      };

    default:
      return state;
  }
}
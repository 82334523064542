import React, { useState } from 'react'
import styles from './styles.module.css'
import Button from '../Button'
import Confirmation from '../Confirmation/Confirmation';
import moment from 'moment';

const ModalFooter = ({handleSubmit , closeModal, data={}, isShowInfo=true, infoCreateChange}) => {

    const [confirmation, setConfirmation] = useState(false);

// приходящие поля в infoCreateChange
    // courses
        // createby
        // createdate
    // classing
        //whochange_fio  whenchange
        //whocreate_fio  whencreate
    // candidates ??? на 26/09 нет еще


    const create = infoCreateChange?.whocreate_fio || infoCreateChange?.createby ? `${infoCreateChange?.whocreate_fio || infoCreateChange?.createby } ${moment(infoCreateChange?.whencreate || infoCreateChange?.createdate).format('YYYY-MM-DD, HH:mm')}` : ''
    const change = infoCreateChange?.whochange_fio ? `${infoCreateChange?.whochange_fio || ''} ${moment(infoCreateChange?.whenchange).format('YYYY-MM-DD, HH:mm') }` : ''

    
    const acceptConfirmation = () => {
      closeModal();
      setConfirmation(false);
    };
    const rejectConfirmation = () => {
      setConfirmation(false);
    };

    return (
        <>
            {confirmation && (
                <Confirmation
                    top="30px"
                    left="40px"
                    title="Закрыть без сохранения?"
                    confirmation={confirmation} 
                    acceptConfirmation={() => acceptConfirmation(confirmation)}
                    rejectConfirmation={rejectConfirmation}
                />
            )}
            <div className={styles.modalFooter}>
                {(isShowInfo && !!create) ? 
                <div>
                    {/* <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
                    <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div> */}
                    <div>Создал: {create}</div>
                   {!!change &&
                    <div>Изменил: {change}</div>
                   }
                </div> : <></>}
                <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
                <Button mode='white' onClick={() => setConfirmation(true)}>Закрыть</Button>
            </div>
        </>
  )
}

export default ModalFooter
import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { createNotification } from "../components/Notifications/Notifications";
import {SET_PERSONNEL_LOADING, GET_PERSONNEL_LIST, GET_PERSON, GET_PERSON_TABLE} from "./types";


export const getPersonnelList = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_PERSONNEL_LOADING, payload: true });
      
        const response = await fetch(`${BACKEND_URL_PREFIX}/staff_roster?work_status=${params}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            // const list =( await response.json()).staff_roster; // БЫЛО ТАК 25-07 ХЗ ШО ПОМЕНЯЛОСЬ
            const list =( await response.json());
            dispatch({ type: GET_PERSONNEL_LIST, payload: list });
          }
    };
        dispatch({ type: SET_PERSONNEL_LOADING, payload: false });
}

export const getPerson = (mid) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_PERSONNEL_LOADING, payload: true });
      
        const response = await fetch(`${BACKEND_URL_PREFIX}/staff_roster/${mid}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            // const list =( await response.json()).staff_roster; // БЫЛО ТАК 25-07 ХЗ ШО ПОМЕНЯЛОСЬ
            const list =( await response.json());
            dispatch({ type: GET_PERSON, payload: list });
          }
    };
        dispatch({ type: SET_PERSONNEL_LOADING, payload: false });
}
export const getPersonTable = (table, mid) => async (dispatch, getState) => {
  // const table ='physical_training'
  // console.log('params::: ', params);
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_PERSONNEL_LOADING, payload: true });
      
        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_personal_file/${table}/${mid}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            // const list =( await response.json()).staff_roster; // БЫЛО ТАК 25-07 ХЗ ШО ПОМЕНЯЛОСЬ
            const list =( await response.json());
            dispatch({ type: GET_PERSON_TABLE, payload: list });
          }
    };
        dispatch({ type: SET_PERSONNEL_LOADING, payload: false });
}
// TODO 

  export const updatePersonnelInfo = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/staff_roster/${params.mid}`, {
          method: "PUT",
                body: JSON.stringify(params),
                ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            await dispatch(getPersonnelList());
          }
    };
  }

  export const createPersonnelInfo = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/staff_roster`, {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
        await dispatch(getPersonnelList());
      } else createNotification('error', "Не удалось создать запись");
    }
  };

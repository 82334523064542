import * as types from "../../actions/types";



const initialState = {
  list: null,
  data: null,
  address: null,
  benefits: null,
  entrance: null,
  medicine_info: null,
  additional_education: null,
  documents: null,
  info: null,
  parents: null,
  exam_city: null,
  loading: false
};


export function data (state = initialState, action) {
  switch (action.type) {
    case types.SET_CANDIDATES_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case types.SET_CANDIDATES_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_CANDIDATES_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case types.SET_CANDIDATES_BENEFITS:
      return {
        ...state,
        benefits: action.payload,
      };
    case types.SET_CANDIDATES_ENTRANCE:
      return {
        ...state,
        entrance: action.payload,
      };
    case types.SET_CANDIDATES_MEDICINE_INFO:
      return {
        ...state,
        medicine_info: action.payload,
      };
    case types.SET_CANDIDATES_ADDITIONAL_EDUCATION:
      return {
        ...state,
        additional_education: action.payload,
      };
    case types.SET_CANDIDATES_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case types.SET_CANDIDATES_INFO:
      return {
        ...state,
        info: action.payload,
      };
    case types.SET_CANDIDATES_PARENTS:
      return {
        ...state,
        parents: action.payload,
      };
    case types.SET_CANDIDATES_EXAM_CITY:
      return {
        ...state,
        exam_city: action.payload,
      };
    case types.SET_CANDIDATES_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case types.SET_CANDIDATES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
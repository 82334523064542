import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { deleteGradeOption, getGradeWeight  } from '../../actions/directory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'


export default function GradeWeight () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {gradeWeight} = useSelector(state => state.directory)

  const [selectedItem, setSelectedItem] = useState(null)
  const [addMode, setAddMode] = useState(false)
  
  const [confirmation, setConfirmation] = useState(false);



  useEffect(() => {
    dispatch(getGradeWeight());
  }, [])

  // useEffect(() => {
  //   selectedItem &&
  //   handleCheck(selectedItem)
  // }, [selectedItem])
  

  const openModal = (data) => () => {
    setAddMode(true)
    setSelectedItem(data || {})
  }

  const closeModal = () => {
    setSelectedItem(null)
    setAddMode(false)
  }
  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteGradeOption(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };
  const renderLi = (data) => {
    const {weight, enabled, color} = data
    return (
      <tr  >
        <td name= 'weight' onClick={openModal(data)}>{weight}</td>
        <td name= 'color' onClick={openModal(data)} style={{backgroundColor: `#${color}` }}></td>
        {/* <td><Checkbox name='enabled' gradeWeight/></td> */}
        <td><input type="checkbox"  name='enabled' onClick={openModal(data)} checked={enabled !== 0 ? true : false }  /></td>
        <td><i className={styles.trash} onClick={() => setConfirmation({id: weight})}><BiTrash/></i></td>
      </tr>
    )
  }
  return (
    <div className={styles.wrapper} style={{width: '700px',  margin: '0 auto'}}>
      {confirmation && (
                <Confirmation
                    top="30px"
                    left="40px"
                    title={`Удалить вес ${confirmation.id}?`}
                    // confirmation={confirmation?.name} 
                    acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
                    rejectConfirmation={rejectConfirmation}
                />
      )}
        <Modal
          closeModal={closeModal}
          selectedItem={selectedItem}
          addMode={addMode}
          height={'500px'}
        />
        <h1>Веса оценок</h1>
        <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
         <thead>
          <tr>
              <th>Вес</th>
              <th>Цвет</th>
              <th style={{width:'70px'}}>Доступен</th>
              <th style={{width:'70px'}}>Удалить</th>
            
          </tr>
          </thead>
         <tbody>

          {gradeWeight?.map(renderLi)}

          </tbody>
        </Table>

        <div style={{display:'flex', flexDirection: "row"}}>
          <Button
              style={{padding: '.5rem 1.5rem', margin: '12px'}}
              onClick={openModal()}
            >
              <IoIosAdd size={30}/>
              Добавить 
            </Button>
        
        </div>
        <footer className={styles.footer}>
             <Button mode='white' onClick={goHome}>Назад</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}


// <div className={styles.secondLiBlock}>
// <div onClick={thrashClickHandler(xp_key)}>{thrash()}</div>
// </div>

// <Pagination
// onPageChange={pageClick}
// pageCount={pageCount}
// pageNumber={pageNumber}
// loading={loading && !selectedItem}
// >
// </Pagination>
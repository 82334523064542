import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getControlData } from '../../../actions/settings/control'
import styles from '../styles.module.scss'
import {Checkbox, Input} from '../../../components/ModalInputs'
import { SettingsContext } from '../../../utils/context'



export default function Control() {
  
  const dispatch = useDispatch()
  const { control } = useSelector(state => state.settings)
  const { form } = useContext(SettingsContext)

  const { values, handleChange, handleBlur, setFieldValue } = form

  useEffect(() => {
    !control && dispatch(getControlData())
  }, [])
  
  const checkboxClick = (e) => {
    const {name} = e.target
    const value = values[name] === '1' ? '0' : '1'
    setFieldValue(name, value)
  }

  return (
    <div className={styles.resultsWrapper}>
      <p>Параметры парольной политики</p>

      <Input
        label='Длина пароля не менее (символов)'
        name='password_length'
        value={values.password_length}
        onChange={handleChange}
        type="number"
        min='0'
        onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
        style={{width: '50px'}}
      />

      <Checkbox
        label='Пароль должен содержать заглавные и строчные буквы'
        name='password_case'
        checked={values.password_case == 1}
        onChange={checkboxClick}
      />

      <p>Категории подбора пароля</p>

      <div>
        <Input
          label='Временной интервал (минут)'
          name='password_min'
          value={values.password_min}
          onChange={handleChange}
          type="number"
          min='0'
          onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
          style={{width: '50px'}}
        />
      </div>

      <div>
        <Input
          label='Количество попыток'
          name='password_count'
          value={values.password_count}
          onChange={handleChange}
          type="number"
          min='0'
          onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
          style={{width: '50px'}}
        />
      </div>

    </div>
  )
}

import React, { useState } from 'react'
import { classingModalModes, defaultModalModes } from '../../assets/const'
import {Curator, History, Main, Team} from './ModalTabs'
import Modal from '../../components/Modal'
import { ClassingContext } from '../../utils/context'

export default function ModalComponent ({classId, closeModal, classing, infoCreateChange}) {
 
  const [mode, setMode] = useState('main')

  const renderTab = () => {
      switch (mode) {
          case 'main': return <Main/>
          case 'curator': return <Curator/>
          case 'history': return <History/>
          case 'team': return <Team/>
          default: return
      }
  }
  
  return (
    <ClassingContext.Provider value={{classId, closeModal, classing, infoCreateChange}}>
          <Modal
            open={!!classId}
            classId={classId}
            menuItem={mode}
            setMenuItem={setMode}
            menuList={classingModalModes}
            // group={group}
            classing
          >
            {renderTab()}
          </Modal>
    </ClassingContext.Provider>
  )
}


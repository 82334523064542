import React from 'react'
import styles from './styles.module.scss'
import { Input, Select } from  '../../../../components/ModalInputs'
import { useSelector } from 'react-redux'

const FORMATS = ['Очно', 'Онлайн']

const Form = ({ form }) => {
  
  const { schoolYears } = useSelector(state => state.common)

  const { values, handleChange } = form
  
  return (
    <form className={styles.inputsWrapper} style={{maxWidth: '580px'}}>
      
      <Select
        label='Учебный год для подачи заявок'
        name='year'
        value={values.year}
        onChange={handleChange}
      >
        {schoolYears.map(({name, xp_key}) => <option key={xp_key} value={xp_key}>{name}</option>)}
      </Select>

      <Input
        label='Подача заявлений (с)'
        name='date_from'
        value={values.date_from}
        onChange={handleChange}
        type='date'
        />

      <Input
        label='Подача заявлений (по)'
        name='date_to'
        value={values.date_to}
        onChange={handleChange}
        type='date'
      />

      <Select
        label='Формат проведения вступительных испытаний'
        name='year'
        value={values.year}
        onChange={handleChange}
      >
        {FORMATS.map(format => <option key={format}>{format}</option>)}
      </Select>

  </form>
  )
}

export default Form
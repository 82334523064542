import * as types from "../actions/types";


const initialState = {
  studyPeriod: [],
  lessons: [],
  scClass: [],
  classbook: [],
  gradeValue: [],
  lesson_chart: null,
  class_topic: null,
  event_tools: null,
  planner_f_list: null,
  home_f_list: null,
  planner_list: null,
  home_list: null,
  class_teacher_fio: '',
  teacher_fio: '',
  loading: false
};


export default function classbook (state = initialState, action) {
  switch (action.type) {

    case types.GET_CLASSBOOK_STUDY_PERIOD:
      return {
        ...state,
        studyPeriod: action.payload
      };
 
    case types.GET_CLASSBOOK_LESSON:
      return {
        ...state,
        lessons: action.payload
      };
    case types.GET_CLASSBOOK_CLASS:
      return {
        ...state,
        scClass: action.payload
      };
    case types.GET_CLASSBOOK_GRADE_VALUE:
      return {
        ...state,
        gradeValue: action.payload
      };
    case types.GET_LESSON_CHART:
      return {
        ...state,
        lesson_chart: action.payload
      };
    case types.GET_CLASS_TOPIC:
      return {
        ...state,
        class_topic: action.payload
      };
    case types.GET_EVENT_TOOLS:
      return {
        ...state,
        event_tools: action.payload
      };
    case types.GET_PLANNERF_LIST:
      return {
        ...state,
        planner_f_list: action.payload
      };
    case types.GET_HOMEWORKF_LIST:
      return {
        ...state,
        home_f_list: action.payload
      };
    case types.GET_PLANNER_LIST:
    return {
      ...state,
        planner_list: action.payload
    };
    case types.GET_HOMEWORK_LIST:
      return {
        ...state,
        home_list: action.payload
      };
    case types.GET_CLASSBOOK:
      return {
        ...state,
        classbook: action.payload.group_journal,
        class_teacher_fio: action.payload.class_teacher_fio,
        teacher_fio: action.payload.teacher_fio,
      };
    case types.SET_CLASSBOOK_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  GET_COUNTRIES,
  GET_EDUCATIONAL_STATUSES,
  GET_FORMS_OF_EDUCATION,
  GET_GROUP_NUTRITION_LIST,
  GET_GRADE_WEIGHT,
  GET_GROUP_NUTRITION_PARALLELS,
  GET_NUTRITION_GROUP, 
  GET_PERIOD_NUTRITION_LIST,
  GET_NUTRITION_PERIOD,
  GET_HEALTH_GROUP,
  GET_NUTRITION_PERIOD_FILES,
  GET_NUTRITION_PERIOD_FILE
} from "./types";





// <----  ГРУППЫ ПИТАНИЯ ---->

export const getGroupNutritionList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_GROUP_NUTRITION_LIST, payload: result });
    }
  }

};
export const getParallelsForGroupNutrition = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group/parallels `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_GROUP_NUTRITION_PARALLELS, payload: result });
    }
  }

};
export const getNutritionGroup = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      
      dispatch({ type: GET_NUTRITION_GROUP, payload: result });
    }
  }

};

export const updateGroupNutrition = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group/${params.xp_key}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupNutritionList());
    }
  };
}

export const createGroupNutrition = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getGroupNutritionList());
    } else createNotification('error', "Не удалось создать запись");
  }
};
export const deleteGroupNutrition = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getGroupNutritionList());
    } else createNotification('error', result.error);
  }
};

// <----  ПЕРИОДЫ ПИТАНИЯ ---->

export const getPeriodNutritionList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_PERIOD_NUTRITION_LIST, payload: result });
    }
  }

};

export const getNutritionPeriod = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      
      dispatch({ type: GET_NUTRITION_PERIOD, payload: result });
    }
  }
};

export const updatePeriodNutrition = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/${params.xp_key}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getPeriodNutritionList());
    }
  };
}

export const createPeriodNutrition = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period `, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getPeriodNutritionList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deletePeriodNutrition = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getPeriodNutritionList());
    } else createNotification('error', result.error);
  }
};


export const getPeriodNutritionFiles = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/files/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_NUTRITION_PERIOD_FILES, payload: result});
    } else  dispatch({ type: GET_NUTRITION_PERIOD_FILES, payload: null});
  }
};


export const createPeriodNutritionFile = (data, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  const fd = new FormData();
  fd.append("file", data);

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/file/${id}`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getPeriodNutritionFiles(id));
    }
  }
};


export const getPeriodNutritionFile = (file_name, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/file/${id}/${file_name}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result =  await response.blob()
      const objectURL = URL.createObjectURL(result);
      dispatch({ type: GET_NUTRITION_PERIOD_FILE, payload: objectURL });
    }  else  dispatch({ type: GET_NUTRITION_PERIOD_FILE, payload: null});
  }
};

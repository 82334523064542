import React from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import {add, remove, change, print, copy} from '../../assets/svg/footers'
import { useState, useEffect } from 'react'
import Modal from './modal'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getGroups } from '../../actions/groups'
import Loader from '../../components/Loader'
import Table from '../../components/Table'


export default function Directory () {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { groups, loading } = useSelector(state => state.groups)


  const [classId, setClassId] = useState(null)

  useEffect(() => {
    dispatch(getGroups())
  }, [])
  
  
  const openModal = (classId) => () => setClassId(classId)

  const closeModal = () => setClassId(null)

  const goHome = () => navigate('/')

  const renderRow = (data) => {
    const { gid, name, year } = data
    return (
      <tr onClick={openModal(gid)}>
        <td>{name}</td>
        <td>{year}</td>

       
      </tr>
    )
  }

  return (
    <div className={styles.wrapper}>
        <Modal
         classId={classId} 
         closeModal={closeModal}
         closeOnOverlayClick={false}
         closeOnEsc={false}
         showCloseIcon={false}  
         />
        <h1>Справочник</h1>
        {loading
          ? <Loader/>
          : groups?.groups.length &&
              <Table style={{backgroundColor: "white", width: "60%",position: "relative",left: "20%" }}>
                <thead>
                        <tr>
                            <th colspan="2">Объекты</th>
                        </tr>
                        <tr>
                            <th>Объект</th>
                            <th>Доступ</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        {groups?.groups
                          .map(renderRow)}
                    </tbody>
              </Table>
        }
        <footer className={styles.footer}>
            <div>
                <Button className={styles.footerButton} onClick={openModal()} mode='bordered'>{add()}Добавить</Button>
                <Button className={styles.footerButton} mode='bordered'>{remove()}Удалить</Button>
                <Button className={styles.footerButton} onClick={openModal()} mode='bordered'>{change()}Изменить</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{copy()}Копировать</Button>
            </div>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}

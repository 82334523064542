import React, { useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../../../components/Button';
import ModalValue from './Modal';
import Table from './Table';
import { deleteEquipment, setEquipmentsForRoom } from '../../../../../actions/rooms';
import { useDispatch } from 'react-redux';
import Confirmation from '../../../../../components/Confirmation/Confirmation';
const Equipment = ({modalOpen}) => {

    const dispatch = useDispatch();
  
    const [confirmation, setConfirmation] = useState(false);
    const [localModalOpen, setLocalModalOpen] = useState(false);


    // const handleModal = (el) => {
    //   if (modalOpen?.rid && el) {
    //   setLocalModalOpen(modalOpen?.rid, el);
    //   }
    // }

    const handleAddValue = (el) => { // Сохранить и закрыть попап для добавления оборудования
      const newArry = el.map(el => ({idequipment: el, rid: modalOpen?.rid}));
      dispatch(setEquipmentsForRoom(newArry, modalOpen?.rid));
      setLocalModalOpen(false);
    }

    const closeModal = () => setLocalModalOpen(false); // Закрыть попап для добавления оборудования

    const handleSubmit = () => {
      setLocalModalOpen(false);
    }

    const acceptConfirmation = () => { // Подтвердить удаление
      closeModal();
      dispatch(deleteEquipment(modalOpen.rid, confirmation?.idequipment));
      setConfirmation(false);
    };
    const rejectConfirmation = () => { // Отменить подтверждение
      setConfirmation(false);
    };

  return (
    <div>

      {confirmation && ( //Попап для подтверждения удаления оборудования
        <Confirmation
            top="30px"
            left="40px"
            title="Закрыть без сохранения?"
            confirmation={confirmation?.name} 
            acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
            rejectConfirmation={rejectConfirmation}
        />
      )}

      <ModalValue  //Попап для добавления
        modalOpen={modalOpen} 
        closeModal={closeModal} 
        localModalOpen={localModalOpen} 
        handleAddValue={handleAddValue} 
        handleSubmit={handleSubmit}/>

      <Table //Таблица со списком оборудования
      modalOpen={modalOpen}
      setConfirmation={setConfirmation}/>

    <div className={styles.button}>
      <Button onClick={() => setLocalModalOpen({type: 'add'})}>Добавить</Button> {/*Кнопка добавления оборудования */}
    </div> 
    
    </div>
  )
}

export default Equipment
import React from 'react'
import styles from './styles.module.css'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import { sex, statuses } from '../../../assets/const'

export default function Education () {

  const renderOptions = ({name, value}) => <option value={value}>{name}</option> 

  return (
    <div className={styles.tabWrapper}>
      <Checkbox label='Основное'/>
      <div className={styles.inputsRow}>
        <Input label='Образование'/>
        <Input label='Послевузовское образование'/>
      </div>
      <div className={styles.inputsRow}>
        <Input label='Наименование ОУ'/>
        <Input label='Название документа'/>
      </div>
      <div className={styles.inputsRow}>
        <Input label='Серия'/>
        <Input label='Номер'/>
      </div>
      <div className={styles.inputsRow}>
        <Input label='Дата выдачи'/>
        <Input label='Дата окончания'/>
      </div>
      <div className={styles.inputsRow}>
        <Input label='Код ОКСО специальности'/>
        <Input label='Название специальности'/>
      </div>
      <div className={styles.inputsRow}>
        <Input label='Код ОКПДР профессии'/>
        <Input label='Название профессии'/>
      </div>
      <div className={styles.inputsRow}>
        <Input label='E-mail'/>
      </div>
    </div>
  )
}

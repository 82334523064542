import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  GET_TYPES_DISEASE,
  GET_HEALTH_GROUP,
  GET_DISCHARGE,
  GET_TYPES_OF_VACCINATIONS,
  GET_VACCINATIONS,
  GET_VACCINATIOS_CURR,
  GET_MKB_TEN_TOP,
  GET_MKB_TEN_MID,
  GET_MKB_TEN_BOT,

  SET_MEDBLOCK_LOADING
} from "./types";


// МЕДПУНКТ СПРАВОЧНИКИ

// <---- ВИДЫ БОЛЕЗНЕЙ ---->
export const getTypesOfDisease = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/disease`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_TYPES_DISEASE, payload: result });
    }
  }
};

export const updateTypesOfDisease = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/disease/${params.disease}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getTypesOfDisease());
    }
  };
}

export const createTypesOfDisease = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/disease`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getTypesOfDisease());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteTypesOfDisease = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/disease/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getTypesOfDisease());
    }
  }
};


// <----  МКБ-10 ---->

export const getMKBtenTop = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/voz_diagnoses/top`, { 
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_MKB_TEN_TOP, payload: result });
    }
  }

};

export const getMKBtenMid = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/voz_diagnoses/mid/${id}`, { 
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_MKB_TEN_MID, payload: result });
    }
  }

};

export const getMKBtenBot = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/voz_diagnoses/bot/${id}`, { 
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_MKB_TEN_BOT, payload: result });
    }
  }

};

// export const updateMKBten = (params) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {

//     const response = await fetch(`${BACKEND_URL_PREFIX}/grade_value/${params.valid}`, {//TODO
//       method: "PUT",
//       body: JSON.stringify(params),
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       // await dispatch(getGradeOptions());
//     }
//   };
// }

// export const createMKBten = (data) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/grade_value`, {//TODO
//       method: "POST",
//       body: JSON.stringify(data),
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       createNotification('success', 'Успешно');
//       // await dispatch(getGradeOptions());
//     } else createNotification('error', "Не удалось создать запись");
//   }
// };

// export const deleteMKBten = (valid) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/grade_value/${valid}`, {
//       method: "DELETE",
//       ...buildDefaultHeaders(getState),
//     });
//     const result = await response.json();

//     if (response.ok) {
//       createNotification('success', 'Успешно');
//       // await dispatch(getGradeOptions());
//     } else createNotification('error', result.error);
//   }
// };

// <---- ГРУППЫ ЗДОРОВЬЯ ---->

export const getHealthgroup = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/healthgroup `, { 
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_HEALTH_GROUP, payload: result });
    }
  }

};

export const updateHealthgroup = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/healthgroup/${params.hid}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getHealthgroup());
    }
  };
}

export const createHealthgroup = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/healthgroup`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getHealthgroup());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteHealthgroup = (groupsId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/healthgroup/${groupsId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getHealthgroup());
    }
  }
};

// <---- ОСВОБОЖДЕНИЯ ---->

export const getDischarge = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/discharges`, { 
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_DISCHARGE, payload: result });
    }
  }

};

export const updateDischarge = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/discharges/${params.iddischarge}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getDischarge());
    }
  };
}

export const createDischarge = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/discharges`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getDischarge());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteDischarge = (groupsId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/discharges/${groupsId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getDischarge());
    }
  }
};

// <---- ВИДЫ ПРИВИВОК ---->
// общая таблица - длинный vaccinations
export const getVaccinations = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine`, { 
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_VACCINATIONS, payload: result }); 
    }
  }
};

// ТИП ВАКЦИНЫ (ВТОРАЯ МОДАЛКА)
// сами вакцины - короткий typesVaccinations:
export const getTypesOfVaccinations = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_MEDBLOCK_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vac_type`, { 
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_TYPES_OF_VACCINATIONS, payload: result });
    }
  }
  dispatch({ type: SET_MEDBLOCK_LOADING, payload: false });
};

// коетр прививка - длинная
export const getVaccinationsCurr = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_MEDBLOCK_LOADING, payload: true });

    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      
      dispatch({ type: GET_VACCINATIOS_CURR, payload: result });
    }
  }
  dispatch({ type: SET_MEDBLOCK_LOADING, payload: false });

};

// сами вакцины - короткий
export const updateTypesOfVaccinations = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vac_type/${params.c_vac_type}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      // await dispatch(getTypesOfVaccinations());
    }
  };
}
// сами вакцины - короткий
export const createTypesOfVaccinations = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vac_type`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      // await dispatch(getTypesOfVaccinations());
    } else createNotification('error', "Не удалось создать запись");
  }
};

// СЕЛЕКТ С КОРОТКИМИ ВАКЦИНАМИ typesVaccinations
export const createVaccine = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getVaccinations());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const updateVaccine = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine/${params.c_vaccine}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getVaccinations());
    }
  };
}

export const deleteTypesOfVaccinations = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vac_type/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      // await dispatch(getTypesOfVaccinations());
    }
  }
};

export const deleteVaccinations = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getVaccinations());
    }
  }
};


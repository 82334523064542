import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../components/ModalInputs'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createTrackProfile, updateTrackProfile } from '../../actions/directoryBasicEducation'

export default function ModalComponent({ data, closeModal, height, addMode }) {

  const dispatch = useDispatch()

  const handleCreate = (values) => {
    dispatch(createTrackProfile(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    dispatch(updateTrackProfile(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {
      name:'',
      isbasic: false,
      closed: false
    } : data,
    enableReinitialize: true,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]


  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsRow}>
              <Input
                label='Название'
                name='name'
                value={values.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />
            </div>
            <div className={styles.inputsRow}>
              <Checkbox label='Это базовый' checked={values.isbasic} value={!!values.isbasic} onChange={handleChange} name='isbasic' />
              <Checkbox label='Закрыт' checked={values.closed} value={!!values.closed} onChange={handleChange} name='closed' />
            </div>

          </div>
        </div>
        {/* <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
        <Button mode='white' onClick={() => setConfirmation(true)}>Закрыть</Button> */}

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>
    // values ?
    // <Modal
    //   classNames={{ modal: styles.modalMain }}
    //   closeOnOverlayClick={false}
    //   closeOnEsc={false}
    //   showCloseIcon={false}
    //   animationDuration={1}
    // //  {...props}
    // >
    //   <div className={styles.modalContentQuit} >

    //     <>
    //       <div className={styles.modalLocal} >
    //         <div className={styles.tabWrapper} >

    //           <div className={styles.inputsRow}>
    //             <Input
    //               label='Название'
    //               name='discharge'
    //               value={values.discharge}
    //               error={error('discharge')}
    //               onChange={handleChange}
    //               style={{ width: '600px' }}
    //             />
    //           </div>

    //         </div>
    //       </div>

    //       <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    //     </>
    //   </div>
    // </Modal> : ''
  )
}

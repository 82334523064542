import * as types from "../../actions/types";



const initialState = false


export default function loading (state = initialState, action) {
  switch (action.type) {
      
    case types.SET_STUDENTS_LOADING:
      return action.payload

    default:
      return state;
  }
}
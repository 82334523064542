import React, { useEffect, useState } from 'react'
import {Main, Access} from './ModalTabs'
import Modal from '../../components/Modal'
import { methodOffice } from '../../assets/const'
import { useFormik } from 'formik'
import { MethodOfficeContext } from '../../utils/context'
import { createFile, createFolder } from '../../actions/methodOffice'
import { useDispatch } from 'react-redux'


export default function ModalComponent ({data, closeModal, file}) {

  const dispatch = useDispatch()
  const [mode, setMode] = useState('main')

  const onSubmit = () => {
    formik.handleSubmit()
  }

  const formik = useFormik({
    initialValues:{},
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: console.log
    
  })
   
  const createFolderModal = () => {
      dispatch(createFolder(file?.mrid, formik.values))
       closeModal()
  }

  const renderTab = () => {
      switch (mode) {
          case 'main': return <Main />
          case 'access': return <Access />
          default: return
      }
  }
  
  return (
    <MethodOfficeContext.Provider value={{
       closeModal, 
       onSubmit,
       formik, 
       data, 
       addMode:!data?.lid, 
       file ,
       createFolderModal
       }}>
      <Modal
        open={!!data}
        menuItem={mode}
        setMenuItem={setMode}
        menuList={methodOffice}
      
      >
        {renderTab()}
      </Modal>
      </MethodOfficeContext.Provider>
  )
}
import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { checkAndUpdateToken } from "../../../actions/auth";

import { createPeriodNutrition, createPeriodNutritionFile, getNutritionPeriod, getPeriodNutritionFile, getPeriodNutritionFiles, updateGroupNutrition, updatePeriodNutrition, updatePeriodNutritionFile } from '../../../actions/directoryCanteen'
import { createNotification } from '../../../components/Notifications/Notifications'
import { BACKEND_URL_PREFIX } from '../../../assets/const'
import { api } from '../../../App'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
})

export default function Main({ closeModal, data, addMode }) {

  const dispatch = useDispatch()
  const { formOfEducation } = useSelector(state => state.directory)
  const { token } = useSelector(state => state.auth)

  const { groupNutritionList, files, filePNG } = useSelector(state => state.directoryCanteen)
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  console.log('image::: ', image);
  // console.log('imageSrc::: ', imageSrc);

  const reqFile = files && files.slice(-2) || null // не взять последнего...
  console.log('reqFile::: ', reqFile);

  const handleCreate = async (values) => {
    dispatch(createPeriodNutrition(values))
    closeModal()
  }

  const handleUpdate = async (values) => {
    dispatch(updatePeriodNutrition(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    // validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const handleAddFile = () => {
    if (file) {
      // dispatch(createPeriodNutrition(values))
      dispatch(createPeriodNutritionFile(file[0], values.xp_key));

    } else {
      createNotification('error', 'Выберите файл');
    }
    setFile('');
  }

  const fetchPhoto = async (file_name, id) => {
    const {data} = await api.get(`/xp_eat_period/file/${id}/${file_name}`, {responseType: 'blob'})
    console.log('data::: ', data);
    const url = URL.createObjectURL(data)
    console.log('url::: ', url);
    setImage(url)
  }

  useEffect(() => {
    (files && reqFile[0]?.file_name) && fetchPhoto(reqFile[0]?.file_name, values.xp_key)
  }, [files])

  useEffect(() => {
    data?.xp_key && dispatch(getNutritionPeriod(data.xp_key)) // ?? ОДИНАКОВЫЕ ЗНАЧЕНИЯ ПРИШЛО И ВЫБРАННОЕ
    data?.xp_key && dispatch(getPeriodNutritionFiles(data.xp_key))
  }, [data])


  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} style={{ display: "flex", justifyContent: 'space-evenly', flexDirection: 'column' }}>

          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
            <div style={{ padding: '15px' }}></div>
            <Select label='Группа питания' value={values.xp_eat_group} onChange={handleChange} name='xp_eat_group'>
              {groupNutritionList?.map(({ name, xp_key }) => <option value={xp_key}>{name}</option>)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Сортировка'
              name='sort'
              value={values.sort}
              error={error('sort')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
            <div style={{ padding: '15px' }}></div>
            <Input type='time' label='Начало' value={values.start} name='start' onChange={handleChange} />
            <Input type='time' label='Окончание' value={values.stop} name='stop' onChange={handleChange} />
            {/* <Input type='time' label='Количества работ' value={amountOfWork} onChange={e => setAmountOfWork(e.target.value)} /> */}
          </div>
          <Checkbox
            label='Отображать калории'
            checked={values.kkalvisible}
            value={values.kkalvisible}
            onChange={handleChange}
            name='kkalvisible'
            style={{ margin: '15px' }}
          />
          <div className={styles.buttons_wrap}>
            <div className={styles.buttons}>

              {!file ?
                <input className={styles.input} type="file" onChange={e => setFile(Object.values(e.target.files))} title="Добавить файл" />
                :
                <>
                  <div className={styles.fileName} title={file?.[0]?.name}>{file?.[0]?.name}</div>
                  <Button onClick={handleAddFile}>Добавить файл</Button>
                </>}
            </div>
          </div>
          <br />
          {!!image &&
            <img
              src={image}
              alt="0_o"
            // style={{width:'300px'}}
            />
          }
        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}

import * as types from "../../actions/types";



const initialState = false;


export function loading (state = initialState, action) {
  switch (action.type) {
    case types.SET_DISCIPLINE_STRUCTURE_LOADING:
      return action.payload;
    default:
      return state;
  }
}
import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Select } from '../../../components/ModalInputs'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getTeacherByGroupId, addGroupUser } from '../../../actions/permissions'
import Loader from '../../../components/Loader'

export default function Main ({closeModal, data, addMode}) {
  
  const dispatch = useDispatch()  
  const  {teachers, teachers_loading}  = useSelector(state => state.roles.permission_groups);


  const [selectedUser, setSelectedUser] = useState('');

  useEffect(() => {
    data && dispatch(getTeacherByGroupId(data));
  }, [data])
  
  const handleSubmit = () => {
    selectedUser && dispatch(addGroupUser(data, selectedUser));
    closeModal();
  }

  const renderOptions = ({fio, mid}) => <option value={mid}>{fio}</option> 

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsColumn}>
            {
              teachers_loading ? <Loader /> :
              <Select label='Преподаватель' onChange={(e) => setSelectedUser(e.target.value)} value={selectedUser}>
                {teachers && [{mid: "", fio: "Выберите преподавателя"}, ...teachers]?.map(renderOptions)}
              </Select>
            }
            {selectedUser == '' && <span className={styles.label}>Выберите преподавателя</span>}
          </div>

        </div>
      </div>
      <ModalFooter 
      handleSubmit={() => handleSubmit()}
       closeModal={closeModal} />
    </>
  )
}

import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {GET_INFO_MAIN } from "./types";




export const getInfoMain= () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    
    const response = await fetch(`${BACKEND_URL_PREFIX}/version`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const info= await response.json();

      dispatch({ type: GET_INFO_MAIN, payload: info });
    }
  }

};


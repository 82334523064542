import * as types from "../actions/types";

const initialState = {
  loading: false
};

export default function directoryBasicEducation(state = initialState, action) {
  switch (action.type) {

    case types.GET_TRACK_PROFILE_LIST:
      return {
        ...state,
        trackProfileList: action.payload
      };
    case types.GET_TRACK_PROFILE_CURR:
      return {
        ...state,
        trackProfileCurr: action.payload
      };
    case types.GET_STUDY_SHIFTS_LIST:
      return {
        ...state,
        studyShiftsList: action.payload
      };





    case types.SET_BASIC_EDUCATION_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import Button from '../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { TeachersSubjects, CabinetsSubjects, CabinetsTeachers, ComplexityClassesSubjects, TeachersLesson, WorkloadTeachersClass } from './Tabs'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getSchoolYears } from '../../actions/common'
import { SettingsContext } from '../../utils/context';


export default function ScheduleSettings() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const goHome = () => navigate('/')

  const {
    main,
    about,
    candidates,
    results,
    administration,
    export: exportData,
    kiosk,
    strength,
    control,
  } = useSelector(state => state.settings)
  const { scheduleSettingsTeachersSubList, scheduleSettingsRoomsSubList, scheduleSettingsRooms  } = useSelector(state => state.schedule_settings)

  const saveChanges = values => {
    const payload = { ...values, ...noticeSettingsForm.values }
    // dispatch(updateSettingsData(payload))
  }

  const form = useFormik({
    initialValues: {},
    onSubmit: saveChanges
  })

  useEffect(() => {
  }, [])

  useEffect(() => { dispatch(getSchoolYears()) }, [])

  useEffect(() => { form.setValues({ ...form.values, ...main }) }, [main])

  useEffect(() => {
    // if (!about) return;
    // const Foundation_date = moment(about.Foundation_date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    // form.setValues({...form.values, ...about, Foundation_date})
  }, [about])

  useEffect(() => { form.setValues({ ...form.values, ...results }) }, [results])

  useEffect(() => { form.setValues({ ...form.values, ...administration }) }, [administration])

  useEffect(() => { form.setValues({ ...form.values, ...exportData }) }, [exportData])

  useEffect(() => { form.setValues({ ...form.values, ...kiosk.data }) }, [kiosk.data])

  useEffect(() => { form.setValues({ ...form.values, ...strength.data }) }, [strength.data])

  useEffect(() => { form.setValues({ ...form.values, ...control }) }, [control])

  const noticeSettingsForm = useFormik({
    initialValues: candidates.notices || {},
    enableReinitialize: true,
    onSubmit: console.log
  })

  const examTicketsForm = useFormik({
    initialValues: candidates.exam_tickets || {},
    enableReinitialize: true,
    onSubmit: console.log
  })





  return (
    <SettingsContext.Provider value={{ form, noticeSettingsForm, examTicketsForm, scheduleSettingsTeachersSubList }}>
      {!!scheduleSettingsTeachersSubList &&
        <div className={styles.wrapper}>
          <h1>Настройки расписания</h1>
          <Tabs>
            <TabList className={styles.tabsBlock}>
              <Tab className={styles.tab} selectedClassName={styles.activeTab}>Преподватели-Предметы</Tab>
              <Tab className={styles.tab} selectedClassName={styles.activeTab}>Кабинеты-Предметы</Tab>
              <Tab className={styles.tab} selectedClassName={styles.activeTab}>Кабинеты-Преподватели</Tab>
              <Tab className={styles.tab} selectedClassName={styles.activeTab}>Сложность Классы-Предметы</Tab>
              <Tab className={styles.tab} selectedClassName={styles.activeTab}>Преподватели-Уроки</Tab>
              <Tab className={styles.tab} selectedClassName={styles.activeTab}>Нагрузка преподавателей по классам</Tab>

            </TabList>

            <TabPanel>
              <TeachersSubjects />
            </TabPanel>

            <TabPanel>
              <CabinetsSubjects />
            </TabPanel>

            <TabPanel>
              <CabinetsTeachers />
            </TabPanel>

            <TabPanel>
              <ComplexityClassesSubjects />
            </TabPanel>

            <TabPanel>
              <TeachersLesson />
            </TabPanel>
            <TabPanel>
              <WorkloadTeachersClass />
            </TabPanel>



          </Tabs>
          <footer className={styles.footer}>
            <Button mode='white' onClick={form.handleSubmit}>Сохранить</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
          </footer>
        </div>
      }
    </SettingsContext.Provider>


  )
}

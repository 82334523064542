import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import { sex, statuses } from '../../../assets/const'
import Button from '../../../components/Button'
import { checked } from '../../../assets/svg/other'
import Table from '../../../components/Table'
import { useDispatch } from 'react-redux'
import { StaffRosterContext } from '../../../utils/context'
import { getPersonTable } from '../../../actions/personnel'


export default function Qualification (table) {
  const { allForms, addMode, } = useContext(StaffRosterContext)

  const { values, handleChange, handleBlur } = allForms
  const dispatch = useDispatch()
  useEffect(()=>{
    if(table) dispatch(getPersonTable('physical_training',values.mid ))
   
  }, [])

  const renderOptions = ({name, value}) => <option value={value}>{name}</option> 



  const renderRow = (row) => {
    const {fio, login, sex, age, birthday, email, residential_address, date_of_birth} = row
    return (
      <tr onClick={{}}>
        <td>{fio}</td>
        <td>{checked()}</td>
        <td>{login}</td>
        <td></td>
        <td>{sex}</td>
        <td>{birthday}</td>
        <td>{age}</td>
        <td>{date_of_birth}</td>
        <td>{email}</td>
        <td>{residential_address}</td>
      </tr>
    )
  }

  return (
    <div className={styles.tabWrapper}>
        <div className={styles.inputsRow}>
          <Select label='Вид образования'>{sex.map(renderOptions)}</Select>
          <Select label='Форма обучения'>{sex.map(renderOptions)}</Select>
          <Select label='Дата начала'>{sex.map(renderOptions)}</Select>
          <Select label='Дата окончания'>{sex.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Тематика курсов'>{sex.map(renderOptions)}</Select>
          <Select label='Количество часов'>{sex.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Специальность'>{sex.map(renderOptions)}</Select>
          <Select label='Руководитель мероприятия(лектор)'>{sex.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Наименование ОУ, местонахождение'>{sex.map(renderOptions)}</Select>
          <Select label='Тип'>{sex.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Выдаваемые документы (название)'>{sex.map(renderOptions)}</Select>
          <Input label='Номер документа'/>
          <Select label='Дата выдачи'>{sex.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Основание'>{sex.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Button>Добавить строку</Button>
          <br/>
          <Button>Удалить строку</Button>
        </div>

        <Table style={{marginTop: '2rem'}}>
          <thead>
              <tr>
                  <th>Фамилия Имя Отчество</th>
                  <th>Фото</th>
                  <th>Логин</th>
                  <th>Заблокирован</th>
                  <th>Пол</th>
                  <th>Дата рождения</th>
                  <th>Возраст</th>
                  <th>ДР (Месяц/День)</th>
                  <th>Email</th>
                  <th>Адрес</th>
              </tr>
          </thead>
          <tbody>

          </tbody>
        </Table>
       
    </div>
  )
}

import * as types from "../../actions/types";



const initialState = {
    schoolYears: null,
    peoples: null,
    courses: null,
    profiles: null
};


export function filters (state = initialState, action) {
  switch (action.type) {
    case types.SET_DISCIPLINE_STRUCTURE_FILTER:
      return {...state, ...action.payload};
    default:
      return state;
  }
}
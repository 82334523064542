import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Table from '../../components/Table'
import { deleteGradeOption } from '../../actions/directory'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getMKBtenBot, getMKBtenMid, getMKBtenTop } from '../../actions/directoryMedCenter'


export default function MKBten() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { topList, midList, botList } = useSelector(state => state.directoryMedCenter)


  // РЕФАКОРНУТЬ
  const [selectedItem, setSelectedItem] = useState([])
  const [selectedItem2, setSelectedItem2] = useState([])
  const [selectedItem3, setSelectedItem3] = useState([])

  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getMKBtenTop());
  }, [])

  useEffect(() => {
    selectedItem?.id && dispatch(getMKBtenMid(selectedItem.id));
    selectedItem2?.id && dispatch(getMKBtenBot(selectedItem2.id));
  }, [selectedItem, selectedItem2])

  const openModal = (data) => () => {
    if (selectedItem.id === data.id) setSelectedItem([]);
    else setSelectedItem(data)
  }
  const openModal2 = (data) => () => {
    if (selectedItem2.id === data.id) setSelectedItem2([]);
    else setSelectedItem2(data)

  }
  const openModal3 = (data) => () => {
    setSelectedItem3(data)
    setSelectedItem2([])
  }

  const closeModal = () => {
    setSelectedItem(null)
  }

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteGradeOption(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };
  const renderTop = (data) => {
    const { code, id, name } = data
    return (
      <>
        <tr  >
          <td name='code' onClick={openModal(data)}>{code}</td>
          <td name='name' onClick={openModal(data)} >{name}</td>
        </tr>
        {selectedItem?.id == id && midList?.map(renderMid)}
      </>
    )
  }

  const renderMid = (data) => {
    const { code, id, name } = data
    return (
      <>
        <tr style={{ backgroundColor: '#95d7fd' }} >
          <td name='code' onClick={openModal2(data)}>{code}</td>
          <td name='name' onClick={openModal2(data)} >{name}</td>
        </tr>
        {selectedItem2?.id == id && botList?.map(renderBot)}
      </>
    )
  }
  const renderBot = (data) => {
    const { code, name } = data
    return (
      <tr style={{ backgroundColor: '#a7f3b4' }} >
        <td name='code' onClick={openModal3(data)}>{code}</td>
        <td name='name' onClick={openModal3(data)} >{name}</td>
      </tr>
    )
  }
  return (
    <div className={styles.wrapper} style={{ width: '1200px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Удалить ${confirmation.id}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      <h1>МКБ-10</h1>
      <Table style={{ marginTop: '2rem', backgroundColor: 'white' }}>
        <thead>
          <tr>
            <th>Код по МКБ10</th>
            <th>Наименование болезни</th>
          </tr>
        </thead>
        <tbody>
          {topList?.map(renderTop)}
        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>

      </div>
      <footer className={styles.footer}>
        <Button mode='white' onClick={goHome}>Назад</Button>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
      </footer>
    </div>
  )
}
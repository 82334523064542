import React from 'react'
import Modal from 'react-responsive-modal'
import styles from './styles.module.css'
import cn from 'classnames'


export default function ModalComponent ({
    children = [],
    menuList = [],
    menuItem = '',
    setMenuItem = () => {},
    ...props
}) {

    const renderLi = ({name, value, icon}) => {
        const selected = value === menuItem
        return (
            <li
                onClick={() => setMenuItem(value)}
                className={cn(styles.modalMenuItem, {
                    [styles.selectedMenuItem]: selected
                })}
            >
                {icon()}
                {name}
            </li>
        )
    }
  

return (
    <Modal
        classNames={{ modal: styles.modalMain }}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}  
        animationDuration={1}
        {...props}
      >
        <div className={menuList.length ? styles.modalContent : styles.modalContentQuit}   style={ typeof(props.height) === 'string' ? {width: "800px", height: props.height} : {}}>
        
            <div className={styles.modalMenu}>
            <ul>{menuList.map(renderLi)}</ul>
            </div>
            {children}
        </div>
    </Modal>
  )
}

import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { 
  GET_METHOD_OFFICE_CYCLE, 
  GET_METHOD_OFFICE_ROLES, 
  GET_METHOD_OFFICE_ACCESS, 
  SET_METHOD_OFFICE_LOADING, 
  GET_METHOD_OFFICE_ACCESS_FILTERED_PERSONNEL,
  GET_METHOD_OFFICE_ACCESS_PERSONNEL,
  METHOD_OFFICE_TABLE_CLEAN,
  GET_METHOD_OFFICE_FILES,
  GET_METHOD_OFFICE_FOLDER,
  GET_METHOD_OFFICE_SUBFOLDER,
  } from "./types";


export const  getMethodOfficeCycle = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/mathodicalroom/cycle`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {

      const cycle = await response.json();
     
      dispatch({ type: GET_METHOD_OFFICE_CYCLE, payload: cycle });

    }
  }
};
export const  getMethodOfficeRoles = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_groups/mathodicalroom `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {

      const roles = await response.json();
     
      dispatch({ type: GET_METHOD_OFFICE_ROLES, payload: roles });

    }
  }
};
export const  getMethodOfficeAccess = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/mathodicalroom/access`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {

      const access = await response.json();
     
      dispatch({ type: GET_METHOD_OFFICE_ACCESS, payload: access });

    }
  }
};

export const getMethodOfficeAccessFilteredPersonnel = (params) => async (dispatch, getState) => {
  const {role, cycle} = params
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/people/mathodicalroom?pmid=${role}&did=${cycle} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: GET_METHOD_OFFICE_ACCESS_FILTERED_PERSONNEL, payload });
    }
  }
  dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: false });
};

export const methodOfficeTableClean = () => ({
  type: `${METHOD_OFFICE_TABLE_CLEAN}`
});

export const getMethodOfficeAccessPersonnel = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/people/mathodicalroom`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: GET_METHOD_OFFICE_ACCESS_PERSONNEL, payload });
    }
  }
  dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: false });
};


export const getMethodOfficeFiles = (mr_parent) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/mathodicalroom/files/${mr_parent} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: GET_METHOD_OFFICE_FILES, payload });
    }
  }
  dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: false });
};

export const getMethodOfficeFolders = (mr_parent) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
    const response = await fetch( mr_parent !==0 ?
      `${BACKEND_URL_PREFIX}/mathodicalroom/folder/${mr_parent} ` :
      `${BACKEND_URL_PREFIX}/mathodicalroom/folder `
      , {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      mr_parent === 0 ?
      dispatch({ type: GET_METHOD_OFFICE_FOLDER, payload }) :
      dispatch({ type: GET_METHOD_OFFICE_SUBFOLDER, payload });
    }
  }
  dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: false });
};


export const createFile = (file, mrid) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
    const responseInfo = await fetch(`${BACKEND_URL_PREFIX}/mathodicalroom/files/${mrid}`, {
      method: "POST",
      body: JSON.stringify({mr_name:file.name}),
      ...buildDefaultHeaders(getState),
    });
    const payload = await responseInfo.json();

    if(responseInfo.ok) {
    const fd = new FormData()
    fd.append('file', file)
    dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/mathodicalroom/files/save/${payload.mrid}`, {
      method: "POST",
      body:fd ,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      await dispatch(getMethodOfficeFiles(mrid));
    }
  }
  }
  dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
};

export const createFolder = (mrid, params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/mathodicalroom/folder/${mrid || 0}`, {
      method: "POST",
      body:JSON.stringify(params),
      
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      await dispatch(getMethodOfficeFolders(0));
    }
  }
  dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
};

export const deleteLowFolder = (mrid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/mathodicalroom/folder/${mrid}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getMethodOfficeFolders(0));
    }
  }
  dispatch({ type: SET_METHOD_OFFICE_LOADING, payload: false });
};
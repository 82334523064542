import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { ClassingContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { deleteHistoryGroups, getGroupHistory,  } from '../../../actions/groups'
import Loader from '../../../components/Loader'
import Table from '../../../components/Table'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import ModalHistory from '../../../components/ModalHistory/ModalHistory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../../components/Confirmation/Confirmation'
import { getTermTypes } from '../../../actions/common'


export default function History (props) {
  
  const dispatch = useDispatch()
  const { classId, closeModal, infoCreateChange } = useContext(ClassingContext)
  const { groupHistory, modalLoading: loading } = useSelector(state => state.groups)

  const [modalHistory, setModalHistory] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const acceptConfirmation= () => {
    // closeModal();
    dispatch(deleteHistoryGroups(confirmation));
    dispatch(getGroupHistory(classId))
    setConfirmation(false);
  };
  const rejectConfirmation= () => {
    setConfirmation(false);
  };

  useEffect(() => {
    dispatch(getGroupHistory(classId))
    dispatch(getTermTypes())
  }, [])

  const createHistory = () => {
    setIsCreating(true)
    setModalHistory(true)
  }
  const updateHistory = (values) => {
    setIsCreating(false)
    setModalHistory(values)
  }
  
  // const renderRow = ({name, calc_years, calc_term_type_name}, index) => (
    const renderRow = (values, index) => (
    <tr key={values.xp_key}>
      <td colSpan={1} onClick={()=> updateHistory(values)}>{ values.calc_years}</td>
      <td colSpan={1} onClick={()=> updateHistory(values)}>{ values.name}</td>
      <td colSpan={1} onClick={()=> updateHistory(values)}>{ values.year}</td>
      <td colSpan={1} onClick={()=> updateHistory(values)}>{ values.liter}</td>
      <td colSpan={1} onClick={()=> updateHistory(values)}>{values.calc_term_type_name}</td>
      <td><i className={styles.trash} style={{margin: '0 auto'}} onClick={() => {setConfirmation({...values, classId})}}><BiTrash/></i></td>
    </tr>
  )

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          {confirmation && (
                    <Confirmation
                        top="30px"
                        left="40px"
                        title="Удалить данную запись?"
                        confirmation={confirmation?.name} 
                        acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
                        rejectConfirmation={rejectConfirmation}
                    />
          )}
       
          {loading
            ? <Loader/>
            : !!groupHistory?.length &&
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th>Годы</th>
                        <th>Название</th>
                        <th>Год обучения</th>
                        <th>Литера</th>
                        <th>Период</th>
                        <th>Удалить</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupHistory.map(renderRow)}
                      <tr>
                        {/* {isCreating ?
                            <td className={styles.deleteCell} colSpan={14} onClick={addCell}> + </td> : null} */}
                      </tr>
                    </tbody>
                    <br/>     
                  </Table>
                  {/* <Button onClick={createHistory}>{'Добавить'}</Button> */}
                </>
          }
                  <Button onClick={createHistory}>{'Добавить'}</Button>

        </div>
        {modalHistory && 
         <ModalHistory
          top="30px"
          left="40px"
          title="Закрыть без сохранения?"
          modalHistory={modalHistory} 
          setModalHistory={setModalHistory}
          groupHistory= {groupHistory}
          classId={classId}
          isCreating={isCreating}
          setIsCreating={setIsCreating}
        
     />}
      </div>
      <ModalFooter closeModal={closeModal} handleSubmit={closeModal} infoCreateChange={infoCreateChange} />
    </>
  )
}

import React from 'react'
import styles from './styles.module.css'
import { Input, Select } from '../../../../../components/ModalInputs';
import { statusAccess } from '../../../../../assets/const';




const Main = ({ modalOpen, setModalOpen, tfo_list, tfo_types, teachers, roomTypes, closeModal, handleSubmit1, values, handleChange, error }) => {


    return (
        <div>
            <div className={styles.inputsRow}>
                <Input
                    label='Название'
                    name='short_title'
                    value={values.short_title}
                    onChange={handleChange}
                    error={error('short_title')}
                />
            </div>
            <div className={styles.inputsRow}>
                <Input
                    label='Полное название'
                    name='full_title'
                    value={values.full_title}
                    onChange={handleChange}
                    error={error('full_title')}
                />
            </div>
            <div className={styles.inputsColumn}>
                <Select
                    name='tfoid'
                    label='База объекта'
                    value={values.tfoid}
                    onChange={handleChange}
                >
                    <option value='' >Выбрать</option>
                    {tfo_list?.map(({ type_title, tfoid }) => <option value={tfoid}>{type_title}</option>)}
                    {/* {tfo_types?.map(({ title, tfotid }) => <option value= {tfotid}>{title}</option>)} */}
                </Select>
                <Select
                    // error={checkError('type')}
                    name='typeid'
                    label='Тип объекта'
                    value={values.typeid}
                    onChange={handleChange}
                >

                    <option value=''>Выбрать</option>
                    {/* {tfo_types?.map(({title, tfotid}) => <option value={tfotid}>{title}</option>)} */}
                    {roomTypes?.map(({ name, rtid }) => <option value={rtid}>{name}</option>)}
                </Select>
                <Select
                    // error={checkError('mid')}
                    name='teacher_mid'
                    label='Ответственный'
                    value={values.teacher_mid}
                    onChange={handleChange}
                >
                    <option value=''>Выбрать</option>
                    {teachers?.teachers?.map(({ mid, calc_fio }) => <option value={mid}>{calc_fio}</option>)}
                </Select>
                <Select
                    label='Статус'
                    name='isactive'
                    value={values.isactive}
                    onChange={handleChange}
                >
                    <option value=''>Выбрать</option>
                    {statusAccess?.map(({ value, name }) => <option value={value}>{name}</option>)}
                </Select>


            </div>


        </div>
    )
}

export default Main
import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosAdd } from 'react-icons/io'
import {thrash} from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './Modal'
import { Pagination } from '../../components/Pagination'
import { deleteSchoolYear, setPage, getSchoolYearsList } from '../../actions/schoolYears'
import Table from '../../components/Table'
import { getTypesStudySessions } from '../../actions/directory'


export default function TypesStudySessions () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState(null)

  const {typesStudySessions} = useSelector(state => state.directory)

  useEffect(() => {
    dispatch(getTypesStudySessions());
    dispatch(setPage(0));
  }, [])
  

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)
  
  const goHome = () => navigate('/')

  // const thrashClickHandler = (id) => (e) => {
  //   e.stopPropagation()
  //   dispatch(deleteSchoolYear(id))
  // }

  const renderLi = (data) => {
    const {typename, isavailable, ismain, isdop, fixed_weight, default_weight, no_access, istest } = data
    return (
      <tr onClick={openModal(data)}>
        <td>{typename}</td>
        <td>{ismain  !==0 ? "Да" : ""}</td>
        <td>{isdop !==0 ? "Да" : "" }</td>
        <td>{(no_access !== null && no_access !== 0) ? 'Да': ''}</td>
        <td>{default_weight}</td>
        <td>{fixed_weight  !==0 ? "Да" : ""}</td>
        <td>{isavailable ? 'Да': ''}</td>
        <td>{istest  !==0 ? "Да" : ""}</td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{width: '1200px', margin: '0 auto'}}>
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height='500px'

        />
        <h1>Типы учебных занятий</h1>
        <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
         <thead>
          <tr>
              <th>Тип учебного процесса</th>
              <th>Основное</th>
              <th>Дополнительное</th>
              <th>Используется</th>
              <th>Вес оценок</th>
              <th>Редактирование веса</th>
              <th>Доступен</th>
              <th>Участие в КОУ, СОУ, % усп.</th>
          </tr>
          </thead>
         <tbody>

          {typesStudySessions?.map(renderLi)}

          </tbody>
        </Table>

        {/* <div style={{display:'flex', flexDirection: "row"}}>
          <Button
              style={{padding: '.5rem 1.5rem', margin: '12px'}}
              onClick={openModal()}
          >
              <IoIosAdd size={30}/>
              Добавить 
          </Button>
        </div> */}
        
        <footer className={styles.footer}>
             <Button mode='white' onClick={goHome}>Назад</Button>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}


// <div className={styles.secondLiBlock}>
// <div onClick={thrashClickHandler(xp_key)}>{thrash()}</div>
// </div>

// <Pagination
// onPageChange={pageClick}
// pageCount={pageCount}
// pageNumber={pageNumber}
// loading={loading && !selectedItem}
// >
// </Pagination>
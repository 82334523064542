import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import {weekForSchedule} from "../../assets/const";
import Loader from '../Loader';

function ScheduleTable({schedule, loading, handleExportToExcel, onCellDoubleCLick}) {
    
    const [listOfClasses, setListOfClasses] = useState([]);
    const [uniqueTimes, setUniqueTimes] = useState([]);
    const [daysOfWeek, setDaysOfWeek] = useState([]);

    useEffect(() => {
        // Получаем список всех классов
        const classes = Array.from(new Set(schedule.map(item => item.groupname)));
        setListOfClasses(classes);

        // Получаем список всех времен начала уроков
        const allTimes = schedule.map(item => item.starttime);
        // Получаем уникальные времена начала уроков
        const times = Array.from(new Set(allTimes));
        setUniqueTimes(times);

        // Получаем список всех дней недели
        const allDays = schedule.map(item => item.day_of_week);
        // Получаем уникальные дни недели
        const days = Array.from(new Set(allDays));
        setDaysOfWeek(days);
    }, [schedule]);

    // Функция для форматирования времени урока
    const formatLessonTime = (lesson) => {
        return lesson
            ? `${lesson.periodname} (${lesson.periodtime || ''})`
            : '';
    };

    const onDoubleClick = (cellData) => () => {
        if (!onCellDoubleCLick) return;
        onCellDoubleCLick(cellData)
    }

    return (
        <div className={styles.wrapper}>
            {loading
                ? <Loader/>
                : schedule.length > 0
                    ? <> <div className={styles.scheduleTable} id="scheduleTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Время</th>
                                    {listOfClasses.map((className, index) => (
                                        <th key={index}>{className}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>

                                {daysOfWeek.map((dayOfWeek, dayIndex) => ( <> <tr></tr> < tr > <td>{(weekForSchedule.find(item => item.value === dayOfWeek))?.[ 'name' || 'name1']}</td> </tr>
                                
                                <tr>
                                </tr > <> {
                                    uniqueTimes.map((time, timeIndex) => {
                                        const lessonsForTime = schedule.filter(item => item.starttime === time && item.day_of_week === dayOfWeek);
                                        return (
                                            <tr key={timeIndex}>
                                                <td className={styles.timeCell}>{formatLessonTime(lessonsForTime[0])}</td>
                                                {listOfClasses.map((className, classIndex) => {
                                                    const lessonsForClass = lessonsForTime.filter(item => item.groupname === className);
                                                    const lessonNames = lessonsForClass
                                                        .map(item => item.coursename)
                                                        .filter(Boolean); // Filter out empty strings
                                                    return <td onDoubleClick={onDoubleClick(lessonsForClass)} key={classIndex}>{lessonNames.join(' / ')}</td>;
                                                })}
                                            </tr>
                                        );
                                    })
                                } </></>))}
                            </tbody>
                        </table>
                    </div> </>
                : <div
                        style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "200px",
                        color: "#1671EF"
                    }}>НЕТ ДАННЫХ</div >
}
        </div>
    );
}

export default ScheduleTable

import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import moment from 'moment'
import { getLesson, getScClass } from '../../../../../actions/classbook'
import { useDispatch } from 'react-redux'
import { Textarea } from '../../../../ModalInputs'

const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

const TabHomeWork = ({modalData, lessons, scClass, studyPeriod, scYear, formData,  handleInputChange, data}) => {

  const [area, setArea] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLesson({scYear: formData.scYear, studyPeriod: formData.studyPeriod})); 
    dispatch(getScClass);
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>Даты: <span>{modalData?.lessonfulldate}, {days[moment(modalData?.lessonfulldate).isoWeekday() - 1]}</span></div>
      <div className={styles.row}>Предмет: <span>{lessons?.find(el => el.cid == formData?.lesson)?.title}</span></div>
      <div className={styles.row}>Класс: <span>{modalData?.grpname}</span></div>
      <Textarea className={styles.textarea} name= {"hwtext"} label="Текст" value={data.hwtext} onChange={e => {handleInputChange(e)}} />
    </div>
  )
}

export default TabHomeWork
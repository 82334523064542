import * as types from "../actions/types";
import Healthgroup from "../pages/Healthgroup";



const initialState = {
  pageNumber: 0,
  pageCount: 0,
  count: 0,
  list: null,
  currentPeriod: {},
  filePNG: [],
  loading: false
};

// МЕДПУНКТ СПРАВОЧНИКИ

export default function directoryCanteen(state = initialState, action) {
  switch (action.type) {

    //СТОЛОВКА
    case types.GET_GROUP_NUTRITION_LIST:
      return {
        ...state,
        groupNutritionList: action.payload
      };
    case types.GET_GROUP_NUTRITION_PARALLELS:
      return {
        ...state,
        parallelList: action.payload
      };
    case types.GET_NUTRITION_GROUP:
      return {
        ...state,
        group: action.payload
      };

    case types.GET_PERIOD_NUTRITION_LIST:
      return {
        ...state,
        periodNutritionList: action.payload
      };
    case types.GET_NUTRITION_PERIOD:
      return {
        ...state,
        period: action.payload
      };
    case types.GET_NUTRITION_PERIOD_FILES:
      return {
        ...state,
        files: action.payload
      };
    case types.GET_NUTRITION_PERIOD_FILE:
      return {
        ...state,
        filePNG: action.payload
      };
    //СТОЛОВКА


    case types.SET_PERIODS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
import * as types from "../../actions/types";



const initialState = null


export default function administration (state = initialState, action) {
  switch (action.type) {
      
    case types.SET_SETTINGS_ADMINISTRATION_DATA:
      return action.payload

    default:
      return state;
  }
}
import { api } from "../App";
import { SET_ACHIEVEMENTS_LIST, SET_ACHIEVEMENTS_LOADING } from "./types";




export const getAchievements = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ACHIEVEMENTS_LOADING, payload: true });
    const { data } = await api.get('/achievements');
    dispatch({ type: SET_ACHIEVEMENTS_LIST, payload: data.achievements });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACHIEVEMENTS_LOADING, payload: false });
  }
}

export const createAchievement = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_ACHIEVEMENTS_LOADING, payload: true });
    await api.post('/achievements', data);
    await dispatch(getAchievements())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACHIEVEMENTS_LOADING, payload: false });
    return success
  }
}

export const updateAchievement = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_ACHIEVEMENTS_LOADING, payload: true });
    await api.put(`/achievements/${id}`, data);
    await dispatch(getAchievements())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACHIEVEMENTS_LOADING, payload: false });
    return success
  }
}

export const deleteAchievement = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ACHIEVEMENTS_LOADING, payload: true });
    await api.delete(`/achievements/${id}`);
    await dispatch(getAchievements())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACHIEVEMENTS_LOADING, payload: false });
  }
}
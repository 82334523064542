import * as types from "../../actions/types";



const initialState = {
    pageNumber: 0,
    pageCount: 0,
    count: 0,
};


export function form (state = initialState, action) {
  switch (action.type) {
    case types.SET_CANDIDATES_FORM:
      return {...state, ...action.payload};
    default:
      return state;
  }
}
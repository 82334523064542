import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select, Textarea } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getTermGradeTypes, getTermTypes } from '../../../actions/common'
import { EducationalStructureContext } from '../../../utils/context'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле'),
  begdate: Yup.string().required('обязательное поле'),
  enddate: Yup.string().required('обязательное поле'),
})


export default function Main() {

  const dispatch = useDispatch()
  const { addMode, closeModal } = useContext(EducationalStructureContext)

  // TODO
  const handleCreateYear = (values) => {
    closeModal()
  }
  // TODO

  const handleUpdateYear = async (values) => {
    const { xp_key, ...payload } = values

    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: !addMode ? { year_grade_types: '', name: '', begdate: '', enddate: '' } : addMode,
    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateYear : handleUpdateYear
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const { termTypes, termGradeTypes } = useSelector(state => state.common)
  const getMultiOptions = ({ title, trmtid }) => ({ label: title, value: trmtid })
  const renderOptions = ({ name, xp_key, term_type_id, term_type_name }) => <option value={term_type_id || xp_key}>{term_type_name || name}</option>

  const getTermGradeValues = () => {
    const options = termGradeTypes?.filter(({ trmtid }) => values.year_grade_types?.includes(trmtid)) || []
    return options.map(getMultiOptions)
  }

  useEffect(() => {
    !termTypes && dispatch(getTermTypes())
    !termGradeTypes && dispatch(getTermGradeTypes())
  }, [])

  const setTermGradeValues = (fieldName) => (grades) => {
    const newValue = grades.map(({ value }) => value).join(',')
    setFieldValue(fieldName, newValue)
  }

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              label='Код'
              name='code'
              value={values.code}
              error={error('code')}
              onChange={handleChange}
            // style={{width: '300px'}}
            />

            <Select
              label='Категория'
              value={getTermGradeValues()}
              options={termGradeTypes?.map(getMultiOptions)}
              onChange={setTermGradeValues('year_grade_types')}
            >
              {termTypes?.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{ width: '500px' }}
            />
          </div>
          <div className={styles.inputsRow}>
            <Checkbox label='Это цикл обучения' />
            <Checkbox label='Это должность' />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Должность занимает'
              // name='name'
              // value={values.name}
              error={error('name')}
              onChange={handleChange}
            // style={{width: '300px'}}
            />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{ resize: 'none', width: '550px', height: '110px' }}
              label='Служебная информация'
              value={values.serviceinfo}
              name='serviceinfo'
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}

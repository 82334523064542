import * as types from "../actions/types";

const initialState = {
  loading: false
};

// МЕДПУНКТ СПРАВОЧНИКИ

export default function directoryMedCenter (state = initialState, action) {
  switch (action.type) {
    case types.GET_TYPES_DISEASE:
      return {
        ...state,
        diseaseList: action.payload
      };
    case types.GET_HEALTH_GROUP:
      return {
        ...state,
        healthgroup: action.payload
      };
    case types.GET_DISCHARGE:
      return {
        ...state,
        discharge: action.payload
      };
    case types.GET_VACCINATIONS:
      return {
        ...state,
        vaccinations: action.payload
      };
    case types.GET_VACCINATIOS_CURR:
      return {
        ...state,
        vaccinationsCurr: action.payload
      };
    case types.GET_MKB_TEN_TOP:
      return {
        ...state,
        topList: action.payload
      };
    case types.GET_MKB_TEN_MID:
      return {
        ...state,
        midList: action.payload
      };
    case types.GET_MKB_TEN_BOT:
      return {
        ...state,
        botList: action.payload
      };


    case types.GET_TYPES_OF_VACCINATIONS:
      return {
        ...state,
        typesVaccinations: action.payload
      };

    

    case types.SET_MEDBLOCK_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
import { api } from "../App";
import { SET_DO_SCHEDULE_VARIANTS_LIST, SET_DO_SCHEDULE_VARIANTS_LOADING } from "./types";




export const getScheduleVariants = (year_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LOADING, payload: true });
    const { data } = await api.get('/schedule_variant_do', {params: {year_id}});
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LOADING, payload: false });
  }
}

export const createScheduleVariant = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LOADING, payload: true });
    await api.post('/schedule_variant_do', data);
    await dispatch(getScheduleVariants())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LOADING, payload: false });
    return success
  }
}

export const updateScheduleVariant = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LOADING, payload: true });
    await api.put(`/schedule_variant_do/${id}`, data);
    await dispatch(getScheduleVariants())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LOADING, payload: false });
    return success
  }
}

export const deleteScheduleVariant = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LOADING, payload: true });
    await api.delete(`/schedule_variant_do/${id}`);
    await dispatch(getScheduleVariants())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_DO_SCHEDULE_VARIANTS_LOADING, payload: false });
  }
}
import { api } from "../App";
import { SET_SCHEDULING_DATA, SET_SCHEDULING_LOADING } from "./types";



export const getSchedule = (varId, params) => async (dispatch, getState) => {
    try {
      
      dispatch({ type: SET_SCHEDULING_LOADING, payload: true });
      const response = await api.get(`/scheduling/${varId}`, {params})
      dispatch({ type: SET_SCHEDULING_DATA, payload: response.data })

    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SCHEDULING_LOADING, payload: false });
    }
};
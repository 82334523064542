import { api } from "../../App";
import { getObjectFromArray } from "../../utils/smallHelpers";
import { SET_SETTINGS_LOADING, SET_SETTINGS_STRENGTH_DATA, SET_SETTINGS_STRENGTH_TABLE_DATA } from "../types";



export const getStrengthData = () => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const { data } = await api.get('/strength_options')
      const payload = getObjectFromArray(data.options)
      dispatch({ type: SET_SETTINGS_STRENGTH_DATA, payload })

    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};

export const getStrengthTableData = (year) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const { data } = await api.get(`/strength_options/${year}`)
      dispatch({ type: SET_SETTINGS_STRENGTH_TABLE_DATA, payload: data.table_strength_options })

    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};
import { BACKEND_URL_PREFIX } from "../assets/const";
import { buildDefaultHeaders } from "./helper";
import { SET_ACCESS_ENTITY, SET_ACCESS_GROUP_DATA, SET_ACCESS_LOADING, SET_CHAPTERS_LIST } from "./types";
import { checkAndUpdateToken } from "./auth";
import { createNotification } from "../components/Notifications/Notifications";

export const getAccessChapters = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ACCESS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/access_control/chapters`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CHAPTERS_LIST, payload });
    }
  }
  dispatch({ type: SET_ACCESS_LOADING, payload: false });
};

export const getChapterEntity = (chapterId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ACCESS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/access_control/chapters/${chapterId}/entity`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_ACCESS_ENTITY, payload });
    }
  }
  dispatch({ type: SET_ACCESS_LOADING, payload: false });
};

export const getChapterGroupData = (chapterId, roleId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ACCESS_LOADING, payload: true });
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/access_control/chapters/${chapterId}/role/${roleId}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_ACCESS_GROUP_DATA, payload });
      
      } else {
        dispatch({ type: SET_ACCESS_GROUP_DATA, payload: null });
       
      }
    } catch (ERR) {
  
    } finally {
      dispatch({ type: SET_ACCESS_LOADING, payload: false });
    }
  }
};

export const uploadChapterGroupData = (payload, chapterId, roleId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ACCESS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/access_control/chapter/${chapterId}/role/${roleId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Изменения успешно сохранены')
    }
  }
  dispatch({ type: SET_ACCESS_LOADING, payload: false });
};
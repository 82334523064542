import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import {useDispatch, useSelector} from 'react-redux';
import {getRoomTypesList, editRoomType, addRoomType, deleteRoomType} from '../../actions/roomTypes';
import Modal from 'react-responsive-modal';
import {Checkbox, Input} from '../../components/ModalInputs';

import {createNotification} from '../../components/Notifications/Notifications';
import ModalFooter from '../../components/ModalFooter/ModalFooter';
import {BiTrash} from 'react-icons/bi';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Confirmation from '../../components/Confirmation/Confirmation';

const RoomTypes = () => {

    const dispatch = useDispatch();
    const {list, loading} = useSelector(state => state.roomTypes)

    const [modalOpen,
        setModalOpen] = useState(null);

    const [confirmation,
        setConfirmation] = useState(false);

    const acceptConfirmation = () => {
        dispatch(deleteRoomType(confirmation.rtid));
        setConfirmation(false);
    };
    const rejectConfirmation = () => {
        setConfirmation(false);
    };

    useEffect(() => {
        dispatch(getRoomTypesList());
    }, []);

    const handleModal = (data) => {
        setModalOpen(data)
    }

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleSubmit = () => {

        if (modalOpen &&( modalOpen.number.length < 50 && modalOpen.name.length < 500)) {
            if (modalOpen
                ?.rtid) {
                dispatch(editRoomType(modalOpen));
            } else {
                dispatch(addRoomType(modalOpen));
            }
            setModalOpen(false);
        } else {
            createNotification('error', `Ошибка заполнения полей`);
        }
    }

    return (
        <div className={styles.wrapper}>
            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                title="Удалить кабинет?"
                confirmation={'"' + confirmation
                ?.name + '"'}
                acceptConfirmation={() => acceptConfirmation(confirmation)}
                rejectConfirmation={rejectConfirmation}/>)}
            {modalOpen != null
                ? <Modal
                        close={closeModal}
                        open={modalOpen}
                        closeOnOverlayClick={false}
                        closeOnEsc={false}
                        showCloseIcon={false}
                        animationDuration={1}
                        classNames={{
                        modal: styles.roomTypesModal
                    }}
                        style={{
                        padding: '0',
                        margin: '0'
                    }}>

                        <> <div className={styles.modalMain}>
                            <div className={styles.tabWrapper}>
                                <div className={styles.title}>{modalOpen
                                        ?.rtid
                                            ? 'Редактирование'
                                            : 'Добавление'}</div>
                                <div className={styles.inputsRow}>
                                    <Input
                                        style={{
                                        width: '500px'
                                    }}
                                        label='Номер'
                                        value={modalOpen
                                        ?.number}
                                        error={modalOpen
                                        ?.number
                                            ? modalOpen?.number?.length >50 ? 'Не более 50 символов'  : ''
                                            : 'Обязательное поле'}
                                        onChange={e => setModalOpen({
                                        ...modalOpen,
                                        number: e.target.value
                                    })}/>
                                </div>
                                <div className={styles.inputsRow}>
                                    <Input
                                        style={{
                                        width: '500px'
                                    }}
                                        label='Название'
                                        value={modalOpen
                                        ?.name}
                                        error={modalOpen
                                        ?.name
                                            ? modalOpen?.name?.length >500 ? 'Не более 500 символов' : ''
                                            : 'Обязательное поле'}
                                        onChange={e => setModalOpen({
                                        ...modalOpen,
                                        name: e.target.value
                                    })}/>
                                </div>
                                <div className={styles.inputsRow}>
                                    <Checkbox
                                        label='Участвует в расписании'
                                        checked={modalOpen
                                        ?.scheduling}
                                        onChange={e => setModalOpen({
                                        ...modalOpen,
                                        scheduling: e.target.checked
                                    })}/>
                                </div>
                            </div>
                        </div>
                        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal}/>
                    </>
                </Modal>
                : null}

            {loading && <div className={styles.loader}><Loader/></div>}
            <h1>Типы кабинетов</h1>
            {!loading
                ? <> <div className={styles.tableWrapper}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Номер</th>
                                <th>Название</th>
                                <th>Участвует в рассписании</th>
                                <th>Удалить</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list
                                ?.map(el => <tr key={el.rtid}>
                                    
                                    <td onClick={() => handleModal(el)}>{el?.number}</td>
                                    <td onClick={() => handleModal(el)}>{el?.name}</td>
                                    <td onClick={() => handleModal(el)}>{el?.scheduling ? 'Да' : 'Нет'}</td>
                                    <td onClick={() => setConfirmation(el)}>
                                        <i className={styles.trash}><BiTrash/></i>
                                    </td>
                                </tr>)}
                            <th colSpan={4}>Всего: {list
                                    ?.length}</th>
                        </tbody>
                    </table>
                    <div style={{
                        margin: '0 2rem'
                    }}>
                        <Button onClick={openModal}>Добавить</Button>
                    </div>
                </div> </>
            : <></>}
        </div>
    )
}

export default RoomTypes
import React, { useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import {main, adresses, priveleges, parents, admission, medicine, education as educ, documents, candidateInfo, more as moree, qualification, award, punish, experience, lesson, event, role, attestation, history, curator} from '../../../assets/svg/modalSVG'
import { Checkbox } from '../../../components/ModalInputs'

const filesList = [
  {
    name: 'Файл 1',
    id: 1
  },
  {
    name: 'Файл 2',
    id: 2
  },
  {
    name: 'Файл 2',
    id: 3
  },
]

const Files = () => {

  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedHomeWork, setSelectedHomeWork] = useState([]);

  const handleSelect = (type, id) => {
    if (type === 'plan') {
      if (selectedPlan.includes(id)) {
        setSelectedPlan(selectedPlan.filter(item => item !== id))
      } else {
        setSelectedPlan([...selectedPlan, id])
      }
    } else {
      if (selectedHomeWork.includes(id)) {
        setSelectedHomeWork(selectedHomeWork.filter(item => item !== id))
      } else {
        setSelectedHomeWork([...selectedHomeWork, id])
      }
    }
  }

  return (
    <div className={styles.wrapperFiles}>
      <div className={styles.planningFiles}>
        <div className={styles.files}>
        {filesList.map(file => (
            <div className={styles.file} key={file.id}>
              <Checkbox onClick={() => handleSelect('plan', file.id)} /> {file.name} <i>{documents()}</i>
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <Button mode='white' disabled={selectedPlan.length > 0}>Добавить</Button>
          <Button mode='white' disabled={selectedPlan.length === 0}>Удалить</Button>
          <Button mode='white'>Скачать</Button>
          <Button mode='white' disabled={selectedPlan.length !== 1}>Просмотр</Button>
        </div>
      </div>

      <div className={styles.homeworkFiles}>
        <div className={styles.files}>
        {filesList.map(file => (
            <div className={styles.file} key={file.id}>
              <Checkbox onClick={() => handleSelect('homeWork', file.id)}/> {file.name} <i>{documents()}</i>
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <Button mode='white' disabled={selectedHomeWork.length > 0}>Добавить</Button>
          <Button mode='white' disabled={selectedPlan.length === 0}>Удалить</Button>
          <Button mode='white'>Скачать</Button>
          <Button mode='white' disabled={selectedPlan.length !== 1}>Просмотр</Button>
        </div>
      </div>
    
    </div>
  )
}

export default Files
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from '../../../components/ModalInputs'
import styles from '../styles.module.scss'
import Table from '../../../components/Table'
import { getScheduleSettingsLessons, getScheduleSettingsRooms, getScheduleSettingsRoomsSubjects, updateScheduleSettingsRoomsSubjects, updateScheduleSettingsTeachersSubjects } from '../../../actions/scheduleSettings'
import Loader from '../../../components/Loader'


export default function CabinetsSubjects() {

  const dispatch = useDispatch()
  const { scheduleSettingsRooms, scheduleSettingsRoomsSubList, loading } = useSelector(state => state.schedule_settings)
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(getScheduleSettingsLessons())
    dispatch(getScheduleSettingsRoomsSubjects())
    dispatch(getScheduleSettingsRooms())
  }, [])

  useEffect(() => {
    dispatch(getScheduleSettingsRoomsSubjects(selected))
  }, [selected])

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target
    setSelected({
      ...selected,
      [name]: value === 'on' ? checked : value
    })
  }

  const selectedCell = (data) => () => {
    const res = { "rid": data[0], "cid": data[1] }
    dispatch(updateScheduleSettingsRoomsSubjects(res))
  }

  const renderLi = (data) => {
    if (data) return (
      <tr key={data[0]}>
        <td className={styles.fio}>{data[1]}</td>
        {data[2].map((el, i) =>
          <td
            key={el[0]}
            onClick={selectedCell([scheduleSettingsRooms?.[i].rid, data[0]])}
            style={{ backgroundColor: el[1] === 1 && '#3689FF' }}

          ></td>)}
      </tr >
    )
  }

  return (
    loading ? <Loader /> :
      <div className={styles.inputsWrapper}>
        <Checkbox label='С закрытыми предметами' isChecked={selected.cycleCheck} onChange={(e) => handleInputChange(e)} name='cycleCheck' />

        <Table style={{ marginTop: '2rem', backgroundColor: 'white', position: 'relative', height: '70vh' }}>
          <thead className={styles.headSchSet}>
            <tr>
              <th className={styles.fio2}></th>
              {scheduleSettingsRooms?.map(el => <th>{el.roomname}</th>)}
            </tr>
          </thead>
          <tbody>

            {scheduleSettingsRoomsSubList?.map(renderLi)}

          </tbody>
        </Table>

      </div>
  )
}

import styles from "../styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import saveExcel from "./saveExcel";
import importExcel from "./importExcel";
import { mofifiedData } from '../../../utils/programModules'
import { updateChildrensLine, updateChildrensLevel, createChildren, } from "../../../actions/educationalStructure";
import { useDispatch, useSelector } from "react-redux";


const newModule = { name: 'НОВЫЙ РАЗДЕЛ', code: 'НР', show: true, children: [] };
const newTopic = { name: 'НОВАЯ ЗАПИСЬ', code: 'НЗ', show: true, children: [] };


const Table = ({ initialData, updateData, params, handleOpenModal }) => {

    const { staff } = useSelector(state => state.educational_structure)
    const dispatch = useDispatch()
    const [data, setData] = useState(initialData && initialData)
    const [editingCellIds, setEditingCellIds] = useState(null);
    const [editingCellDepth, setEditingCellDepth] = useState(null);
    const [did, setDid] = useState(null);
    const [select, setSelect] = useState(null);
    const [owner_did, setOwner_did] = useState(null);
    const [toModal, setToModal] = useState(null);

    const [edit, setEdit] = useState(null);
    const [edit2, setEdit2] = useState(null);

    const navPanelRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        !data && initialData && initialData.length &&
            setData(initialData)
    }, [initialData])



    useEffect(() => {
        if (Array.isArray(toModal))
            handleOpenModal(toModal)
    }, [toModal])

    const handleMouseClick = (event) => {
        const path = event.path || (event.composedPath && event.composedPath());
        if (!editingCellIds || path.includes(navPanelRef?.current) || path.includes(inputRef?.current)) return;
        onSave()  // TODO
    }

    const toggleHideModule = (ids) => {
        const newData = [...data];
        setCellDataField(ids, newData, null, 'show', true);
        setData(newData);
    }

    const resolve = val => !isNaN(parseInt(val)) ? val.toString() : false;
    const selected = !!staff && staff.find(el => el.did === select)


    const setCellDataField = (ids, newData, value, field, setOpposite) => {
        if (ids.length === 1) newData[ids[0]][field] = setOpposite ? !newData[ids[0]][field] : value;
        else {
            let item = newData[ids[0]]
            ids.slice(1).forEach(id => {
                item = item.children[id];
            })
            item[field] = setOpposite ? !item[field] : value;
        }
    }

    const cellOnKeyPressHandler = (e, ids, type) => {

        const newData = [...data];
        setCellDataField(ids, newData, e.target.value, type);
        setData(newData);
    }

    const onCellOnceClickHandler = (ids, type, depth, did, owner_did,) => {
        if (data[ids[0]]?.children[ids[1]]?.name === 'НОВАЯ ЗАПИСЬ' || data[ids[0]]?.children[ids[1]]?.name === 'НОВЫЙ РАЗДЕЛ') return setEdit(data[ids[0]]?.children[ids[1]]?.name)
        setDid(did)
        setSelect(did)
        setOwner_did(owner_did)
        const newData = [...data];
        // reset old cell editing
        if (editingCellIds) setCellDataField([...editingCellIds], newData, false, 'editing');

        // set current cell editing
        // setCellDataField(ids, newData, type, 'editing');

        setEditingCellDepth(depth)
        setEditingCellIds(ids ? [...ids] : []);
        setData(newData);
    }

    // ф-я для поиска элемеНЗа по editingCellIds ([1,1,1,1]) в глубину массива data
    const findElementByPath = (data, path) => {
        // Проверяем, является ли путь корректным (не пустым и содержит только числа)
        if (!Array.isArray(path) || !path.every(Number.isInteger)) {
            throw new Error('Некорректный путь');
        }
        let current = data;
        for (let i = 0; i < path.length; i++) {
            const index = path[i];
            // Проверяем, существует ли текущий уровень и есть ли у него дочерние элемеНЗы
            if (!current[index] || !current[index].hasOwnProperty('children')) {
                return null; // ЭлемеНЗ не найден
            }
            current = current[index].children.length == 0 || path.length == i + 1 ? current[index] : current[index].children; // Переходим к следующему уровню
        }
        return current;
    }

    const onCellDoubleClickHandler = (ids, type, depth, did, owner_did) => {
        setDid(did)
        setOwner_did(owner_did)
        const newData = [...data];
        // reset old cell editing
        if (editingCellIds) setCellDataField([...editingCellIds], newData, false, 'editing');

        // set current cell editing
        // setCellDataField(ids, newData, type, 'editing');

        setEditingCellDepth(depth)
        setEditingCellIds([...ids]);
        setData(newData);
        handleOpenModal(findElementByPath(newData, ids))
    }

    // TODO пока не сохраняю
    const onSave = () => {
        const newData = [...data];
        setCellDataField([...editingCellIds], newData, false, 'editing');
        setEditingCellDepth(null);
        setEditingCellIds(null);
        handleUpdateData(newData)
        setData(newData);
    }

    const cellEnterPressHandler = (e) => {
        if (e.key === 'Enter') {
            onSave();
        }
    }

    const inputBlurHandler = () => {
        onSave()
    }
    const rowRef = useRef()

    const cellEdit = (e) => { //
        setEdit2(e.target.value)
    }
    const renderCell = (children, did, owner_did, text, editing, type, ids, depth,) => {
        const colSpan = type === 'name' ? 12 - (depth ? depth : 0) : 4
        return (
            <td
                colSpan={colSpan}
                onClick={() => onCellOnceClickHandler(ids, type, depth, did, owner_did)}
                onDoubleClick={() => onCellDoubleClickHandler(ids, type, depth, did, owner_did,)}
                // className={styles[type]}
                ref={rowRef}
                contentEditable
            >
                {children}
                {edit != null && edit === text ?
                    <input
                        type={'text'}
                        value={edit2}
                        // onChange={(e) => cellOnKeyPressHandler(e, ids, type)}
                        onChange={(e) => cellEdit(e, ids, type)}
                        ref={inputRef}
                        onKeyPress={cellEnterPressHandler}
                        autoFocus
                    />
                    : text}

            </td>
        )
    }

    const renderRows = (data) => {
        return (
            data.map((module, moduleIndex) => {
                const returned = [];
                returned.push(
                    <tr
                        key={`${module.name}${moduleIndex}${Math.random()}`}
                        className={module.editing ?
                            `${styles.module} ${styles.bgSelected}`
                            : styles.module}
                    >
                        {renderCell(
                            (<span
                                onClick={() => toggleHideModule([moduleIndex])}
                            >
                                {module.show ? '- ' : '+ '}
                            </span>),
                            module.did,
                            module.owner_did,
                            module.name,
                            module.editing && module.editing === 'name',
                            'name',
                            [moduleIndex],
                        )}
                        {renderCell(
                            null,
                            null,
                            null,
                            module.code,
                            module.editing && module.editing === 'code',
                            'code',
                            [moduleIndex]
                        )}
                        {renderCell(
                            null,
                            null,
                            null,
                            module.info,
                            module.editing && module.editing === 'info',
                            'info',
                            [moduleIndex]
                        )}
                    </tr>
                );

                if (module?.children?.length > 0 && module.show) returned.push(renderChildren(module.children, [moduleIndex]));

                return returned;
            })
        );
    }

    const renderChildren = (children, ids) => {
        const returned = [];

        children.forEach((child, childIndex) => {
            const idsArray = [...ids, childIndex];
            const depth = idsArray.length - 1;
            const select = child.did === selected.did

            returned.push(
                <tr
                    key={`${child.name}${childIndex}`}
                    className={child.editing ?
                        `${styles.child} ${styles.bgSelected}`
                        : styles.child}
                    style={select ? { backgroundColor: '#c5ddff' } : {}}
                >
                    {Array(depth).fill(1).map((x, i) => <td className={styles.gap}>{' '}</td>)}
                    {renderCell(
                        (<span
                            // onClick={() => toggleHideModule([...idsArray])}> {child.show ? '- ' : '+ '}
                            onClick={() => toggleHideModule([...idsArray])}> {child.show ? '- ' : child?.children?.length ? '+ ' : ' '}
                        </span>),
                        child.did,
                        child.owner_did,
                        child.name,
                        child.editing && child.editing === 'name',
                        'name',
                        idsArray,
                        depth,
                    )}
                    {renderCell(
                        null,
                        null,
                        null,
                        child.code,
                        child.editing && child.editing === 'code',
                        'code',
                        idsArray
                    )}
                    {renderCell(
                        null,
                        null,
                        null,
                        module.info,
                        module.editing && module.editing === 'info',
                        'info',
                    )}
                </tr>
            );
            if (child?.children?.length > 0 && child.show) returned.push(renderChildren(child.children, idsArray));
        });

        return returned;
    }

    const addFirst = () => add(true);
    const addNext = () => add(false);

    const add = (first) => {
        const newData = [...data];
        if (first) newData.push({ ...newModule });
        else {
            const ids = [...editingCellIds];
            if (ids.length === 1) newData.splice(ids[0] + 1, 0, {
                name: 'НОВЫЙ РАЗДЕЛ',
                code: 'НР',
                show: true,
                children: []
            });
            else {
                let item = newData[ids[0]]
                ids.slice(1, -1).forEach(id => {
                    item = item.children[id];
                })
                item.children.splice(ids.slice(-1)[0] + 1, 0, {
                    name: 'НОВАЯ ЗАПИСЬ',
                    code: 'НЗ',
                    show: true,
                    children: []
                });
                // handleOpenModal(item)
            }
        }

        setData(newData);
        // dispatch(createChildren(item.children))
    }

    const addToChildren = () => {
        const newData = [...data];
        const ids = [...editingCellIds];
        let item = newData[ids[0]]

        // if (ids.length === 1) item.children.push({name: 'НОВАЯ ЗАПИСЬ', code: 'НЗ', owner_did: did , children: {}});
        if (ids.length === 1) item.children.push({
            name: 'НОВАЯ ЗАПИСЬ',
            role: 0,
            mid: 0,
            info: '',
            color: '',
            owner_did: did,
            not_in: 0,
            application: 0,
            is_cycle: 0,
            sortorder: 0,
            is_position: 0,
            category: '',
            code: '',
        });
        else {
            ids.slice(1).forEach(id => {
                item = item.children[id];
            })
            // item.children.push({name: 'НОВАЯ ЗАПИСЬ', code: 'НЗ',  owner_did: did, children: {}});
            item.children.push({
                name: 'НОВАЯ ЗАПИСЬ',
                role: 0,
                mid: 0,
                info: '',
                color: '',
                owner_did: did,
                not_in: 0,
                application: 0,
                is_cycle: 0,
                sortorder: 0,
                is_position: 0,
                category: '',
                code: '',
            });
        }
        setData(newData);
        dispatch(createChildren(item.children))
    }

    const deleteItem = (ids, newData) => {
        if (ids.length === 1) newData.splice(ids[0], 1);
        else {
            let item = newData[ids[0]]
            ids.slice(1, -1).forEach(id => {
                item = item.children[id];
            })
            item.children.splice(ids.slice(-1)[0], 1);
        }
    }

    const move = (data, ind, step, parent) => {
        const x = data.splice(ind, 1);
        x[0].editing = false;
        data.splice(ind + step, 0, x[0])
    }

    // const handleUpdateData = (list, parent) => {
    //     const payload = parent ? [parent, ...list] : list
    //     const _payload = payload.map(({children, ...item}) => item)
    //     updateData(_payload, !parent)
    // }

    const handleUpdateData = (data) => {
        const payload = mofifiedData(data[0].children)
        // updateData(payload, params)
        updateChildrensLevel(payload, params)
        updateChildrensLine(payload, params)
    }

    const up = (ids, newData) => {
        if (ids.length === 1) move(newData, ids[0], -1);
        else {
            let item = newData[ids[0]]
            ids.slice(1, -1).forEach(id => {
                item = item.children[id];
            })
            const parent = ids.length > 2 ? item : null
            move(item.children, ids.slice(-1)[0], -1, parent)
            dispatch(updateChildrensLine(did, 'up'))
        }
    };

    const down = (ids, newData) => {
        if (ids.length === 1) move(newData, ids[0], 1);
        else {
            let item = newData[ids[0]]
            ids.slice(1, -1).forEach(id => {
                item = item.children[id];
            })
            const parent = ids.length > 2 ? item : null
            move(item.children, ids.slice(-1)[0], 1, parent)
            dispatch(updateChildrensLine(did, 'down'))
        }
    };

    const downToNext = (ids, newData) => {
        let item = newData;
        if (ids.length > 1) {
            item = item[ids[0]]
            ids.slice(1, -1).forEach(id => {
                item = item.children[id];
            })
            item = item.children;
        }

        const x = item.splice(ids.slice(-1)[0], 1);
        x[0].editing = false;
        item[ids.slice(-1)[0]].children.splice(0, 0, x[0]);
        // dispatch() // запросик с понижением в уровне
        // dispatch(updateChildrensLevel(did, owner_did))
    }

    const upOutOfCurrentModule = (ids, newData) => {
        const findOwner = !!staff && staff.find(el => el.did === did).owner_did

        if (ids.length === 2) {
            const child = newData[ids[0]].children.splice(ids[1], 1);
            child[0].editing = false;
            child[0].children = child[0].children ? child[0].children : [];
            child[0].show = true;
            newData.splice(ids[0], 0, child[0]);
        } else {
            let parent = newData[ids[0]]
            ids.slice(1, -2).forEach(id => {
                parent = parent.children[id];
            })
            const child = parent.children[ids.slice(-2)[0]].children.splice(ids.slice(-1)[0], 1);
            child[0].editing = false;
            child[0].children = child[0].children ? child[0].children : [];
            child[0].show = true;
            parent.children.splice(ids.slice(-2)[0], 0, child[0]);
            dispatch(updateChildrensLevel(did, findOwner))
        }
    }


    // const go = (action) => {
    //     console.log('action::: ', action);
    //     // const newData = [...data];
    //     // action([...editingCellIds], newData);
    //     setEditingCellIds(null);
    //     setEditingCellDepth(null);
    //     // handleUpdateData(newData)
    //     // setData(newData);
    // }
    const go = (action) => {
        const newData = [...data];
        action([...editingCellIds], newData);
        setEditingCellIds(null);
        setEditingCellDepth(null);
        handleUpdateData(newData)
        setData(newData);
    }
    const fromXLSX = async e => {
        const file = e.target.files[0];
        if (file.name.split('.')[file.name.split('.').length - 1] === 'xlsx') {

            const result = await importExcel(file);

            if (result) setData(result);

        }
        // else createNotification('error',
        //     `Формат файла ${file.name.split('.')[1]} не поддерживается, загрузите файл формата xlsx`,
        //     'Неверный формат',
        // );
    }

    const flattenData = (data, result = []) => {
        data.forEach(row => {
            result.push([row.name, row.code]);
            if (row.children.length > 0) flattenData(row.children, result);
        })
        return result;
    }

    const toXLSX = () => {
        const resolvedData = [['Наименование', 'Код'], ...flattenData(data)];
        saveExcel(resolvedData);
    };

    const goUpCheck = () => !!editingCellIds && (editingCellIds.slice(-1)[0] > 0);
    const goDownCheck = () => {
        let parent = data;
        if (editingCellIds.length > 1) {
            parent = parent[editingCellIds[0]].children;
            if (editingCellIds.length !== 2) {
                editingCellIds.slice(1, -1).forEach(id => {
                    parent = parent[id];
                    parent = parent.children;
                })
            }
        }
        ;
        return editingCellIds.slice(-1)[0] < parent.length - 1;
    }

    const goUpOutOfCurrentModuleCheck = () => !!editingCellIds && editingCellIds.length > 2;


    const canImport = []
    // Object.values(params).find(param => parseInt(param) === 0) === undefined

    return (
        <div>
            <div className={styles.table}>
                {!!data?.length
                    ? <table style={{ width: '80%', margin: '0 auto' }}>
                        <thead>
                            <tr>
                                <th colSpan={12}>Название</th>
                                <th colSpan={4}>Должность занимает</th>
                                <th colSpan={4}>Описание</th>
                            </tr>
                        </thead>
                        <tbody style={{ userSelect: "none" }}>
                            {renderRows(data)}
                        </tbody>
                    </table>
                    : <h5 style={{ textAlign: 'center', margin: '0 0 1rem 0' }}>
                        По заданным параметрам данные отсутствуют
                    </h5>
                }



                {/* <div className={styles.buttonsRow}>
                
                {canImport &&
                    <div>
                        <label htmlFor="fileInput">Импорт из эл. таблицы</label>
                        <input id="fileInput" type="file" onChange={fromXLSX}/>
                    </div>
                }
                {!!data?.length && <button onClick={toXLSX} disabled={!(Object.keys(data)?.length)}>Экспорт в эл. таблицу</button>}
            </div> */}
            </div>
            {!!data?.length && editingCellIds !== null && editingCellIds.length >= 2 &&
                <div ref={navPanelRef} className={styles.buttonsRow + ' ' + styles.controls} style={{ width: '80%', margin: '0 auto' }}>

                    <button
                        style={{ fontSize: '2rem' }}
                        disabled={!editingCellIds && !(data?.length === 0)}
                        onClick={data?.length === 0 ? addFirst : addNext}
                        title={'Добавить новый элемеНЗ'}
                    >
                        +
                    </button>

                    <button
                        style={{ fontSize: '2rem' }}
                        title={'Добавить новый дочерний элемеНЗ'}
                        disabled={!editingCellIds || editingCellDepth > 10}
                        onClick={addToChildren}
                    >
                        + ↴
                    </button>

                    <button
                        style={{ fontSize: '2rem' }}
                        disabled={!editingCellIds}
                        title={'Удалить'}
                        onClick={() => go(deleteItem)}
                    >
                        -
                    </button>

                    <button
                        style={{ fontSize: '2rem' }}
                        disabled={!goUpCheck()}
                        title={'Переместить вверх'}
                        onClick={() => go(up)}
                    // onClick={() => go('up')}
                    // onClick={() => dispatch(updateChildrensLine(did, 'up'))}
                    >
                        ⭡
                    </button>

                    <button
                        style={{ fontSize: '2rem' }}
                        disabled={!editingCellIds || !goDownCheck()}
                        title={'Переместить вниз'}
                        onClick={() => go(down)}
                    // onClick={() => dispatch(updateChildrensLine(did, 'down'))}
                    >
                        ⭣
                    </button>

                    <button
                        style={{ fontSize: '2rem' }}
                        disabled={!editingCellIds || !goDownCheck() || editingCellDepth > 10}
                        title={'На уровень ниже'}
                        onClick={() => go(downToNext)}
                    >
                        ⬎
                    </button>

                    <button
                        style={{ fontSize: '2rem' }}
                        disabled={!goUpOutOfCurrentModuleCheck()}
                        title={'На уровень выше'}
                        onClick={() => go(upOutOfCurrentModule)}
                    >
                        ⬏
                    </button>
                </div>
            }
        </div>
    )
}

export default Table;
import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { SET_REPORTS_LIST, SET_REPORTS_LOADING, SET_REPORTS_PARAMS } from "./types";
import { buildDefaultHeaders } from "./helper";



export const getReportsList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_REPORTS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/reports`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const {reports} = await response.json();
      dispatch({ type: SET_REPORTS_LIST, payload: reports });
    }
  }
  dispatch({ type: SET_REPORTS_LOADING, payload: false });
};

export const getReportParams = (reportId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_REPORTS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/reports/${reportId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_REPORTS_PARAMS, payload });
    }
  }
  dispatch({ type: SET_REPORTS_LOADING, payload: false });
};

export const getReport = (data) => async (dispatch, getState) => {
  let reportLink = ""
  try {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: SET_REPORTS_LOADING, payload: true });
      const response = await fetch(`${BACKEND_URL_PREFIX}/create_report`, {
        method: "POST",
        ...buildDefaultHeaders(getState),
        body: JSON.stringify(data)
      });
      if (response.ok) {
        reportLink = await response.json();
      }
    }
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_REPORTS_LOADING, payload: false });
    return reportLink;
  }
};


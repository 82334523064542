import React from 'react'
import styles from './styles.module.css'
import Input from '../../../components/Input'
import { IoMdTrash } from 'react-icons/io'

const Index = ({files = [], setFiles, filesDelete, setFilesDelete, type}) => {
    const handleDelete = (file) => {
        setFiles(files.filter(f => f !== file))
        if (file.file_path) {
            setFilesDelete([...filesDelete, file])
        }      
    }

    const handleAddFilse = (data) => {
        if (files) {
            setFiles([...files, ...Object.values(data)])
        } else {
            setFiles([...Object.values(data)])
        }
    }

    return (
        <div className={styles.fileContainer}>
            <div className={styles.fileWrap}>
                <div className={styles.file}>
                    <div className={styles.buttonFile}>Добавить файл</div>
                    <Input
                        type='file'
                        label=''
                        multiple
                        accept="*/*"
                        onChange={(e) => handleAddFilse(e.target.files)}/>
                </div>
            </div>
            <div className={styles.files}>  
            
            {files?.length ? <> 
                {
                    files?.map(file => ( 
                    <div className={styles.fileWrapper}>   
                    
                    <a
                        className={styles.link} 
                        href={`https://lms2.edumil.ru/messages${file?.file_path}`  || ''} 
                        download 
                        target='_blank'> 
                        <div className={styles.fileName}>{file?.name || file?.file_name}</div>
                    </a>

                    {type === true || !file.file_path ? 
                        <div className={styles.delete}>
                            <i key={file?.name || file?.file_name} 
                                className={styles.buttonDelete} 
                                title='Удалить'
                                onClick={() => handleDelete(file)}>
                                <IoMdTrash />
                            </i>
                        </div>
                    :<></>}

                    </div>
                    ))
                } </> : <div className={styles.fileName}>Нет файлов</div>}
        </div>
        </div>
    )
}

export default Index
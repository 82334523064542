const education = (color) => (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" d="M26 11.7193V13.5V18L16.5 22L7 18V12.5V11M26 11.7193L30.5 10V18L29 19.5V23.5H33V19.5L32 18V8.5L30.5 8L16.5 1.5L2 8.5L7 11M26 11.7193L16.5 15.5L7 11" stroke={color || 'white'}/>
    </svg>
)

const services = (color) => (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" d="M27.3131 27.5L21.8131 26C19.313 27 17.313 28 14.3131 28C-3.68678 28 -3.18701 1 14.3131 1C25.8131 1 30.8131 13.5 25.8131 21.5L27.3131 27.5Z" stroke={color || 'white'}/>
    </svg>
)

const comission = (color) => (
    <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" d="M16.5 16C21 17 21.5 21 22 28.5H13.5M16.5 16L15.5 15.6667M16.5 16L17 19.5L15.5 21.5L16.5 23.5L13.5 28.5M14.5 14L15 15.5L15.5 15.6667M14.5 14C15.9528 12.6631 17.5 11 17 7M14.5 14C12.5 15 10.214 15.1429 8.5 14M17 7C18.3116 5.13614 18.5796 4.12457 16.5 2.5C16.134 2.21409 15.8921 2.21676 15.5 1.5C15.1217 0.77971 14.6305 0.527082 13 0.5C10.4435 0.581905 9.07713 0.862113 7 2.5C5.87965 3.52567 5.60932 4.37161 6 6.5C6.08034 6.66795 6.14656 6.81689 6.21269 7M17 7C16.481 6.71296 16.1399 6.47948 15.5 6C12.2663 7.38233 10.5412 7.07155 7.5 6C6.99674 6.40673 6.7214 6.62674 6.21269 7M8.5 14C8.03975 13.6404 7.83066 13.4182 7.5 13C6.5 11.5 6.59165 9.67686 6.5 8C6.38399 7.53207 6.29841 7.23734 6.21269 7M8.5 14L8 16M8 16H6.5M8 16L8.89324 19.7218M6.5 16C3.49995 16.5 1.5 18.5 1 28.5H9.5M6.5 16L6 19.5L7.5 21.5L6.5 23.5L9.5 28.5M14.5 19.5L15.5 15.6667M14.5 19.5L13 18.6923L12 20.5M14.5 19.5L12.9097 25.2251M12 28.5H11M12 28.5L12.9097 25.2251M12 28.5H13.5M9.5 28.5H11M11 28.5L10.214 25.2251M8.89324 19.7218L10.214 18.8087L11 20.5M8.89324 19.7218L10.214 25.2251M11 20.5L10.214 25.2251M11 20.5H12M12 20.5L12.9097 25.2251" stroke={color || 'white'}/>
    </svg>
)

const settings = (color) => (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
            <path d="M3.70722 6.50021C4.49306 5.31814 4.70722 4.99973 5.70722 5.50021L8.20728 6.5C9.04451 5.95162 9.49112 5.70839 10.2073 5.5L10.7073 2C10.7073 1.5 11.2073 1 12.2073 1H17.2073C18.2073 1 18.7073 1.5 18.7073 2L19.2073 5.5C19.827 5.83529 20.149 6.07491 20.7073 6.5L23.2073 5.50058C24.2016 5.10144 24.7073 5 25.2073 5.50058L27.7073 9.50058C28.2073 10.5006 28.2073 11.5006 27.7073 12.0006L25.2073 14.0006C25.339 14.8103 25.3266 15.2586 25.2073 16.0006L27.7073 18.0006C28.2073 18.5006 28.7073 19.0006 28.2073 20.0006L25.7073 24.0006C25.0338 25.0818 24.2073 25.0006 23.7073 25.0006L20.7073 23.5006C20.1647 24.0132 19.8246 24.2051 19.2073 24.5006L18.7073 27.5006C18.7073 28.0006 17.7073 29.0006 17.2073 29.0006H12.7073C11.2073 29.0006 11.2073 28.5006 10.7073 27.5006L10.2073 24.5006C9.53589 24.217 9.20983 23.9854 8.70728 23.5006L6.20728 24.5006C5.20728 25.0006 4.70728 25.0006 4.20726 24.5006L1.20707 20.0006C0.707196 19.0008 1.20701 18.5008 1.70671 18.0009L1.70707 18.0006L4.2071 16.0006C4.17658 15.1442 4.18239 14.7049 4.2071 14.0006L1.70722 12.0006C1.2071 11.5006 0.970375 11.0269 1.20722 10.4997L3.70722 6.50021Z" stroke={color || 'white'}/>
            <circle cx="14.5" cy="15.5" r="4" stroke={color || 'white'}/>
        </g>
    </svg>
)

const more = (color) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
            <circle cx="7" cy="13" r="1.5" stroke={color || 'white'}/>
            <circle cx="13" cy="13" r="1.5" stroke={color || 'white'}/>
            <circle cx="19" cy="13" r="1.5" stroke={color || 'white'}/>
            <circle cx="13" cy="13" r="12.5" stroke={color || 'white'}/>
        </g>
    </svg>
)

const reference = (color) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
            <path d="M13.67 15.04H11.6C11.6 14.56 11.708 14.11 11.924 13.69C12.14 13.258 12.404 12.892 12.716 12.592C13.028 12.28 13.34 11.986 13.652 11.71C13.964 11.422 14.228 11.11 14.444 10.774C14.66 10.438 14.768 10.096 14.768 9.748C14.768 9.256 14.594 8.872 14.246 8.596C13.898 8.32 13.43 8.182 12.842 8.182C12.278 8.182 11.792 8.326 11.384 8.614C10.976 8.902 10.682 9.31 10.502 9.838L8.72 8.83C9.044 7.99 9.578 7.336 10.322 6.868C11.078 6.4 11.936 6.166 12.896 6.166C13.976 6.166 14.9 6.472 15.668 7.084C16.448 7.696 16.838 8.542 16.838 9.622C16.838 10.114 16.73 10.582 16.514 11.026C16.298 11.47 16.034 11.848 15.722 12.16C15.41 12.472 15.098 12.778 14.786 13.078C14.474 13.366 14.21 13.678 13.994 14.014C13.778 14.35 13.67 14.692 13.67 15.04ZM12.644 16.516C13.016 16.516 13.334 16.648 13.598 16.912C13.862 17.176 13.994 17.494 13.994 17.866C13.994 18.238 13.862 18.556 13.598 18.82C13.334 19.084 13.016 19.216 12.644 19.216C12.272 19.216 11.954 19.084 11.69 18.82C11.426 18.556 11.294 18.238 11.294 17.866C11.294 17.494 11.42 17.176 11.672 16.912C11.936 16.648 12.26 16.516 12.644 16.516Z" fill={color || 'white'}/>
            <circle cx="13" cy="13" r="12.5" stroke={color || 'white'}/>
        </g>
    </svg>
)



export { education, services, comission, settings, more, reference }
import * as types from "../../actions/types";



const initialState = {
  chapters: null,
  chapterEntity: null,
  chapterGroupData: null,
  loading: false,
};


export function access_control (state = initialState, action) {
  switch (action.type) {
    case types.SET_CHAPTERS_LIST:
      return {
        ...state,
        chapters: action.payload
      };
    case types.SET_ACCESS_ENTITY:
      return {
        ...state,
        chapterEntity: action.payload
      };
    case types.SET_ACCESS_GROUP_DATA:
      return {
        ...state,
        chapterGroupData: action.payload
      };
    case types.SET_ACCESS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from '../../../components/ModalInputs'
import styles from '../styles.module.scss'
import Table from '../../../components/Table'
import { getScheduleSettingsDayOfWeek, getTeachersLesson, updateTeachersLesson } from '../../../actions/scheduleSettings'
import Loader from '../../../components/Loader'
import { getSchoolYears } from '../../../actions/common'
import moment from 'moment'
import { getCurrentYear } from '../../../utils/classBook'



export default function TeachersLesson() {

  const dispatch = useDispatch()
  const { TeachersSubjectsList, loading, dayOfWeek } = useSelector(state => state.schedule_settings)
  const { schoolYears } = useSelector(state => state.common)
  const [currYear, setCurrYear] = useState(getCurrentYear(schoolYears, moment()));

  useEffect(() => {
    // dispatch(getScheduleSettingsRooms())
    dispatch(getTeachersLesson(currYear))
    dispatch(getScheduleSettingsDayOfWeek(currYear))
    dispatch(getSchoolYears())
  }, [])


  useEffect(() => {
    dispatch(getTeachersLesson(currYear))
    dispatch(getScheduleSettingsDayOfWeek(currYear))
  }, [currYear])

  const selectedCell = (data) => () => {
    dispatch(updateTeachersLesson(data, currYear))
  }

  const renderLi = (data) => {
    if (data) return (
      <tr key={data[0]}>
        <td className={styles.fio}>{data[1]}</td>
        {data[3].map((el, i) =>
          <>
            {i < 1 && <td>{data[2]}</td>}
            <td
              key={el[0]}
              onClick={selectedCell({
                "year": currYear,
                "dayofweek": el[2],
                "mid": data[0],
                "period": el[0],
              })}
              style={{ backgroundColor: el[1] === 0 ? 'white' : el[1] > 0 ? 'red' : 'green' }}
            ></td>
          </>
        )}
      </tr >
    )
  }

  return (
    loading ? <Loader /> :
      <div className={styles.inputsWrapper} style={{ flexDirection: 'column' }}>
        <div className={styles.selectWrapper}>
          <Select
            label='Учебные года'
            value={currYear}
            onChange={({ target: { name, value } }) => setCurrYear(value)}
            name='xp_key'
          >
            {schoolYears && schoolYears.map(({ name, xp_key }) => <option key={xp_key} value={xp_key}>{name}</option>)}
          </Select>
        </div>
        <Table style={{ marginTop: '2rem', backgroundColor: 'white', position: 'relative', height: '70vh' }}>
          <thead className={styles.headSchSet}>
            <tr>
              <th className={styles.fio2}></th>
              <th>Кол-во вых, кроме Вс</th>
              {dayOfWeek?.map(el => <th>{el.name_day}</th>)}
            </tr>
          </thead>
          <tbody>

            {TeachersSubjectsList?.map(renderLi)}

          </tbody>
        </Table>

      </div>
  )
}

import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateMedicineInfo } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Checkbox, Textarea } from '../../../components/ModalInputs'
import Table from '../../../components/Table'


export default function Medicine () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { medicine_info } = useSelector(state => state.candidates.data)

  useEffect(() => {
    dispatch(getCandidateMedicineInfo(candidateId))
  }, [])
  
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Checkbox
              label='Готов к поступлению'
            />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '750px', height: '150px'}}
              label='Состояние здоровья'
            />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '550px', height: '150px'}}
              label='Очный осмотр'
            />
            <Checkbox
              label='Не допущен к дальнейшему прохождению вступительных испытаний по результатам очного осмотра'
            />
          </div>
          <p>Не хватает доументов</p>
          <Table>
            <thead>
              <tr>
                <th>Название документа (файл)</th>
                <th>Примечание/Причина</th>
                <th>Статус</th>
                <th>Обновлен</th>
              </tr>
            </thead>
          </Table>
        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={closeModal}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}

import React, { useEffect, useRef } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Input, } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'

import { createHealthgroup, updateHealthgroup } from '../../../actions/directoryMedCenter'

const validationSchema = Yup.object({
  title: Yup.string().required('обязательное поле').max(50, 'Должно быть не более 50 символов'),

})


export default function Main({ closeModal, data, addMode }) {

  const dispatch = useDispatch()

  const handleCreate = (values) => {
    dispatch(createHealthgroup(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    dispatch(updateHealthgroup(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} style={{ display: "flex", justifyContent: 'space-evenly' }}>

          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='title'
              value={values.title}
              error={error('title')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
          </div>

        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import MarkPanel from './MarkPanel/MarkPanel';
import { updateComplexityClassesSubjects } from '../../../actions/scheduleSettings';

export const TableCell = ({
  data,
  panelOpen,
  setPanelOpen,

  selected,
  setSelected,
}) => {
  const dispatch = useDispatch();
  const { gradeValue } = useSelector(state => state.classbook);
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState('');

  const editModeToggle = () => {
    setEditMode(prev => !prev)

  }
  const cellChanging = async (name, num) => {
    setValue(num)
    // setSelected((prev)=>( {...prev, [name]:num}))
    dispatch(updateComplexityClassesSubjects(({ ...selected, [name]: num })))
    setPanelOpen([])
    // await dispatch(setClassbook({ ...selected, [name]: num }, formData))
  }
  // const handleActive = ({mid, lessonid}) => {
  //   if (panelOpen.mid && panelOpen.lessonid) {
  //     setPanelOpen({mid: null, lessonid: null})
  //   } else if (selected.find(el => (el.mid == mid) && (el.lessonid == lessonid))) {
  //     const arr = selected.filter(el => el.mid != mid && el.lessonid != lessonid);
  //     setSelected(arr)
  //   } else {
  //     setSelected([...selected, {mid: mid, lessonid: lessonid}])
  //   }
  // }

  const CheckboxType = (student) => {
    if (student.gradecomment) return "lightgrey"
    else return "white"
  }

  const handleRightClick = (e, { data }) => {
    e.preventDefault();
    if (e.button === 0) {
      setSelected(data)
      setPanelOpen(data)
    }
  }


  return (
    <div
      key={data.gid}
      onContextMenu={(e) => e.preventDefault()}
      onMouseDown={(e) => handleRightClick(e, { data })}
      // onClick={selectedCell([scheduleSettingsRooms?.[i].rid, data[0]])}
      className={styles.cell}
    // style={{backgroundColor: panelOpen.find(el => (el[0] == panelOpen.gid) && (el[1] == panelOpen.cid)) ? '#3689FF' :  CheckboxType(student)}}

    >
      {data.value > 0 ? data.value : ''}
      {/* {(panelOpen.gid == data[0]) && (panelOpen.cid == data[1]) ? */}
      {!!panelOpen.gid && (panelOpen.gid == data.gid) && (panelOpen.cid == data.cid) ?
        <MarkPanel
          classbook={data}
          setSelected={setSelected}
          selected={selected}
          // student={student}  
          setPanelOpen={setPanelOpen}
          gradeValue={gradeValue}
          cellChanging={cellChanging}
        // handleOpenComment={handleOpenComment}
        /> : null
      }
    </div>
  )
}


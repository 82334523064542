import React, { useEffect } from 'react'
import styles from './index.module.css'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../../../components/ModalFooter/ModalFooter'
import { Input, Select } from '../../../../../components/ModalInputs'
import { useDispatch, useSelector } from 'react-redux'
import { getStudyPlanList, getStudyTrackProfile } from '../../../../../actions/studyPlans'

const Index = ({ modalOpen, setModalOpen, closeModal , handleSubmit}) => {

    const dispatch = useDispatch()

    const {fullList = [], track_profile = []} = useSelector(state => state.studyPlans)

    useEffect(() => {
        dispatch(getStudyPlanList());
        dispatch(getStudyTrackProfile());
    }, [])
    
    const renderOptions = ({course_name, cid, xp_key}) => <option value={cid} key={xp_key}>{course_name}</option>
    const renderOptions_ = ({trp_id, name}) => <option value={trp_id} key={trp_id}>{name}</option>

    const handleChange = (e) => {
        setModalOpen({...modalOpen, [e.target.name]: Number(e.target.value)})
    }

    const checkProfile = () => {
        console.log(modalOpen, 'modalOpen')
        let x = false
        if (modalOpen.xp_key === 'new') {
            if (modalOpen?.profile_id === '' || modalOpen?.profile_id === 0) {
                x = true
            } else {
                x = false
            }
        } else {
            if (modalOpen?.profile_id === '' || modalOpen?.profile_id === 0) {
                x = true
            } else {
                x = false
            }
        }
        return x
    }

  return (
    <Modal
            classNames={{
            modal: styles.modalMain
        }}
            open={modalOpen}
            onClose={closeModal}
            closeOnOverlayClick={true}
            closeOnEsc={true}
            showCloseIcon={true}
            animationDuration={1}
            style={{
                modal: {
                    background: 'white',
                    width: '700px'
                }
            }}
            >

                <div className={styles.modalContent}>
                    <h1 className={styles.modalHeader}>{modalOpen?.xp_key !='new' ? 'Изменить' : 'Создать'}</h1>
                    <div className={styles.modalMenu}>
                            <div className={styles.inputsRow}>
                                <Select
                                    error={modalOpen?.cid === '' ? 'Обязательное поле' : ''} 
                                    label='Предмет' 
                                    name='cid'
                                    value={modalOpen?.cid}
                                    onChange={e => handleChange({target: {name: 'cid', value: Number(e.target.value)}})}>
                                    {[{ cid: '', course_name: 'Выбрать' }, ...fullList]?.map(renderOptions)}
                                </Select>
                            </div>

                            <div className={styles.inputsRow}>
                                <Select 
                                    error={modalOpen?.prev_cid === '' ? 'Обязательное поле' : ''}
                                    label='Предшествующий предмет' 
                                    name='prev_cid'
                                    value={modalOpen?.prev_cid}
                                    onChange={e => handleChange({target: {name: 'prev_cid', value: Number(e.target.value)}})}>
                                    {[{ cid: '', course_name: 'Выбрать' }, ...fullList]?.map(renderOptions)}
                                </Select>
                            </div>

                            <div className={styles.tabWrapper}>
                                <div className={styles.inputsRow}>
                                <Input
                                    type='number'
                                    error={modalOpen.xp_hours === 0 ? 'Обязательное поле' : ''}
                                    label='Часов в неделю'
                                    name='xp_hours'
                                    value={modalOpen.xp_hours}
                                    onChange={e => handleChange(e)}/>
                                </div>
                                <div className={styles.inputsRow}>
                                <Input
                                    type='number'
                                    error={modalOpen.xp_hours_period === 0 ? 'Обязательное поле' : ''}
                                    label='Часов в период'
                                    name='xp_hours_period'
                                    value={modalOpen.xp_hours_period}
                                    onChange={e => handleChange(e)}/>
                                </div>
                                <div className={styles.inputsRow}>
                                <Input
                                    type='number'
                                    error={modalOpen.xp_hours_year === 0 ? 'Обязательное поле' : ''}
                                    label='Часов в год'
                                    name='xp_hours_year'
                                    value={modalOpen.xp_hours_year}
                                    onChange={e => handleChange(e)}/>
                                </div>
                            </div>

                            <div className={styles.tabWrapper}>
                                <div className={styles.inputsRow}>
                                <Input
                                    type='number'
                                    error={modalOpen.level === 0 ? 'Обязательное поле' : ''}
                                    label='Параллель'
                                    name='level'
                                    value={modalOpen.level}
                                    onChange={e => handleChange(e)}/>
                                </div>
                                <div className={styles.inputsRow}>
                                <Select
                                    error={ checkProfile() ? 'Обязательное поле' : ''}
                                    label='Профиль' 
                                    name='profile_id'
                                    value={modalOpen?.profile_id}
                                    onChange={e => handleChange({target: {name: 'profile_id', value: Number(e.target.value)}})}>
                                    {[...track_profile]?.map(renderOptions_)}
                                </Select>
                                </div>

                            </div>

                    </div>
                </div>

                <ModalFooter isShowInfo={true}  closeModal={closeModal} handleSubmit={handleSubmit}
                />
            </Modal>
  )
}

export default Index